import React from 'react'
import { gsap } from 'gsap';

const AnimationCards = () => {

    const card1Ref = React.useRef();
    const card2Ref = React.useRef();
    const card3Ref = React.useRef();
    const card4Ref = React.useRef();
    const card5Ref = React.useRef();
    const card6Ref = React.useRef();
    const timeline = React.useRef(null);
    
    React.useEffect(() => {
        const card1 = card1Ref.current;
        const card2 = card2Ref.current;
        const card3 = card3Ref.current;
        const card4 = card4Ref.current;
        const card5 = card5Ref.current;
        const card6 = card6Ref.current;
        timeline.current = gsap.timeline({
            repeat: -1, // Repeat indefinitely   
            onComplete: () => {     
                // Additional logic if needed after each complete cycle
            }
        });

        timeline.current
            // FIRST INTERATION 
            // .to(card1, { 
            //     duration: 1.5,
            //     delay:3,
            //     backgroundColor: '#FFFFFF',
            //     boxShadow: '10px 10px 50px 3px #275C8D1A',
            //     opacity: 1,
            // })
            .to(card1, {
                duration: 2,
                delay: 4,
                boxShadow: 'none',
                opacity: 0,
                top: '-100%'
            })
            .to(card2, {
                duration: 1,
                backgroundColor: '#FFFFFF',
                // boxShadow: '10px 10px 50px 3px #275C8D1A',
                // boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',  
                // boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;',
                delay: -2,
                top: '0%',
                opacity: 1
            })
            .to(card3, {
                duration: 1,    
                delay: -2,
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                top: '15%',
                opacity: 1
            })
            .to(card4, {
                duration: 1,
                delay: -2,
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                top: '30%',
                opacity: 1
            })
            .to(card5, {
                duration: 1,
                delay: -2,
                top: '45%',
            })
            .to(card6, {
                duration: 1,
                delay: -2,
                top: '60%',
            })
            .to(card1, {
                duration: 0.1,
                delay: -0.1,
                top: '75%'
            })
            .to(card1, {
                duration: 0.1,
                opacity: 0.4,
            })
            // SECOND INTERATION 
            .to(card2, {
                duration: 2,
                delay: 2,
                boxShadow: 'none',
                opacity: 0,
                top: '-100%'
            })
            .to(card3, {
                duration: 1,
                backgroundColor: '#FFFFFF',
                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
                delay: -2,
                top: '0%',
                opacity: 1
            })
            .to(card4, {
                duration: 1,
                delay: -2,
                top: '15%',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                opacity: 1
            })
            .to(card5, {
                duration: 1,
                delay: -2,
                top: '30%',
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                opacity: 1
            })
            .to(card6, {
                duration: 1,
                delay: -2,
                top: '45%',
            })
            .to(card1, {
                duration: 1,
                delay: -2,
                top: '60%',
            })
            .to(card2, {
                duration: 0.1,
                delay: -0.1,
                top: '75%'
            })
            .to(card2, {
                duration: 0.1,
                opacity: 0.4,
            })
            // THIRD INTERATION 
            .to(card3, {
                duration: 2,
                delay: 2,
                boxShadow: 'none',
                opacity: 0,
                top: '-100%'
            })
            .to(card4, {
                duration: 1,
                backgroundColor: '#FFFFFF',
                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
                delay: -2,
                top: '0%',
                opacity: 1
            })
            .to(card5, {
                duration: 1,
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                delay: -2,
                top: '15%',
                opacity: 1
            })
            .to(card6, {
                duration: 1,
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                delay: -2,
                top: '30%',
                opacity: 1
            })
            .to(card1, {
                duration: 1,
                delay: -2,
                top: '45%',
            })
            .to(card2, {
                duration: 1,
                delay: -2,
                top: '60%',
            })
            .to(card3, {
                duration: 0.1,
                delay: -0.1,
                top: '75%'
            })
            .to(card3, {
                duration: 0.1,
                opacity: 0.4,
            })
            // FOURTH INTERATION 
            .to(card4, {
                duration: 2,
                delay: 2,
                boxShadow: 'none',
                opacity: 0,
                top: '-100%'
            })
            .to(card5, {
                duration: 1,
                backgroundColor: '#FFFFFF',
                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
                delay: -2,
                top: '0%',
                opacity: 1
            })
            .to(card6, {
                duration: 1,
                delay: -2,
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                top: '15%',
                opacity: 1
            })
            .to(card1, {
                duration: 1,
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                delay: -2,
                top: '30%',
                opacity: 1
            })
            .to(card2, {
                duration: 1,
                delay: -2,
                top: '45%',
            })
            .to(card3, {
                duration: 1,
                delay: -2,
                top: '60%',
            })
            .to(card4, {
                duration: 0.1,
                delay: -0.1,
                top: '75%'
            })
            .to(card4, {
                duration: 0.1,
                opacity: 0.4,
            })
            // FIFTH INTERATION 
            .to(card5, {
                duration: 2,
                delay: 2,
                boxShadow: 'none',
                opacity: 0,
                top: '-100%'
            })
            .to(card6, {
                duration: 1,
                backgroundColor: '#FFFFFF',
                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
                delay: -2,
                top: '0%',
                opacity: 1
            })
            .to(card1, {
                duration: 1,
                delay: -2,
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                top: '15%',
                opacity: 1
            })
            .to(card2, {
                duration: 1,
                delay: -2,
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                top: '30%',
                opacity: 1
            })
            .to(card3, {
                duration: 1,
                delay: -2,
                top: '45%',
            })
            .to(card4, {
                duration: 1,
                delay: -2,
                top: '60%',
            })
            .to(card5, {
                duration: 0.1,
                delay: -0.1,
                top: '75%'
            })
            .to(card5, {
                duration: 0.1,
                opacity: 0.4,
            })
            // SIXTH INTERATION 
            .to(card6, {
                duration: 2,
                delay: 2,
                boxShadow: 'none',
                opacity: 0,
                top: '-100%'
            })
            .to(card1, {
                duration: 1,
                backgroundColor: '#FFFFFF',
                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
                delay: -2,
                top: '0%',
                opacity: 1
            })
            .to(card2, {
                duration: 1,
                delay: -2,
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                top: '15%',
                opacity: 1
            })
            .to(card3, {
                duration: 1,
                delay: -2,
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                top: '30%',
                opacity: 1
            })
            .to(card4, {
                duration: 1,
                delay: -2,
                top: '45%',
            })
            .to(card5, {
                duration: 1,
                delay: -2,
                top: '60%',
            })
            .to(card6, {
                duration: 0.1,
                delay: -0.1,
                top: '75%'
            })
            .to(card6, {
                duration: 0.1,
                opacity: 0.4,
            })
    }, []);      
    
    return (     
        <> 
            <div className='hidden lg:flex relative  items-center justify-center  w-3/12 h-full overflow-hidden bg-[#F1F6FF]'>
                <div className='w-full relative h-[80vh]   overflow-hidden flex flex-col items-center justify-center '>
                    <div style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px' }} ref={card1Ref} className='w-11/12 h-24 absolute top-[0%] bg-[#FFFFFF]  flex items-center justify-center gap-3 px-6 rounded '>
                        <h1 className='text-base text-center  text-[#424D51] font-medium'><span className='text-[#AAAAAA]'>Empower Your Signers with</span> Phone and Email OTP Verificatio </h1>
                    </div>
                    <div style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }} ref={card2Ref} className='w-11/12 h-24 absolute top-[15%]  bg-[#FFFFFF] opacity-90 flex  items-center justify-center gap-3 px-6 rounded '>
                        <h1 className='text-base text-center  text-[#424D51] font-medium'><span className='text-[#AAAAAA]'>Simplify</span> Your Signing Process with Signizy </h1>
                    </div>
                    <div style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }} ref={card3Ref} className='w-11/12 h-24 absolute top-[30%]  bg-[#FFFFFF] opacity-80  flex  items-center justify-center gap-3 px-6 rounded '>
                        <h1 className='text-base text-center  text-[#424D51] font-medium'><span className='text-[#AAAAAA]'>Simple, Convenient</span> Pricing for Every Need </h1>
                    </div>
                    <div ref={card4Ref} className='w-11/12 h-24  absolute top-[45%]  bg-[#FFFFFF] opacity-70  flex items-center justify-center gap-3 px-6 rounded '>
                        <h1 className='text-base text-center  text-[#424D51] font-medium'><span className='text-[#AAAAAA]'>Effortless Document Creation with</span> Upload and Write Yourself </h1>
                    </div>
                    <div ref={card5Ref} className='w-11/12 h-24 absolute top-[60%]  bg-[#FFFFFF] opacity-60 flex  items-center justify-center gap-3 px-6 rounded '>
                        <h1 className='text-base text-center  text-[#424D51] font-medium'><span className='text-[#AAAAAA]'>Collaborate Seamlessly with</span> Unlimited Signers </h1>
                    </div>
                    <div ref={card6Ref} className='w-11/12 h-24 absolute top-[75%]  bg-[#FFFFFF] opacity-50 flex items-center justify-center gap-3 px-6 rounded '>
                        <h1 className='text-base text-center  text-[#424D51] font-medium'><span className='text-[#AAAAAA]'>Print and Download</span> Your Documents </h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AnimationCards
