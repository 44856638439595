import React, { useState } from 'react';
import { Button, Input, Select, Modal } from 'antd';
import { EmmailIcon, LocationIcon, PeopleIcon, RoleIcon } from './Icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '../Themes/ThemeProvider';

const ConfirmCancelDialogue = ({ open, setOpen, onDiscard }) => {
    const { themeColors } = useTheme();
    const navigate = useNavigate();
    const location = useLocation()
    // console.log(location?.pathname?.split('/')?.length);
    const modalContentStyle = {
        // backgroundColor: 'red',
        padding: '0'
    };

    return (
        <>
            <Modal
                title={<h1>Do you want to discard all changes and exit?</h1>}
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                closable={false}
                style={modalContentStyle}
                footer={
                    <div className='w-full flex items-center justify-end gap-4 pt-6'>
                        <Button
                            onClick={onDiscard}
                            size='middle'
                            style={{ backgroundColor: themeColors.bg.backButton, color: themeColors.bg.inviteButton }}
                            type="primary"
                        >
                            Discard
                        </Button>
                        <Button
                            onClick={() => setOpen(false)}
                            // style={{ backgroundColor: themeColors.bg.inviteButton }}
                            size='middle'
                            type="primary"
                        >
                            Stay
                        </Button>
                    </div>
                }
                header={null}
                width={500} 
            >
                <div className='w-full flex flex-col lg:flex-row items-start lg:items-start gap-3 lg:gap-6 py-6 flex-wrap'>
                    <h1>Confirm discarding all unsaved changes and exiting. Any unsaved changes will be lost.</h1>
                </div>
            </Modal>
        </>
    );
};
export default ConfirmCancelDialogue;