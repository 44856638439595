import { current } from "@reduxjs/toolkit";

// theme.js
export const lightThemeColors = {
    bg: {
        primary: 'bg-[#FFFFFF]',
        secondary: 'bg-[#F9FBFD]',
        options: 'bg-[#FAFAFA]',
        layout: 'bg-[#F8F8F8]',
        disabled: 'bg-[#F5F5F5]',
        // DASHBOARD COLOR 
        dashboard: 'bg-[#F8F8F8]',
        dashboardDate: 'bg-[#f1f6ff]',
        dateRow: 'bg-[#508BFD36]',
        selecttButton: 'bg-[#1677FF]',
        inviteButton: '#1677FF',
        backButton: '#F1F6FF',
        addButton: '#D6E3FD',
        cancel: '#508BFD36',
        accordian: '#F7F7F780',
        primaryColor: '#FFFFFF'
    },
    headings: {
        primary: 'text-[#424D51]',
        secondary: 'text-[#000000]',
        third: 'text-[#FFFFFF]',
        disabled: 'text-[#AAAAAA]'
    },
    text: {
        primary: 'text-[#666]',
        secondary: 'text-[#424D51]',
        third: 'text-[#9E9E9E]',
        fourth: 'text-[#8E8E8E]',
        fifth: 'text-[#1677FF]',
        draft: 'text-[#AAAAAA]',
        send: 'text-[#FAA41A]',
        completed: 'text-[#00C1A3]',
        plan: 'text-[#FFFFFF]',
    },
    icons: {
        primary: '#1677FF',
        current: '#1677FF33',
        secondary: '#AAAAAA',
        third: '#1677FF',
        fourth: '#8E8E8E',
        fifth: '#CACACA',
        sixth: '#424D51',
        seven: '#FFFFFF',
        // fifth:''/
    },
    borders: {
        primary: 'border-[#1677FF]',
        secondary: 'border-[#D9D9D9]',
        third: 'border-[#ECECEC]',
        fourth: 'border-[#D2E2FF]',
        fifth: ['border-[rgba(0, 0, 0, 0.25)]'],
        signP: 'border-[#1677FF61]',
        otpInput: '#E3EDFE',
        accordianDoc: '#1677FF26',
        input: '#EAEAEA'
    },
    inputBorder: {
        main: '#D9D9D9'
    },
    primary: 'text-[#757575]',
    secondary: 'text-green-500',
    // Add more light theme colors as needed
};

export const darkThemeColors = {
    primary: 'text-red-300',
    secondary: 'text-gray-500',
    // Add more dark theme colors as needed
};

