import React, { useState } from 'react'
import { H1, H2, H3, H4, H6, P, Price, Span } from '../Themes/StylingComponent'
import { AiIcon, BasicPlanIcon, CancelIcon, RightIcon } from './Icons'
import { Button } from 'antd'
import { useTheme } from '../Themes/ThemeProvider'
import Payments from '../Screens/Payments'
import { useRecoilState } from 'recoil'
import { userAuthState } from '../Services/RecoilService'
import { useLocation, useNavigate } from 'react-router-dom';


const PlanCard = ({ plan, isYearly, onStartTrial, isSetting, isBilling, setIsYearly, setCurrency, trial, currency, defaultCurrency, credits }) => {
    const { themeColors } = useTheme();
    const location = useLocation();
    const [authState, setAuthState] = useRecoilState(userAuthState);
    const [price, setPrice] = useState('')
    const [isTrial, setIsTrial] = React.useState(false)
    const navigate = useNavigate();
    credits = credits ? credits : 0
    React.useEffect(() => {
        // console.log(plan, ';plsnn');
        if (plan?.price[currency?.code]) {
            setPrice(plan?.price[currency?.code])
        } else {
            setPrice(plan?.price[defaultCurrency?.code])
            setCurrency(defaultCurrency)
        }
    }, [plan])

    React.useEffect(() => {
        if (trial === 14)
            setIsTrial(true)
    }, [trial])

    const textColor = '#424D51';

    return (
        <>
            <div className={`w-full relative flex flex-col gap-4 overflow-hidden p-8 ${themeColors?.bg?.primary}  shadow-lg `}>
                <div className='flex w-full items-center justify-between gap-2 pb-4'>
                    <div className='flex items-center  gap-2 '>
                        <BasicPlanIcon color={themeColors?.icons?.primary} />
                        <H2 title={plan?.title} />
                    </div>
                    {plan?.popular ?
                        <div className={`w-48 flex items-center justify-center absolute h-8 rotate-45 ${themeColors?.bg?.selecttButton} top-0 right-[-10%] `}>
                            <p className='text-white text-xs lg:text-sm ml-12'>Popular</p>
                        </div>
                        : null}
                </div>
                <P
                    align='center'
                    title={'Our Basic plan offers essential features at an affordable price, perfect for startups and small businesses looking to get started.'}
                />
                <div className='w-full flex items-center gap-2 justify-center pt-4'>
                    <Price title={`${currency?.symbol} ${isYearly ? `${(price?.monthlyPrice)?.toFixed(2)}` : price?.monthlyPrice?.toFixed(2)}`} />
                    <div className='flex items-center gap-1'>
                        {currency &&
                            <>
                                <H1
                                    title={`${currency?.symbol}${isYearly ? `${((price?.offerYearlyPrice - credits) / 12)?.toFixed(2)} ` : parseFloat(credits) < parseFloat(price?.offerMonthlyPrice) ? (price?.offerMonthlyPrice - credits)?.toFixed(2) : price.offerMonthlyPrice?.toFixed(2)}`}
                                />
                                <Span title={'/Month'} />
                            </>
                        }
                    </div>
                </div>
                <div className=' py-4 flex  gap-2'>
                    {trial > 0 ?
                        <Button
                            type="primary"
                            style={{
                                width: '100%'
                            }}
                            onClick={() => onStartTrial(plan?._id)}
                        >
                            Start Free Trial
                        </Button>
                        : null}

                    {location && (location?.pathname?.includes('pay_now') || location?.pathname?.includes('invoice')) ? null :
                        <Button
                            type={trial > 0 ? 'default' : 'primary'}
                            style={{
                                width: '100%',
                            }}
                            onClick={() => navigate(`/invoice`, { state: { plan, isYearly, trial, isSetting, currency, isTrial } })}
                        >
                            Purchase
                        </Button>
                    }

                    {/* <Payments
                        currency={currency}
                        userId={authState?.id}  
                        teamId={authState?.teamId}  
                        plan={plan} 
                        authState={authState}
                        isYearly={isYearly}
                        trial={trial}
                        isSetting={isSetting}
                    /> */}

                </div>

                <div className='w-full  flex flex-col items-start gap-4 pt-8'>
                    <div className='flex items-center gap-2'>
                        <RightIcon />
                        <h6 className={`text-xs lg:text-sm text-[${textColor}] font-normal`}>{plan?.numberOfDocument} Digital contracts per month</h6>
                    </div>
                    <div className='flex items-center gap-2'>
                        <RightIcon />
                        <h6 className={`text-xs lg:text-sm text-[${textColor}] font-normal`}>{plan?.numberOfUser} {plan?.numberOfUser != 1 ? 'Users' : 'User'} allowed</h6>
                    </div>
                    <div className='flex items-center gap-2'>
                        <RightIcon />
                        <h6 className={`text-xs lg:text-sm text-[${textColor}] font-normal`}>Draw signatures</h6>
                    </div>
                    <div className='flex items-center gap-2'>
                        <RightIcon />
                        <h6 className={`text-xs lg:text-sm text-[${textColor}] font-normal`}>Email signatures</h6>
                    </div>
                    <div className='flex items-center gap-2'>
                        <RightIcon />
                        <h6 className={`text-xs lg:text-sm text-[${textColor}] font-normal`}>Create contracts</h6>
                    </div>
                    <div className='flex items-center gap-2'>
                        {plan?.mobileSign ?
                            <RightIcon />
                            :
                            <CancelIcon />
                        }
                        <h6 className={`text-xs lg:text-sm text-[${textColor}] font-normal`}>Phone OTP signatures</h6>
                    </div>
                    <div className='flex items-center gap-2'>
                        {plan?.fileUpload ?
                            <RightIcon />
                            :   
                            <CancelIcon />
                        }
                        <h6 className={`text-xs lg:text-sm text-[${textColor}] font-normal`}>Upload PDF contracts</h6>
                    </div>
                    <div className={`flex items-center gap-2`}>
                        {plan?.fileUpload ?
                            <RightIcon />
                            : <CancelIcon />}
                        <h6 className={`text-xs lg:text-sm text-[${textColor}] font-normal`}>{plan?.storage}  mb uploads</h6>
                    </div>
                    <div className='flex items-center gap-2'>
                        <AiIcon />
                        <h6 className={`text-xs lg:text-sm text-[${textColor}] font-normal`}>AI contract writing (upcoming)</h6>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PlanCard
