import React, { useState } from 'react';
import { Button, Drawer, Checkbox, Input, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDimensions } from '../DimensionContext/DimensionContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { get, patch, post } from '../Services/api-services';
import { userAuthState } from '../Services/RecoilService';
import { useRecoilState } from 'recoil';
import { useDispatch, useSelector } from 'react-redux';
import { removeAllSigners, updateContent, updateMethods, updateSigners, updateTitle } from '../store/Reducers/contentReducer';
import { useTheme } from '../Themes/ThemeProvider';
import TagComponent from './TagComponent';
import { H2, H3, H4, P } from '../Themes/StylingComponent';

const { TextArea } = Input;

const inputStyle = { fontSize: '14px' }

const SendDrawer = ({ open, setOpen, signers, selectedSigners, setSelectedSigners }) => {
    const { themeColors } = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { title, isWriting } = useSelector(state => state.contentOption);
    const _documentid = location?.pathname?.includes('edit') ? location.pathname.split('/')[3] : location.pathname.split('/')[2]
    const { lg, xl, xxl } = useDimensions();
    const [emailTitle, setEmailTitle] = React.useState(`${title} via Signizy`)
    const [emailContent, setEmailContent] = React.useState('')
    const [authState, setAuthState] = useRecoilState(userAuthState)
    const [isAdd, setIsAdd] = React.useState(false)
    const [sendMe, setSendMe] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [tags, setTags] = useState([]);
    const [Emails, setEmails] = React.useState([])

    // MANAGE SIGNER SELECTION 
    const onChange = (e, index) => {
        if (e.target.checked) {
            const selectedValue = signers[index]
            selectedSigners.splice(index, 0, selectedValue)
        } else {
            selectedSigners.splice(index, 1);
            // console.log(selectedSigners);
            // console.log(signers);
        }
    };  

    // SEND THE FINAL DOCUMENT 
    const sendDocumnt = async () => {
        setLoading(true)
        try {
            // Check if Emails is valid before splitting
            // const _emails = Emails ? Emails.split(',') : [];
            // const _emails = Emails ? ...Emails : [];
            const _emails = Emails ? [...Emails] : [];

            console.log(_emails);


            const _signersEmail = [];
            if (selectedSigners) {
                for (let i = 0; i < selectedSigners.length; i++) {
                    const sign = selectedSigners[i];
                    if (sign && sign && sign.email) {
                        _signersEmail.push(sign.email);
                    }
                }
            }

            if (sendMe) {
                const _userData = await get(`user/${authState?.id}`)
                if (_userData?.statusCode == 200) {
                    _emails?.push(_userData?.data?.email)
                }
            }

            const _method = {
                methods: {
                    draw: true,
                    email: false,
                    mobile: false
                }
            }

            const _data = {
                signers: _signersEmail,
                emailTitle: emailTitle,
                emailContent: emailContent,
                ccEmails: _emails,
                documentId: _documentid
            }

            const response = await post(`document/sendEmail`, _data)
            setLoading(false)
            if (response?.statusCode == 200) {
                setOpen(false)
                dispatch(updateContent(''))
                dispatch(removeAllSigners([]))
                dispatch(updateTitle(''));
                dispatch(updateMethods(_method))
                message.success('Document Sent Successfully!')
                navigate('/home/dashboard', { replace: true })
            } else {
                message?.error(response?.message)
            }
        } catch (e) {
            console.log(`ERROR WHILE SENDING DOCUMENT ${e}`);
            setLoading(false)
            message.error('Error while sending Document!')
        }
    }

    const _textStype = `text-xs  whitespace-nowrap  lg:text-sm ${themeColors.text.fourth}`; 
    
    return (
        <>
            <Drawer
                closeIcon={null}
                width={lg || xl || xxl ? 550 : 300}
                footer={
                    <div className='w-full flex items-center justify-between pt-6'>
                        <Button onClick={() => setOpen(false)} size='middle' style={{ backgroundColor: themeColors.bg.cancel, color: themeColors.bg.inviteButton }} type="primary">Cancel</Button>
                        <Button
                            onClick={() => sendDocumnt()}
                            // style={{ backgroundColor: themeColors.bg.inviteButton }}
                            size='middle'
                            type="primary"
                            loading={loading}
                            disabled={!selectedSigners?.length > 0 && !sendMe && !Emails?.length > 0}
                        >
                            {/* {!loading ? (
                                location?.pathname?.includes(_documentid) ? 'Send again' : 'Send'
                            ) : ''} */}
                            {/* {location?.pathname?.includes(_documentid) ? 'Send again' : 'Send'} */}
                            Send
                        </Button>
                    </div>
                }
                title={
                    <H4
                        title='SENDING TO'
                        align='left'
                    />
                }
                onClose={() => setOpen(false)}
                open={open}
            >
                <div className='w-full'>
                    <div className='w-full'>
                        {signers?.map((sign, index) => {
                            return (
                                <div key={index} className='w-full flex items-start  gap-3 py-3'>
                                    <Checkbox defaultChecked style={{ marginTop: '3px' }} onChange={(e) => onChange(e, index)} />
                                    <div className='flex flex-col items-start gap-1'>
                                        <H4
                                            title={sign?.name.length > 20 ? sign?.name.substring(0, 20) + '...' : sign?.name}
                                            font='medium'
                                            toolTipTitle={sign?.name.length > 20 ? sign?.name : ''}
                                        />
                                        {/* <h3 className={`text-base capitalize lg:text-base font-medium ${themeColors.headings.primary}`}>{sign?.name}</h3> */}
                                        <div className='flex flex-col items-start lg:items-center lg:flex-row gap-1'>
                                            <div className='flex  gap-1'>
                                                <P title='Title:' />
                                                <P
                                                    title={sign?.title?.length > 20 ? sign?.title.substring(0, 20) + '...' : sign?.title}
                                                    toolTipTitle={sign?.title?.length > 20 ? sign?.title : ''}  
                                                />
                                            </div>
                                            <span className='w-1 hidden lg:block h-1 bg-[#8E8E8E] rounded-full mr-1'></span>
                                            <P
                                                title={sign?.email?.length > 20 ? sign?.email?.substring(0, 20) : sign?.email}
                                                toolTipTitle={sign?.email?.length > 20 ? sign?.email : ''}
                                            />
                                            {sign?.mobile &&
                                                <>
                                                    <span className='w-1 hidden lg:block h-1 bg-[#8E8E8E] rounded-full mr-1'></span>
                                                    <P title={`+${sign?.country?.phone} ${sign?.mobile}`} />
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='w-full hidden py-4'>
                        <Button style={{ padding: 'initial' }} onClick={() => setIsAdd(true)} icon={<PlusOutlined />} type="link">Add more participants</Button>
                        {isAdd &&
                            <div className='py-2'>
                                <Input
                                    placeholder="CC:(Comma Separeted Emails)"
                                    size='large'
                                    style={inputStyle}
                                    onChange={(e) => setEmails(e?.target?.value)}
                                />
                            </div>
                        }
                    </div>
                    <div className='w-full py-4'>
                        {/* <Button style={{ padding: 'initial', margin: '10px 0' }} onClick={() => setIsAdd(true)} icon={<PlusOutlined />} type="link">Add more participants</Button> */}
                        <TagComponent
                            tags={Emails}
                            setTags={setEmails}
                        />
                    </div>
                    <div className='w-full flex flex-col py-4 items-start gap-3'>
                        {/* <Input
                            size='large'
                            placeholder="Text"
                            // prefix={<EmmailIcon />}  
                            style={inputStyle}
                            value={emailTitle}
                            onChange={(e) => setEmailTitle(e?.target?.value)}
                        /> */}
                        <TextArea
                            placeholder="Messsage..."
                            autoSize={{ minRows: 7, maxRows: 10 }}
                            value={emailContent}
                            onChange={(e) => setEmailContent(e.target.value)}
                        />
                        <div className='w-full py-4'>
                            <Checkbox
                                checked={sendMe}
                                onChange={(e) => {
                                    setSendMe(e?.target?.checked)
                                }}
                            >
                                Send me a copy
                            </Checkbox>
                        </div>
                    </div>
                </div>
            </Drawer >
        </>
    );
};
export default SendDrawer;