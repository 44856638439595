import React, { useEffect, useState } from 'react'
import { Input, Progress, message, } from 'antd';
import { useNavigate } from 'react-router-dom';
import { post } from '../Services/api-services';
// import { addFile, removeFile } from '../store/Actions/Action';
import { useDispatch, useSelector } from 'react-redux';
import { addFile } from '../store/Reducers/fileReducer';
import { updateDoc } from '../store/Reducers/contentReducer';
import { useTheme } from '../Themes/ThemeProvider';
import { H3, P } from '../Themes/StylingComponent';
import { TeamOutlined } from '@ant-design/icons';
const _img = require('../assets/Images/teamLogo.png')


const SelectButton = ({ title, icon, isUpload, messageContent, docs, setDocs, inputRef, desc }) => {
    const { themeColors } = useTheme();
    const dispatch = useDispatch();
    const { documents } = useSelector(state => state.contentOption);
    const navigate = useNavigate();
    const selected = false;
    const [selectedFiles, setSelectedFiles] = React.useState([])
    const [hideFileInput, setHideFileInput] = React.useState(false)
    const [fileSize, setFileSize] = React.useState(0)
    const [progress, setProgress] = useState(0);

    const handleButtonClick = () => {
        if (inputRef?.current && title === 'Upload Document') {
            if (isUpload) {
                inputRef.current.input.click(); // Trigger click event on the input element
            } else {
                message.error(messageContent)   
            }
        } else {
            // navigate('/create-doc/write')
        }
    };  

    // UPLOAD DPCUMENTS 
    const handleFileChange = async (e) => {
        try {
            const files = e.target.files;
            console.log(files);
            if (files.length > 0) {
                // Create a new FormData object
                let formData = new FormData();
                for (let i = 0; i < files.length; i++) {
                    console.log(files[i]);
                    formData.append('files', files[i]);
                }

                console.log(formData);
                // const response = await post('document/upload', formData);
                // if (response?.statusCode === 200) {
                //     // Handle successful upload
                //     message.success('Files uploaded successfully!');
                // } else {
                //     // Handle upload error
                //     message.error('Error while uploading files!');
                // }
            }
        } catch (error) {
            // Handle error
            message.error('Error while uploading files!');
            console.error('Error uploading files:', error);
        }
    };
    //  ${selected ? themeColors.bg.primary : themeColors.bg.options}

    return (
        <>
            <div
                onClick={handleButtonClick}
                className={`
                 ${selected ? themeColors.borders.primary : themeColors.borders.secondary}
                 ${selected ? themeColors.bg.primary : isUpload ? themeColors.bg.options : themeColors?.bg?.disabled}
                 w-full ${isUpload ? 'cursor-pointer' : 'cursor-help'} active:animate-pulse h-full border border-dashed  p-10 gap-3 lg:gap-5 rounded-xl flex flex-col items-center ${isUpload ? `hover:border-[#1677FF]` : ''}   justify-center
                `}  
            >
                {icon}
                <H3
                    title={title}
                    disabled={isUpload ? false : true}
                />
                <P
                    title={desc}
                    align='center'
                />
            </div>
            {!hideFileInput && title == 'Upload Document' ?
                <>
                    {/* <Progress percent={progress} /> */}
                    <Input
                        ref={inputRef}
                        type='file'
                        multiple
                        accept="application/pdf"
                        style={{ display: "none" }}
                        // onClick={() => { inputRef.current.value = ""; }}
                        // onChange={(event) => {
                        //     const newDocs = Array.from(event.target.files);
                        //     // event.target.files = new FileList([])
                        //     // dispatch(updateDoc(newDocs)) 
                        //     let _size = 0
                        //     let totalSizeInMb = 0
                        //     let notPdf = false
                        //     newDocs?.map((fl, idx) => {
                        //         _size += fl?.size
                        //         if (fl?.type != 'application/pdf') {
                        //             notPdf = true
                        //         }
                        //     })

                        //     // if (notPdf) {
                        //     //     message.error('Can only upload pdf!')   
                        //     //     return
                        //     // }
                        //     totalSizeInMb = _size / (1024 * 1024)
                        //     if (totalSizeInMb > 8) {
                        //         message?.error("File size exceeds 8 MB limit. Please either compress it or upload a smaller file"); // Displays an error message
                        //         // event.value = null; // Resets the file input field 
                        //         setHideFileInput(true);
                        //         setTimeout(() => {
                        //             setHideFileInput(false);
                        //         }, 1);
                        //         return; // Exits the function
                        //     }

                        //     setDocs((prevDocs) => {
                        //         // Filter out files that are already present in the state
                        //         const uniqueDocs = newDocs.filter((newDoc) => {
                        //             const fileSizeInMB = newDoc?.size / (1024 * 1024); // Calculate file size in MB
                        //             // setFileSize((prevFileSize) => fileSizeInMB);/
                        //             setFileSize({
                        //                 ...fileSize,
                        //                 fileSizeInMB
                        //             })
                        //             return !prevDocs.some((prevDoc) => prevDoc.name === newDoc.name);
                        //         });
                        //         return [...prevDocs, ...uniqueDocs];
                        //     });
                        //     // hide input from dom tree 
                        //     setHideFileInput(true)
                        //     setTimeout(() => {
                        //         setHideFileInput(false)
                        //     }, 1);
                        // }}
                        onChange={(event) => {
                            const newDocs = Array.from(event.target.files);
                            let totalSizeInMb = 0;

                            // Filter out non-PDF files
                            const pdfDocs = newDocs.filter((fl) => fl.type === 'application/pdf');

                            // Check if any non-PDF files exist
                            if (pdfDocs.length !== newDocs.length) {
                                message.error('Please upload pdf files only');
                            }

                            // Get current state of docs
                            setDocs((prevDocs) => {
                                // Filter out already existing files based on name (or another unique identifier)
                                const existingFileNames = prevDocs.map((doc) => doc.name);
                                const uniquePdfDocs = pdfDocs.filter((doc) => !existingFileNames.includes(doc.name));


                                // Check for duplicates
                                if (uniquePdfDocs.length !== pdfDocs.length) {
                                    message.error('Some files are already uploaded and will not be added again.');
                                }

                                // Calculate total size of the new unique PDF files
                                const totalSize = uniquePdfDocs.reduce((acc, file) => acc + file.size, 0);
                                totalSizeInMb = totalSize / (1024 * 1024);

                                if (totalSizeInMb > 8) {
                                    message.error('File size exceeds 8 MB limit. Please either compress it or upload a smaller file');
                                    return prevDocs; // Return current state without updating
                                }

                                // Update state with new unique PDF files
                                return [...prevDocs, ...uniquePdfDocs];
                            });

                            // Hide input
                            setHideFileInput(true);
                            setTimeout(() => {
                                setHideFileInput(false);
                            }, 1);
                        }}
                    />  
                </>
                : null
            }

            <div>
                {selectedFiles?.map((file, idx) => (
                    <div key={idx}>
                        <span>{file.name}</span>
                        <img
                            src={URL.createObjectURL(file)}
                            alt="file"
                            // onError={(e) => { e.target.src = <TeamOutlined /> }}    
                            onError={(e) => { e.target.src = _img }}
                        />

                    </div>
                ))}
            </div>

        </>
    )
}

export default SelectButton
