import React, { useState } from 'react';
import { Button, Input, Select, Modal, message } from 'antd';
import { EmmailIcon, LocationIcon, PeopleIcon, RoleIcon } from './Icons';
import { patch } from '../Services/api-services';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeAllSigners, updateContent, updateMethods, updateTitle } from '../store/Reducers/contentReducer';
import { useTheme } from '../Themes/ThemeProvider';


const DeleteDocDialogue = ({ open, setOpen, onDelete }) => {
    const { themeColors } = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const _documentid = location?.pathname?.includes('edit') ? location.pathname.split('/')[3] : location.pathname.split('/')[2]
    const modalContentStyle = {
        // backgroundColor: 'red',
        padding: '0'
    };

    // DELETE DOCUMENT 
    const deleteDocument = async () => {
        try {
            const _method = {
                methods: {
                    draw: true,
                    email: false,
                    mobile: false
                }
            }

            const _body = {
                trash: true
            }

            const response = await patch(`document/${_documentid}`, _body)
            if (response?.statusCode == 200) {
                setOpen(false)
                dispatch(updateContent(''))
                dispatch(removeAllSigners([]))
                dispatch(updateTitle(''));
                dispatch(updateMethods(_method))
                // navigate('/home/dashboard')
                message.success('Document Deleted Succesfully!')
                onDelete();
            } else {
                message.error(response?.message)
            }
            console.log(response);
        } catch (e) {
            console.log(`ERROR WHILE DELETING DOCUMENT ${e}`);
            message.error('Error While Deleting Document!')
        }
    }

    return (
        <>
            <Modal
                title={<h1>Are you sure you want to delete this document?</h1>}
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                closable={false}
                style={modalContentStyle}
                footer={
                    <div className={`w-full flex items-center justify-end gap-4 pt-6 `}>
                        <Button onClick={deleteDocument} size='middle' style={{ backgroundColor: themeColors.bg.backButton, color: themeColors.icons.third }} type="primary">Delete</Button>
                        <Button onClick={() => setOpen(false)} style={{ backgroundColor: themeColors.bg.inviteButton }} size='middle' type="primary">Cancel</Button>
                    </div>
                }
                header={null}   
                width={500}
            >
                <div className='w-full flex flex-col lg:flex-row items-start lg:items-start gap-3 lg:gap-6 py-6 flex-wrap'>
                    {/* <h1>Confirm deletion of this document from your account. This action cannot be undone.</h1> */}
                    <h1>Confirm deletion of this document from your account. </h1>
                </div>  
            </Modal>
        </>
    );
};
export default DeleteDocDialogue;