import React, { useState } from 'react';
import { Button, Input, Select, Modal } from 'antd';
import { EditIcon, EmmailIcon, LocationIcon, PeopleIcon, RoleIcon } from './Icons';
import { useTheme } from '../Themes/ThemeProvider';
import { H4, H5, H6 } from '../Themes/StylingComponent';

const ChangeRole = ({ open, setOpen, userData, onChangeRole, isLoading, setUserData, onCloseDialogue }) => {
    const { themeColors } = useTheme();

    const modalContentStyle = {
        // backgroundColor: 'red',
        padding: '0'
    };


    return (
        <>
            <Modal
                title={<h1>Change Role</h1>}
                centered
                open={open}
                onOk={() => onCloseDialogue()}
                onCancel={() => onCloseDialogue()}  
                style={modalContentStyle}
                footer={
                    <div className='w-full flex items-center justify-between gap-4 pt-6'>
                        <Button
                            onClick={() => onCloseDialogue()}
                            size='middle'
                            style={{ backgroundColor: themeColors.bg.backButton, color: themeColors.bg.inviteButton }}
                            type="primary"
                        >
                            Back
                        </Button>
                        <Button
                            onClick={onChangeRole}
                            size='middle'
                            type="primary"
                            loading={isLoading}
                        >
                            Save
                        </Button>
                    </div>
                }
                header={null}
                width={540}
            >
                <div className='w-full flex flex-col lg:flex-row items-start lg:items-start gap-3 lg:gap-6 py-6 flex-wrap'>
                    <div className='w-full flex items-center justify-between'>
                        <div>
                            <H4
                                title={userData?.user?.name}
                                font='semibold'
                            />
                            <H6
                                title={userData?.user?.email}
                            />
                        </div>
                        <div className='flex items-center gap-2 w-fit'>
                            <Select
                                // defaultValue={userData?.role}
                                style={{
                                    width: '100%',
                                }}
                                value={userData?.role}
                                onChange={(value) => {
                                    setUserData({
                                        ...userData,
                                        role: value
                                    })
                                }}      
                                options={[
                                    {
                                        value: 'admin',
                                        label: 'Admin',
                                    },
                                    {
                                        value: 'moderator',
                                        label: 'Moderator',
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default ChangeRole;