import React from 'react'
import { hasMeaningfulContent } from '../Services/api-services'
import ReactQuill from 'react-quill'
import "react-quill/dist/quill.snow.css";
import { useTheme } from '../Themes/ThemeProvider';

const ContentPreview = ({ textContent, noStyle }) => {
    const themeColors = useTheme();
    const modules = {
        toolbar: false, // Disable the toolbar
    };
    return (
        <>
            {/* TEXT CONTENT DIV  */}
            {hasMeaningfulContent(textContent) ?
                // <div
                //     style={{
                //         boxShadow: 'rgba(0, 0, 0, 0.16) 0px 0px 4px',
                //     }}
                //     className={`w-full max-w-[912px] mx-auto rounded-sm`}
                // >    
                <ReactQuill
                    value={textContent}
                    readOnly={true}
                    modules={modules}
                    style={{
                        backgroundColor: '#FFFFFF',
                        // maxWidth:'912px',    
                        boxShadow: noStyle ? '' : 'rgba(0, 0, 0, 0.16) 0px 0px 4px',
                    }}
                // onChange={handleChange}  
                />
                // </div>   
                : null}
        </>
    )
}

export default ContentPreview
