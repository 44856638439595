import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTheme } from '../Themes/ThemeProvider';
import { H1, P } from '../Themes/StylingComponent';
import { Button, Input, message } from 'antd';
import { capitalizeAllWords, post } from '../Services/api-services';
import { useEffect } from 'react';
import { emailRegex, passwordRegex } from '../ValidationRegex';
import { EmmailIcon } from '../Components/Icons';

const inputStyle = { fontSize: '14px' }

const _formData = {
    email: '',
}


const ForgotPassword = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { themeColors } = useTheme();
    const [otp, setOtp] = React.useState('')
    const [formData, setFormData] = React.useState({ ..._formData })
    const [count, setCount] = React.useState(30);
    const [isLoading, setIsLoading] = React.useState(false)

    // SEND OTP 
    const sendOtp = async () => {
        try {
            setIsLoading(true)
            const _body = {
                email: formData?.email
            }
            const response = await post('auth/forgetPassword', _body)
            setIsLoading(false)
            if (response?.statusCode == 200) {
                navigate('/confirm-otp', { state: { data: _body?.email } })
                message.success('OTP sent seccessfully!')
            } else {
                message.error(response?.message)
            }
        } catch (e) {
            message.error('Error while sending OTP!')
            console.log(`ERROR WHILE SENDING OTP ${e}`);
        }
    }

    React.useEffect(() => {
        document.title = capitalizeAllWords('Forgot Password');
    }, [])

    return (
        <div className='w-full h-screen flex'>
            <div className='w-full lg:w-9/12 flex flex-col items-center justify-center'>
                <div className='w-11/12 lg:w-2/6 flex flex-col  items-start gap-6'>
                    <div className='w-full flex flex-col items-center gap-5 py-2'>
                        <H1 title='FORGOT PASSWORD' />
                        {/* <P
                            align='center'
                            title='Get started with your Signizy account and begin sending documents for signatures! Easily sign your important documents with Signizy.'
                        /> */}
                    </div>
                    <div className='w-full flex flex-col items-start gap-5 py-4 lg:gap-6 '>
                        <Input
                            size='large'
                            style={inputStyle}
                            placeholder="Email"
                            prefix={<EmmailIcon color={themeColors.icons.secondary} />}
                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                            status={formData?.email?.length > 0 && !emailRegex?.test(formData.email) ? 'error' : ''}
                        />

                    </div>
                    <div className='w-full'>
                        <Button
                            onClick={sendOtp}
                            disabled={!emailRegex.test(formData?.email)}
                            size='large' style={{ width: '100%' }}
                            type="primary"
                            loading={isLoading}
                        >
                            Send Otp
                        </Button>
                    </div>
                </div>
            </div>
            <div className='hidden lg:block w-3/12 h-full bg-[#F1F6FF]'></div>
        </div >
    )
}

export default ForgotPassword

