import * as React from 'react'
import { PeopleIcon } from '../Components/Icons';
import { Button, Input, Space, Select, AutoComplete } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { Checkbox } from 'antd';
import { Switch } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { allowedImageTypes, capitalizeAllWords, get, patch, post } from '../Services/api-services';
import CountrySelectComponent from './CountrySelectComponent';
import SelectButton from './SelectButton';
import UploadButton from './UploadButton';
import { useRecoilState } from 'recoil';
import { userAuthState } from '../Services/RecoilService';
import { mobileRegex } from '../regex';
import AnimationCards from './AnimationCards';
import MobileCountryComponent from './MobileCountryComponent';


const inputStyle = { fontSize: '14px' }

const payloadData = {
    user: {
        name: '',
        mobile: '',
        country: {}
    },
    team: {
        name: '',
        logo: '',
        country: {}
    },
    organization: false
}

const _methods = {
    methods: {
        draw: true,
        email: true,
        mobile: true
    }
}

const MobileSIgnup = () => {
    const navigate = useNavigate();
    const location = useLocation();
    // const [isOrg, setIsOrg] = React.useState(() => localStorage.getItem('isOrg'));
    const [isOrg, setIsOrg] = React.useState(() => {
        const storedValue = sessionStorage.getItem('isOrg');
        return storedValue ? JSON.parse(storedValue) : false;
    });

    const { state } = location;
    const [isAgree, setIsAgree] = React.useState(false)
    const [logoUrl, setLogoUrl] = React.useState('');
    const [image, setImage] = React.useState('');
    const [formData, setFormData] = React.useState({ ...payloadData });
    const [authState, setAuthState] = useRecoilState(userAuthState)
    const [methods, setMethods] = React.useState({ ..._methods })
    const [isSelected, setIsSelected] = React.useState(false)
    const [isCompanySelected, setIsCompanySelected] = React.useState(false)
    const [isDisabled, setIsDisabled] = React.useState(true)
    const [isLoading, setIsLoading] = React.useState(false)

    // CHECK FORM VALIDATION 
    React.useEffect(() => {
        if (isOrg) {
            if (isAgree && formData?.user?.name?.length > 0 && formData?.team?.name && (mobileRegex.test(formData?.user?.mobile) && (formData?.user?.mobile?.length === formData?.user?.country?.phoneLength))) {
                setIsDisabled(false)
            } else {
                setIsDisabled(true)
            }
        } else {
            if (formData?.user?.name?.length > 0 && (mobileRegex.test(formData?.user?.mobile) && (formData?.user?.mobile?.length === formData?.user?.country?.phoneLength))) {
                setIsDisabled(false)
            } else {
                setIsDisabled(true)
            }
        }
    }, [isAgree, formData?.user?.name, formData?.team?.name, isOrg, formData?.user?.mobile])

    const [selectedCountry, setSelectedCountry] = React.useState({
        code: "IN",
        label: "India",
        phone: "91",
        phoneLength: 10,
        currency: {
            code: "INR",
            symbol: "₹"
        }
    });

    const [selectedCompanyCountry, setSelectedCompanyCountry] = React.useState({
        code: "IN",
        label: "India",
        phone: "91",
        phoneLength: 10,
        currency: {
            code: "INR",
            symbol: "₹"
        }
    });

    // SET COUNTRY DATA 
    React.useEffect(() => {
        setCountryData()
    }, [selectedCountry, selectedCompanyCountry, isOrg, logoUrl])

    const setCountryData = () => {
        setFormData({
            ...formData,
            user: {
                ...formData.user,
                country: selectedCountry
            },
            organization: isOrg,
            team: {
                ...formData.team,
                country: selectedCompanyCountry,
                logo: logoUrl
            }
        })
    }

    // CHENGR ISORG 
    const onChange = (checked) => {
        setIsOrg(checked);
        sessionStorage.setItem('isOrg', checked);
    };

    // UPDATE MOBILE 
    const updateData = (e) => {
        setFormData({
            ...formData,
            user: {
                ...formData.user,
                mobile: e?.target?.value
            }
        });
    }

    // UPDATE USER AND TEAM DETAILS 
    const updateUserData = async (_data) => {
        // Check MIME type to allow only PNG, JPEG, and BMP
        setIsLoading(true)
        try {
            if (image) {
                // Check file size
                if (image && (image.size / (1024 * 1024)) > 2) {
                    message.error('Logo size cannot exceed 2 MB. Please select a smaller logo.');
                    setIsLoading(false)
                    return;
                }
                // Check MIME type to allow only PNG, JPEG, and BMP

                if (!allowedImageTypes.includes(image.type)) {
                    message.error('Invalid file type. Please upload a PNG, JPEG, or BMP image.');
                    setImage('');
                    setIsLoading(false);
                    return;
                }
            }
            let teamLogoFileName;
            if (image) {
                // Create a new FormData object
                try {
                    let formData = new FormData();
                    formData.append('file', image);

                    const response = await post('document/upload/logo', formData)
                    if (response?.statusCode == 200) {
                        // setLogoUrl(response?.data?.fileName)
                        teamLogoFileName = response?.data?.fileName
                    } else {
                        message.error(response?.message)
                        setIsLoading(false)
                        return
                    }
                } catch (e) {
                    console.log(`ERROR WHLILE UPLOADING LOGO ${e}`);
                    setIsLoading(false)
                    return
                }
                // navigate('/home/dashboard', { state: { formData } });    
            }
            _data.status = state?.data;
            if (teamLogoFileName) _data.team.logo = teamLogoFileName

            const response = await patch(`user/${authState?.id}/userAndTeam/${authState?.teamId}`, _data)
            setIsLoading(false)
            if (response.statusCode == 200) {
                // message.success('trial added succesfully!')
                sessionStorage.removeItem('isOrg')
                if (state?.data) {
                    const checkTeams = await get(`user/${authState?.id}/team`)
                    if (checkTeams?.statusCode == 200) {
                        if (checkTeams?.data?.length > 1) {
                            navigate('/select-team')
                        } else {
                            console.log();
                            setAuthState(prevState => ({
                                ...prevState, // Keep the existing data
                                teamId: checkTeams?.data[0]?._id // Add the new team ID
                            }));
                            navigate('/home/dashboard')
                        }
                    }
                } else {
                    navigate('/select-plan')
                }
            }
        } catch (e) {
            console.log(`ERROR WHILE UPDATING USER DATA ${e}`);
        }
    }

    React.useEffect(() => {
        document.title = capitalizeAllWords('SignUp');
    }, [])


    return (
        <div className='w-full h-screen flex'>
            <div className='w-full lg:w-9/12 flex flex-col items-center justify-center'>
                <div className='w-11/12 sm:w-1/2 lg:w-4/6 xl:w-2/6 flex flex-col  items-start gap-5 '>
                    <div className='w-full flex items-center justify-end py-12'>
                        {/* <Button
                            onClick={() => {
                                if (state?.data) {
                                    navigate('/home/dashboard')
                                } else {
                                    navigate('/select-plan')
                                }
                            }}
                            style={{ color: '#8E8E8E' }}
                            type="link"
                        >
                            Skip
                        </Button> */}
                    </div>
                    <Input
                        size='large'
                        placeholder="Name *"
                        prefix={<PeopleIcon color='#AAAAAA' />}
                        style={inputStyle}
                        value={formData?.user?.name}
                        // status={formData?.user?.name?.length > 0 && !mobileRegex?.test(formData.user.name) ? 'error' : ''}
                        onChange={(e) => {
                            setFormData({
                                ...formData,
                                user: {
                                    ...formData.user,
                                    name: e.target.value
                                }
                            });
                        }}
                    />
                    <MobileCountryComponent
                        selectedCountry={selectedCountry}
                        setSelectedCountry={setSelectedCountry}
                        mobile={formData?.user?.mobile}
                        updateData={(e) => updateData(e)}
                        isSelected={isSelected}
                        setIsSelected={setIsSelected}
                        methods={methods?.methods}
                    />
                    {!state?.data ?
                        <div className='flex items-center gap-2 py-2'>
                            <Switch size="medium" checked={isOrg ? true : false} onChange={onChange} />
                            <p className='text-[#424D51] text-sm'>Use it as an Organization</p>
                        </div>
                        : null}

                    {isOrg === true &&
                        <div className='flex flex-col w-full items-center gap-5'>
                            <div className='w-full flex gap-3'>
                                <div className='w-1/4  flex items-start justify-end '>
                                    <UploadButton
                                        icon={<PlusOutlined style={{ color: '#1677FF' }} />}
                                        title='Company Logo'
                                        image={image}
                                        setImage={setImage}
                                        logoUrl={logoUrl}
                                    />
                                </div>
                                <div className='w-9/12 flex flex-col items-center justify-center gap-3 '>
                                    <Input
                                        size='large'
                                        placeholder="organisation name *"
                                        prefix={<PeopleIcon color='#AAAAAA' />}
                                        style={inputStyle}
                                        onChange={(e) => {
                                            setFormData({
                                                ...formData,
                                                team: {
                                                    ...formData.team,
                                                    name: e.target.value
                                                }
                                            });
                                        }}
                                    />
                                    {/* COMPANY COUNTRY  */}
                                    <CountrySelectComponent
                                        selectedCountry={selectedCompanyCountry}
                                        setSelectedCountry={setSelectedCompanyCountry}
                                        isSelected={isCompanySelected}
                                        setIsSelected={setIsCompanySelected}
                                        methods={methods?.methods}
                                    />
                                </div>
                            </div>

                            <div className='flex w-full items-start gap-2 py-2'>
                                <Checkbox onChange={(e) => setIsAgree(e.target.checked)} />
                                <p className='text-[#8E8E8E] text-[10px]'>I certify that I am the designated signing authority for this organization, authorized to sign and manage documents on its behalf.</p>
                            </div>
                        </div>
                    }
                    <div className='w-full py-2'>
                        <Button
                            // disabled={!isAgree && (!formData?.user?.name?.length > 0 && !formData?.team?.name?.length > 0)}
                            disabled={isDisabled}
                            size='large'
                            style={{ width: '100%' }}
                            type="primary"
                            onClick={() => updateUserData(formData)}
                            loading={isLoading}
                        >
                            Start Your Journey
                        </Button>
                    </div>
                </div>
            </div>
            <AnimationCards />
            {(isSelected || isCompanySelected) &&
                <div
                    onClick={() => {
                        setIsSelected(false)
                        setIsCompanySelected(false)
                    }}
                    className='w-full h-screen bg-transparent fixed top-0 left-0 z-60'>
                </div>
            }
        </div >

    )
}

export default MobileSIgnup
