import React from 'react'
// SHOW PDF IMPORTS 
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker } from 'pdfjs-dist/build/pdf.worker.entry';
import { BASEURL } from '../Services/api-services';
import { DocumentIcon } from '../Components/Icons';
import { useTheme } from '../Themes/ThemeProvider';

const PDFVIewer = ({ documents }) => {  
    const { themeColors } = useTheme();
    return (
        <>
            <div className='py-2  w-full  flex flex-col items-start gap-4'>
                {documents?.map((document, idx) => {
                    return (
                        <div key={idx} className='w-full  bg-white py-2 pb-4'>
                            <div className='flex  items-center justify-between w-full  bg-white'>
                                <div className='flex items-center gap-3 py-4 px-6 '>
                                    <DocumentIcon color={themeColors.icons.third} />
                                    <h6 className={`text-sm lg:text-base ${themeColors.text.secondary}`}>{document?.name}</h6>
                                </div>
                            </div>
                            <div className='w-full'>
                                <Viewer fileUrl={`${BASEURL}public/documents/${document?.fileName}`} />
                            </div>
                        </div>
                    )
                })} 
            </div>
        </>
    )
}

export default PDFVIewer
