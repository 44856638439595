import React, { useState } from 'react';
import { Button, Input, Select, Modal } from 'antd';
import { DrawIcon, DrawLinedIcon, EmailOptionIcon, EmmailIcon, LocationIcon, MobileLinedIcon, MobileOptionIcon, PeopleIcon, RoleIcon } from './Icons';
import OptionComponent from './OptionComponent';
import { useTheme } from '../Themes/ThemeProvider';

const OptionsDialogue = ({ open, setOpen, methods, isMobile, _type, setType, setOpenSendOtp, setOpenSignPad, currentSigner }) => {
    const { themeColors } = useTheme();

    const modalContentStyle = {
        // backgroundColor: 'red',
        padding: '0'
    };

    const selectOption = (type) => {
        setType(type)
        if (type === 'draw') {
            setOpen(false)
            setOpenSignPad(true)
        } else {
            setOpen(false)
            setOpenSendOtp(true)
            // onSendOtp(type)
        }
    }

    return (
        <>
            <Modal
                title={<h1 className='text-xl'>Choose your method for signing</h1>}
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                style={modalContentStyle}
                footer={null}
                width={500}
            >   
                <div className='w-full flex flex-col lg:flex-row items-start lg:items-start gap-3 lg:gap-6 py-6 flex-wrap'>
                    {methods?.draw ?
                        <OptionComponent
                            title='draw'
                            text='Draw on a signature pad via touch or mouse'
                            icons={<DrawLinedIcon />}
                            handleClick={(type) => selectOption(type)}
                        />
                        : null}
                    {methods?.email ?
                        <OptionComponent
                            title='email'
                            text='Sign electronically via a email verification code'
                            icons={<EmmailIcon color={themeColors.bg.inviteButton} />}
                            handleClick={(opt) => selectOption(opt)}
                        />
                        : null}
                    {
                        methods?.mobile ?
                            <OptionComponent
                                title='phone'
                                text='Sign electronically via a phonew verification code'
                                icons={<MobileLinedIcon />}
                                handleClick={(opt) => selectOption(opt)}
                            />
                            : null}
                </div>
            </Modal>

        </>
    );
};
export default OptionsDialogue;