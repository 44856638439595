// Settings.js
import { LeftArrowIcon } from '../Components/Icons';
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useDimensions } from "../DimensionContext/DimensionContext";
import { Layout } from 'antd';
import React, { useEffect, useState } from 'react'
import { useTheme } from '../Themes/ThemeProvider';
import { H4 } from '../Themes/StylingComponent';
import { capitalizeAllWords, get } from '../Services/api-services';
import SettingDrawer from '../Components/SettingDrawer';
import { useRecoilState } from 'recoil';
import { userAuthState } from '../Services/RecoilService';
const { Header } = Layout;

const Settings = () => {
    const { themeColors } = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const { lg, xl, xxl } = useDimensions()
    const [heading, setHeading] = useState('');
    const [authState, setAuthState] = useRecoilState(userAuthState)
    const [currentUser, setCurrentUser] = React.useState('')

    useEffect(() => {
        if (location?.pathname?.split('/')[2]) {
            setHeading(location?.pathname?.split('/')[2])
        } else {
            setHeading('settings')
        }
    }, [location])

    const handleClick = () => {
        if (lg || xl || xxl) {
            navigate('/home/dashboard')
        } else {
            if (heading == 'settings') {
                navigate('/home/dashboard')
            } else {
                navigate('/settings')
            }
        }
    }

    React.useEffect(() => {
        const currentPath = location.pathname?.split('/');
        document.title = capitalizeAllWords(currentPath[currentPath?.length - 1]);
    }, [location.pathname]);
    
    return (
        <div className='w-full flex  flex-1 h-full  overflow-hidden'>
            {lg || xl || xxl ?
                <div className='w-2/12  h-full'>    
                    <SettingDrawer />
                </div>  
                : null}
            <div className='w-10/12 flex-1 overflow-y-auto'>
                <Outlet />      
            </div>
        </div>
    )
}

export default Settings
