import { AutoComplete, Input } from 'antd';
import React, { useState } from 'react'
import { DownArrowIcon } from './Icons';
import { get } from '../Services/api-services';
import { mobileRegex } from '../ValidationRegex';
import { useDimensions } from '../DimensionContext/DimensionContext';

const inputStyle = { fontSize: '14px' }

const MobileCountryComponent = ({ mobile, methods, profile, isSelected, setIsSelected, updateData, selectedCountry, setSelectedCountry }) => {
    // const [isSelected, setIsSelected] = React.useState(false)
    const [CountryData, setCountryData] = React.useState([])
    const [searchInputValue, setSearchInputValue] = useState('');
    const { xs, sm, lg, xl, xxl } = useDimensions();

    React.useEffect(() => { 
        getCountriesData(); 
    }, [])

    // GET ALL COUNTRIES DATA 
    const getCountriesData = async () => {
        try {
            const response = await get('auth/country')
            if (response.statusCode == 200) {
                setCountryData(response.data)
            }
        } catch (e) {
            console.log(`ERROR WHILE GETTING COUNTRIES DATA ${e}`);
        }
    }

    // SELECT COUNTRY 
    const selectBefore = (
        <div
            onClick={() => {
                if (methods?.mobile || profile) {
                    setIsSelected(!isSelected)
                }
            }}
            className={`flex items-center gap-2 w-12 ${methods?.mobile || profile ? 'cursor-pointer' : 'cursor-help'}`}
        >
            <img className='w-6 h-6 object-contain rounded' src={`https://flagcdn.com/w20/${selectedCountry?.code.toLowerCase()}.png`} alt="flag" />
            <DownArrowIcon />
        </div>
    );

    // set country data 
    const handleSelect = (value, option) => {
        if (option) {
            setSelectedCountry(option?.country);
            setIsSelected(false)
        }
    };


    return (
        <>
            <div className='w-full relative'>
                <Input
                    style={inputStyle}
                    size='large'
                    // prefix={mobile != undefined ? "+" + selectedCountry.phone : ''}
                    placeholder='Mobile Number *'
                    addonBefore={selectBefore}
                    value={mobile !== undefined ? mobile : selectedCountry?.label}
                    status={mobile?.length > 0 && mobile?.length != selectedCountry?.phoneLength || (!mobileRegex?.test(mobile) && mobile?.length > 0) ? 'error' : ''}
                    onChange={(e) => {
                        mobile != undefined && updateData(e)
                    }}
                    // disabled={!methods?.mobile && !profile}  
                    onFocus={() => {
                        if (mobile == undefined && profile) {
                            setIsSelected(true)
                        }
                    }}
                />
                {isSelected &&
                    <div className='w-full absolute top-12 z-50'>
                        <AutoComplete
                            popupClassName="certain-category-search-dropdown"
                            popupMatchSelectWidth={lg || xl || xxl ? 480 : 350}
                            style={{
                                width: '100%',
                            }}
                            options={CountryData?.map(country => ({
                                value: `${country.code} ${country.label} ${country.phone}  ${country.phoneLength}`,
                                label: <>
                                    <div className='flex object-contain gap-2 my-1'>
                                        <img className='w-6 h-6 object-contain rounded' src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`} alt={country.name} /> {country.label} ({country.code})
                                    </div>
                                </>,
                                code: country.code,
                                country: country
                            }))}
                            onSelect={handleSelect}
                            filterOption={(inputValue, option) => {
                                return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }}
                            open // Set open to true to show suggestions by default 
                        >
                            <Input.Search
                                placeholder="search here"
                            />
                        </AutoComplete>
                    </div>
                }
            </div >
        </>
    )
}

export default MobileCountryComponent
