import { Tooltip } from "antd";
import { useTheme } from "./ThemeProvider"

export const H1 = ({ title, align, font }) => {
    const { themeColors } = useTheme();
    return (
        <h1 className={`text-3xl text-${align} lg:text-4xl  ${themeColors.headings.primary} font-${font}`}>{title}</h1>
    )
}

export const H2 = ({ title }) => {
    const { themeColors } = useTheme();
    return (
        <h1 className={`text-xl lg:text-2xl capitalize ${themeColors.headings.primary} font-medium`}>{title}</h1>
    )
}

export const H3 = ({ title, disabled }) => {
    const { themeColors } = useTheme();
    return (
        <h1 className={`text-lg lg:text-xl   ${disabled ? themeColors.headings.disabled : themeColors?.headings?.primary} font-medium`}>{title}</h1>
    )
}

export const H4 = ({ title, align, font, toolTipTitle }) => {
    const { themeColors } = useTheme();
    return (
        <Tooltip title={toolTipTitle}>
            <h1 className={`text-base capitalize  lg:text-lg text-${align} font-${font} ${themeColors.headings.primary} `}>{title}</h1>
        </Tooltip>
    )
}

export const H5 = ({ title, font, align, toolTipTitle }) => {
    const { themeColors } = useTheme();
    return (
        <Tooltip title={toolTipTitle}>
            <h1 className={`text-sm whitespace-nowrap lg:text-base text-${align} ${themeColors.headings.primary} font-${font}`}>{title}</h1>
        </Tooltip>
    )
}

export const H6 = ({ title, toolTipTitle }) => {
    const { themeColors } = useTheme();
    return (
        <Tooltip title={toolTipTitle}>
            <h1 className={`text-xs lg:text-sm ${themeColors.headings.primary} font-normal`}>{title}</h1>
        </Tooltip>
    )
}

export const Span = ({ title }) => {
    const { themeColors } = useTheme();
    return (
        <h1 className={`text-xs mt-3 lg:text-sm ${themeColors.headings.primary} font-normal`}>{title}</h1>
    )
}

export const P = ({ title, align, toolTipTitle }) => {
    const { themeColors } = useTheme();
    return (
        <Tooltip title={toolTipTitle}>    
            <h1 className={`text-xs lg:text-sm text-${align}  ${themeColors.text.fourth} font-normal`}>{title}</h1>
        </Tooltip>  
    )
}

export const XS = ({ title, align, color, font, capitalize, toolTipTitle }) => {
    const { themeColors } = useTheme();
    return (
        <Tooltip title={toolTipTitle}>
            <p className={`text-[10px] lg:text-xs ${capitalize} whitespace-nowrap ${color}   font-${font} `}>{title}</p>
        </Tooltip>

    )
}

export const Price = ({ title, align }) => {
    const { themeColors } = useTheme();
    return (
        <div className='relative'>  
            {/* <span className='w-[52px] bg-[#AAAAAA] h-[2px] absolute top-1/2 left-0'></span> */}
            <h6 className='text-xl whitespace-nowrap line-through text-[#AAAAAA]'>{title}</h6>
        </div>
    )
}



