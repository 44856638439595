import React from 'react'
import SettingDrawer from '../Components/SettingDrawer'
import { useDimensions } from "../DimensionContext/DimensionContext";
import { Input, Button, Select, theme, message, Avatar, Skeleton, Tooltip } from "antd";
import Header from '../Components/Header';
import { useTheme } from '../Themes/ThemeProvider';
import { allowedImageTypes, BASEURL, get, patch, post } from '../Services/api-services';
import { useRecoilState } from 'recoil';
import { userAuthState } from '../Services/RecoilService';
import { useEffect } from 'react';
import CountrySelectComponent from '../Components/CountrySelectComponent';
import { FormHeading, H4, H5, H6, P } from '../Themes/StylingComponent';
import UploadButton from '../Components/UploadButton';
import { EditOutlined, PlusOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import EditUserDialogue from '../Components/EditUserDialogue';
import EditUserTeamDialogue from '../Components/EditUserTeamDetails';
import ChangePasswordDialogue from '../Components/ChangePasswordDialogue';
import { UserIcon } from '../Components/Icons';
import SignaturePadComponent from '../Components/SignaturePadComponent';
import EditSignComponent from '../Components/EditSignComponent';
const inputStyle = { fontSize: '14px' }

const { Option } = Select;

const Profile = () => {
    const { themeColors } = useTheme();
    const { lg, xl, xxl } = useDimensions()
    const [authState, setAuthState] = useRecoilState(userAuthState)
    const [isSelected, setIsSelected] = React.useState(false)
    const [currentUser, setCurrentUser] = React.useState('')
    const [userTeamDetails, setUerTeamDetails] = React.useState('')
    const [isCompanySelected, setIsCompanySelected] = React.useState(false)
    const [logoUrl, setLogoUrl] = React.useState('');
    const [image, setImage] = React.useState('');
    const [open, setOpen] = React.useState(false)
    const [companyOpen, setCompanyOpen] = React.useState(false)
    const [openPassword, setOpenPassword] = React.useState(false)
    const [imgLoaded, setImgLoaded] = React.useState(false)
    const [dataLoading, setDataLoading] = React.useState(true);
    const [teamdataLoading, setTeamDataLoading] = React.useState(true);
    const [truncatedTeamName, setTruncatedTeamName] = React.useState('');
    const [openSignPad, setOpenSignPad] = React.useState(false)
    const [formData, setFormData] = React.useState({
        password: '',
        confirmPassword: ''
    })

    const [selectedCountry, setSelectedCountry] = React.useState({
        code: 'In',
        label: "India",
        phone: "91",
        phoneLength: 10
    });

    const [selectedCompanyCountry, setSelectedCompanyCountry] = React.useState({
        code: 'In',
        label: "India",
        phone: "91",
        phoneLength: 10
    });

    const FormHeading = ({ title }) => (
        <P
            title={title}
            align='left'
        />
    )

    const FormContent = ({ title }) => (
        <H5
            title={title}
            align='left'
        />
    )

    // GET USER DETAILS 
    useEffect(() => {
        getUserDetails();
        getUserTeamDetails();
    }, [])

    const getUserDetails = async () => {
        try {
            const response = await get(`team/${authState?.teamId}/user/${authState?.id}`)
            if (response?.statusCode == 200) {
                setCurrentUser(response?.data)
                if (response?.data?.country) {
                    setSelectedCountry(response?.data?.country)
                    setDataLoading(false)
                }
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING USER DETAILS ${e}`);
        }
    }

    const getUserTeamDetails = async () => {
        try {
            const response = await get(`team/${authState?.teamId}`)
            if (response?.statusCode == 200) {
                setUerTeamDetails(response?.data)
                setLogoUrl(response?.data?.logo)
                // setCurrentUser(response?.data)
                if (response?.data?.country) {
                    setSelectedCompanyCountry(response?.data?.country)
                    setTeamDataLoading(false)
                }
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING USER DETAILS ${e}`);
        }
    }

    // UPDATE USER DETAILS 
    const updateUserDetail = async () => {
        try {
            let _body = { ...currentUser };
            if (_body._id) delete _body._id;
            if (_body.createdAt) delete _body.createdAt;
            if (_body.updateAt) delete _body.updateAt;
            _body.country = selectedCountry;
            const response = await patch(`user/${authState?.id}`, _body)
            if (response?.statusCode == 200) {
                setOpen(false)
                getUserDetails()
            }
            // let _team = { ...userTeamDetails };
            // if (_team._id) delete _team._id;
            // if (_team.createdAt) delete _team.createdAt;
            // if (_team.updateAt) delete _team.updateAt;
            // _team.country = selectedCompanyCountry;
            // if (logoUrl) _team.logo = logoUrl;

            // const teamResponse = patch(`team/${authState?.teamId}`, _team)
            // if (teamResponse?.statusCode == 200) getUserTeamDetails();

        } catch (e) {
            console.log(`ERROR WHILE UPDATING USER ${e}`);
        }
    }

    // UPDATE USER DETAILS 
    const updateTeamDetail = async () => {
        try {
            let patchPayload = {};
            let _team = { ...userTeamDetails };
            if (image) {
                // Check file size
                if (image && (image.size / (1024 * 1024)) > 2) {
                    message.error('Logo size cannot exceed 2 MB. Please select a smaller logo.');
                    return;
                }

                // if (image && image?.type == 'image/gif') {
                //     message.error('can only upload image!')
                //     setImage('')
                //     return
                // }
                // Check MIME type to allow only PNG, JPEG, and BMP
                if (!allowedImageTypes.includes(image.type)) {
                    message.error('Invalid file type. Please upload a PNG, JPEG, or BMP image.');
                    setImage('');
                    return;
                }
            }


            let teamLogoFileName;
            if (image) {
                // Create a new FormData object
                try {
                    let formData = new FormData();
                    formData.append('file', image);

                    const response = await post('document/upload/logo', formData)
                    if (response?.statusCode == 200) {
                        setLogoUrl(response?.data?.fileName)
                        teamLogoFileName = response?.data?.fileName
                        // patchPayload.logo = response?.data?.fileName
                    } else {
                        message.error(response.message)

                        return
                    }
                } catch (e) {
                    console.log(`ERROR WHLILE UPLOADING LOGO ${e}`);
                    return
                }
                // navigate('/home/dashboard', { state: { formData } });    
            }
            if (teamLogoFileName) {
                patchPayload.logo = teamLogoFileName
            }
            delete _team.country;
            // _team.country = selectedCompanyCountry;

            patchPayload = {
                name: _team?.name,
                // country: selectedCompanyCountry,
                logo: teamLogoFileName
            }

            const response = await patch(`team/${authState?.teamId}`, patchPayload)
            if (response?.statusCode == 200) {
                setCompanyOpen(false)
                getUserTeamDetails();
                setImage('')
            }

        } catch (e) {
            console.log(`ERROR WHILE UPDATING USER ${e}`);
        }
    }

    // CHANGE PASSWORD FUNCTION 
    const changePassword = async () => {
        try {
            const _body = {
                password: formData?.password,
                confirmPassword: formData?.confirmPassword,
            }
            const response = await post(`user/${authState?.id}/changePassword`, _body)
            if (response?.statusCode == 200) {
                setOpenPassword(false);
                getUserDetails();
                message.success('Password Changed Successfully')
                setFormData({
                    password: '',
                    confirmPassword: ''
                })
            } else {
                message.error(response?.data?.message)
            }
        } catch (e) {
            console.log(`ERROR WHILE CHANGING PASSWORD ${e}`);
            message.error('error while changing password')
        }
    }

    // DIV STYLES 

    const maincontainer = 'w-full flex flex-col mainDiv items-start  gap-6 border-b  py-6'
    const childContainer = 'w-full lg:w-11/12 flex flex-col items-start gap-6'
    const fieldStyle = 'w-full flex items-start justify-between '
    const header = 'w-fit gap-3 flex pb-6'
    const dataFieldStyle = `text-sm whitespace-nowrap lg:text-base text-left ${themeColors.headings.primary} font-normal`

    return (
        <>
            <div className={`w-full  flex-1 overflow-y-auto py-4 px-3 lg:px-6  ${themeColors.bg.primary}`}>
                <div className=' flex flex-col items-start gap-3 py-4'>
                    <Header title='Welcome, to your profile page' />
                    <p className={`text-xs lg:text-sm ${themeColors.text.secondary}`}>This is your hub for managing your account and personalizing your experience with Signizy. Here, you can update your information!</p>
                </div>
                <div className='w-full  flex-1 flex flex-col items-start gap-4 '>
                    {/* USER DETAILS  */}
                    <div className={maincontainer}>
                        <div className='w-full lg:w-3/5'>
                            <div className={header}>
                                <H4
                                    title='User Details'
                                    font='medium'
                                />
                                <Button
                                    onClick={() => setOpen(true)}
                                    type='text'
                                    shape='circle'
                                    icon={<EditOutlined />}
                                />
                            </div>
                            <div className={childContainer}>
                                <div className={fieldStyle}>
                                    <FormHeading title='Name' />
                                    <div className='w-2/3 '>
                                        {!dataLoading ?
                                            currentUser?.name ?
                                                <Tooltip title={currentUser?.name?.length > 20 ? currentUser?.name : ''}>
                                                    <h1 className={dataFieldStyle}>
                                                        {currentUser?.name.length > 20 ? currentUser?.name.substring(0, 20) + '...' : currentUser?.name}
                                                    </h1>
                                                </Tooltip>
                                                : null
                                            :
                                            <div className='w-full h-6 '>
                                                <Skeleton active paragraph={{ rows: 0 }} />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className={fieldStyle}>
                                    <FormHeading title='Email' />
                                    <div className='w-2/3'>
                                        {!dataLoading ?
                                            currentUser?.email ?
                                                <Tooltip title={currentUser?.email?.length > 20 ? currentUser?.email : ''}>
                                                    <h1 className={dataFieldStyle}>
                                                        {currentUser?.email.length > 20 ? currentUser?.email.substring(0, 20) + '...' : currentUser?.email}
                                                    </h1>
                                                </Tooltip>
                                                : null
                                            :
                                            <div className='w-full h-6 '>
                                                <Skeleton active paragraph={{ rows: 0 }} />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className={fieldStyle}>
                                    <FormHeading title='Phone Number' />
                                    <div className='w-2/3'>
                                        {!dataLoading ?
                                            currentUser?.mobile ?
                                                <FormContent
                                                    title={`+${currentUser?.country?.phone} ${currentUser?.mobile}`}
                                                    align='left'
                                                /> : null
                                            :
                                            <div className='w-full h-6 '>
                                                <Skeleton active paragraph={{ rows: 0 }} />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className={fieldStyle}>
                                    <FormHeading title='Role' />
                                    <div className='w-2/3'>
                                        {!dataLoading ?
                                            currentUser?.role ?
                                                <Tooltip title={currentUser?.role?.length > 20 ? currentUser?.role : ''}>
                                                    <h1 className={dataFieldStyle}>
                                                        {currentUser?.role.length > 20 ? currentUser?.role.substring(0, 20) + '...' : currentUser?.role}
                                                    </h1>
                                                </Tooltip>
                                                : null
                                            :
                                            <div className='w-full h-6 '>
                                                <Skeleton active paragraph={{ rows: 0 }} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* UPDATE SIGNATURE  */}
                    <div className={maincontainer}>
                        <div className='w-full lg:w-3/5'>
                            <div className={header}>
                                <H4
                                    title='User Signature'
                                    font='medium'
                                />
                                <Button
                                    type='text'
                                    shape='circle'
                                    onClick={() => {
                                        setOpenSignPad(true);
                                    }}
                                    icon={<EditOutlined />}
                                />
                            </div>
                            <div className={fieldStyle}>
                                <FormHeading title='Signature' />
                                <div className='w-2/3'>
                                    {!dataLoading ?
                                        currentUser?.svg ?
                                            <div className='w-16 h-14  flex items-center justify-center '>
                                                <img className='h-full w-full object-contain' src={`data:image/svg+xml;utf8,${encodeURIComponent(currentUser?.svg)}`} alt="SVG Image" />
                                            </div>
                                            : null
                                        :
                                        <div className='w-full h-6 '>
                                            <Skeleton active paragraph={{ rows: 0 }} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* USER TEAM DETAILS  */}
                    <div className={maincontainer}>
                        <div className='w-full lg:w-3/5'>
                            <div className={header}>
                                <H4
                                    title=' Team Details'
                                    font='medium'
                                />
                                {authState?.role === 'owner' &&
                                    <Button
                                        type='text'
                                        shape='circle'
                                        onClick={() => {
                                            setCompanyOpen(true)
                                        }}
                                        icon={<EditOutlined />}
                                    />
                                }
                            </div>
                            <div className={childContainer}>
                                <div className={fieldStyle}>
                                    <FormHeading title='Team Name' />
                                    <div className='w-2/3'>
                                        {!teamdataLoading ?
                                            userTeamDetails?.name ?
                                                <Tooltip title={userTeamDetails?.name?.length > 20 ? userTeamDetails?.name : ''}>
                                                    <h1 className={dataFieldStyle}>
                                                        {userTeamDetails?.name.length > 20 ? userTeamDetails?.name.substring(0, 20) + '...' : userTeamDetails?.name}
                                                    </h1>
                                                </Tooltip>
                                                : null
                                            :
                                            <div className='w-full h-6 '>
                                                <Skeleton active paragraph={{ rows: 0 }} />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className={fieldStyle}>
                                    <FormHeading title='Country' />
                                    <div className='w-2/3'>
                                        {!teamdataLoading ?
                                            userTeamDetails?.country ?
                                                <Tooltip title={userTeamDetails?.country?.label?.length > 20 ? userTeamDetails?.country?.label : ''}>
                                                    <h1 className={dataFieldStyle}>
                                                        {userTeamDetails?.country?.label.length > 20 ? userTeamDetails?.country?.label.substring(0, 20) + '...' : userTeamDetails?.country?.label}
                                                    </h1>
                                                </Tooltip>

                                                : null
                                            :
                                            <div className='w-full h-6 '>
                                                <Skeleton active paragraph={{ rows: 0 }} />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className={fieldStyle}>
                                    <FormHeading title='Company Logo' />
                                    <div className='w-2/3 '>
                                        {logoUrl ?
                                            <div className='w-1/4  '>
                                                <img
                                                    className={`w-full h-full ${imgLoaded ? 'opacity-100' : 'opacity-0'} object-contain`}
                                                    src={`${BASEURL}public/logos/${logoUrl}`}
                                                    alt=""
                                                    onLoad={() => setImgLoaded(true)}
                                                />
                                                {!imgLoaded ? <Avatar
                                                    // src={`${BASEURL}public/logos/${logoUrl}`}
                                                    shape="square"
                                                    size={74}
                                                    icon={<TeamOutlined />}
                                                /> : null}
                                            </div>
                                            :
                                            <div className='w-1/4  flex items-start justify-start '>
                                                <Avatar
                                                    // src={`${BASEURL}public/logos/${logoUrl}`}
                                                    shape="square"
                                                    size={74}
                                                    icon={<TeamOutlined />}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* PASSWORD CHANGE  */}
                    <div className={maincontainer}>
                        <div className='w-full lg:w-3/5'>
                            <div className={header}>
                                <H4
                                    title='User Password'
                                    font='medium'
                                />
                                <Button
                                    type='text'
                                    shape='circle'
                                    onClick={() => {
                                        setOpenPassword(true)
                                    }}
                                    icon={<EditOutlined />}
                                />
                            </div>
                            <div className={childContainer}>
                                <div>
                                    <FormHeading title='Password' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    (isSelected || isCompanySelected) &&
                    <div
                        onClick={() => {
                            setIsSelected(false)
                            setIsCompanySelected(false)
                        }}
                        className='w-full h-screen bg-transparent fixed top-0 left-0 z-60'>
                    </div>
                }
            </div>

            {/* EDIT USER DETAILS DIALOGUE  */}
            {open ?
                <EditUserDialogue
                    open={open}
                    setOpen={setOpen}
                    isSelected={isSelected}
                    currentUser={currentUser}
                    setIsSelected={setIsSelected}
                    setCurrentUser={setCurrentUser}
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                    updateUser={updateUserDetail}
                />
                : null}

            {/* EDIT TEAM DIALOGUE  */}
            {companyOpen ?
                <EditUserTeamDialogue
                    open={companyOpen}
                    logoUrl={logoUrl}
                    setLogoUrl={setLogoUrl}
                    image={image}
                    setImage={setImage}
                    setOpen={setCompanyOpen}
                    isCompanySelected={isCompanySelected}
                    userTeamDetails={userTeamDetails}
                    setIsCompanySelected={setIsCompanySelected}
                    setUerTeamDetails={setUerTeamDetails}
                    selectedCompanyCountry={selectedCompanyCountry}
                    setSelectedCompanyCountry={setSelectedCompanyCountry}
                    updateUser={updateTeamDetail}
                    onCancelEdit={() => {
                        getUserTeamDetails();
                        setCompanyOpen(false);
                    }}
                />
                : null}

            {openPassword ?
                <ChangePasswordDialogue
                    formData={formData}
                    setFormData={setFormData}
                    open={openPassword}
                    setOpen={setOpenPassword}
                    changePassword={changePassword}
                />
                : null}

            {/* SIGNTURE PAD COMPONENT  */}
            {openSignPad ?
                <EditSignComponent
                    open={openSignPad}
                    setOpen={setOpenSignPad}
                    currentUser={currentUser}
                    getDetails={() => getUserDetails()}
                    previousSignature={currentUser?.svg}  // Pass the previous signature SVG here
                    onclose={() => {
                        setOpenSignPad(false)
                    }}
                />
                : null}
        </>
    )
}

export default Profile 