import React, { useEffect, useState } from 'react'
import { Card, Divider, Button, Radio, message, Breadcrumb } from 'antd'
import { H3, H2, H5, H4, H1 } from '../Themes/StylingComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import PlanCard from '../Components/PlanCard';
import { userAuthState } from '../Services/RecoilService';
import { useRecoilState } from 'recoil';
import Payments from './Payments';
import moment from 'moment';
import { useDimensions } from '../DimensionContext/DimensionContext';
import SettingDrawer from '../Components/SettingDrawer';
import Header from '../Components/Header';
import { useTheme } from '../Themes/ThemeProvider';
import { capitalizeAllWords } from '../Services/api-services';
import BillCard from '../Components/BillCard';

export default function Bill() {
    const { themeColors } = useTheme();
    const { lg, xl, xxl } = useDimensions()
    const location = useLocation();
    const navigate = useNavigate();

    const { plan, isYearly, trial, currency, isSetting, isBilling, credits, isTrial } = location?.state;

    console.log(plan, 'plan');

    const [authState, setAuthState] = useRecoilState(userAuthState);
    const [price, setPrice] = useState(plan?.price[currency.code]);
    const [isYearlyPlan, setIsYearlyPlan] = useState(isYearly)
    const [bill, setbill] = useState({
        price: isYearlyPlan ? price.monthlyPrice * 12 : price.monthlyPrice,
        discount: isYearlyPlan ? (price.monthlyPrice * 12) - price.offerYearlyPrice : price.monthlyPrice - price.offerMonthlyPrice,
        couponDiscount: 0,
        credits: credits ? credits : 0,
        tax: 0
    })
    const [couponTrue, setCouponTrue] = useState(false);

    const rowstyle = 'flex justify-between px-6';

    function getDateAfterDays(days) {
        console.log(days)
        const currentDate = new Date();
        const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
        const futureDate = new Date(currentDate.getTime() + days * millisecondsPerDay);
        return moment(futureDate).format("DD MMM YYYY");
    }

    useEffect(() => {
        setbill({
            price: isYearlyPlan ? price.monthlyPrice * 12 : price.monthlyPrice,
            discount: isYearlyPlan ? (price.monthlyPrice * 12) - price.offerYearlyPrice : price.monthlyPrice - price.offerMonthlyPrice,
            couponDiscount: 0,
            credits: credits ? credits : 0,
            tax: 0
        })
    }, [isYearlyPlan])

    React.useEffect(() => {
        document.title = capitalizeAllWords('Pay now');
    }, [])


    return (
        <div className={`w-full  flex-1 h-full flex flex-col overflow-y-auto  ${themeColors?.bg?.primary}`}>
            <div className=' flex  px-4 flex-col items-start gap-3 py-4'>
                <Header title='Manage Team' />
            </div>
            <div className='w-full flex items-center justify-center  flex-1 px-0 md:px-4'>
                <div className='w-full  lg:w-full xl:lg:w-[80%] flex flex-col lg:flex-row gap-10 lg:gap-0 justify-between  pb-4 px-4 lg:px-0'>
                    <div className='w-full   lg:w-1/2 flex flex-col items-start justify-center flex-1 h-full'>
                        {/* <PlanCard /> */}
                        <div className='w-full md:w-full lg:w-11/12 xl:w-8/12'>
                            <BillCard
                                currency={currency}
                                plan={plan}
                                credits={credits ? credits : 0}
                                isYearly={isYearlyPlan}
                                trial={false}
                                isSetting={true}
                                isYearlyPlan={isYearlyPlan}
                                setIsYearlyPlan={setIsYearlyPlan}
                                isBilling={isBilling}
                                onStartTrial={(planId) => console.log('je')}
                            />
                        </div>
                    </div>
                    <div className='w-full lg:w-1/2 flex items-center justify-center ' >
                        <div
                            style={{
                                boxShadow: ' rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                            }}
                            className='w-full md:w-full xl:w-11/12 py-16 rounded-xl'>
                            <div className='w-full px-6 py-4 pb-10'>
                                <H2 title="billing" />
                            </div>
                            <div className='flex flex-col gap-8'>
                                <div className={rowstyle}>
                                    <H4 title={"price"} />
                                    <H4 title={`${currency.symbol} ${bill.price}`} />
                                    {/* <div>{currency.symbol} {bill.price}</div> */}
                                </div>
                                {bill?.discount > 0 ?
                                    <div className={rowstyle}>
                                        <H4 title={"discount"} />
                                        <H4 title={`- ${currency.symbol} ${bill.discount}`} />
                                        {/* <div>- {currency.symbol} {bill.discount}</div> */}
                                    </div>
                                    : null}
                                {bill.couponDiscount > 0 ?
                                    <div className={rowstyle}>
                                        <H4 title={"coupon Discount"} />
                                        <H4 title={`- ${currency.symbol} ${bill.couponDiscount}`} />
                                    </div>
                                    : null}
                                {bill.credits > 0 ?
                                    <div className={rowstyle}>
                                        <H4 title={"Balance"} />
                                        <H4 title={` - ${currency.symbol} ${bill.credits}`} />
                                        {/* <div> - {currency.symbol}{credits ? credits : 0}</div></div> */}
                                    </div>
                                    : null}

                                {couponTrue ? ''
                                    :
                                    <div className='w-full hidden px-6'>
                                        <Button
                                            style={{
                                                padding: 0,
                                                color: '#00C1A3',
                                                fontWeight: '700',
                                                fontSize: '16px'
                                            }}
                                            onClick={() => {
                                                setCouponTrue(true);
                                                message.success('Coupen applied')
                                            }}
                                            type="link"
                                        >
                                            Apply for coupon
                                        </Button>
                                    </div>
                                }
                                {parseFloat(bill.price - bill?.discount - bill?.couponDiscount - bill?.credits) > 1 ?
                                    <div className={rowstyle}>
                                        <H4 title={"Total"} />
                                        <H4 title={`${currency.symbol} ${bill.price - bill?.discount - bill?.couponDiscount - bill?.credits}`} />
                                    </div>
                                    :
                                    <div className={rowstyle}>
                                        {/* //commented for now need clarification  */}
                                        {/* <H4 title={"Valid till"} /> */}
                                        {/* <H4 title={` ${(getDateAfterDays((bill.credits) / ((bill.price - bill?.discount - bill?.couponDiscount) / 30)))}`} /> */}
                                    </div>
                                }
                                {/* <Divider dashed /> */}
                                <div className='flex justify-between px-6 border-dashed border-t pt-6'>
                                    <H4 title={"Tax"} />
                                    <H4 title={`+ ${currency?.symbol} ${((bill.price - bill?.discount - bill?.couponDiscount - bill?.credits) / 100) * bill.tax} (${bill.tax}%)`} />
                                </div>
                                <Divider />
                                <div className={rowstyle}>
                                    <H2 title={"To Pay"} />
                                    {/* <H2 title={`${currency?.symbol} ${(((bill.price - bill?.discount - bill?.couponDiscount - bill?.credits) / 100) * bill.tax) + (bill.price - bill?.discount - bill?.couponDiscount - bill?.credits)}`}
                                        font='semibold'
                                    /> */}
                                    <H2 title={`${currency?.symbol} ${((
                                        ((bill.price - bill?.discount - bill?.couponDiscount - bill?.credits) / 100) * bill.tax) + (bill.price - bill?.discount - bill?.couponDiscount - bill?.credits)
                                    ).toFixed(2)}`}
                                        font='semibold'
                                    />
                                </div>
                                <div className='w-full flex items-center justify-end px-6'>
                                    <Payments
                                        currency={currency}
                                        userId={authState?.id}
                                        teamId={authState?.teamId}
                                        plan={plan}
                                        authState={authState}
                                        isYearly={isYearlyPlan}
                                        trial={trial}
                                        isSetting={isSetting}
                                        isBilling={isBilling}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
