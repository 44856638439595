import React from 'react'
import { useTheme } from '../Themes/ThemeProvider';
import { useRecoilState } from 'recoil';
import { userAuthState } from '../Services/RecoilService';
import { Breadcrumb } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useDimensions } from '../DimensionContext/DimensionContext';

const Header = ({ title }) => {
    const { themeColors } = useTheme();
    const location = useLocation();
    const [authState, setAuthState] = useRecoilState(userAuthState)
    const _route = location?.pathname?.split('/')
    const { lg, xl, xxl } = useDimensions()


    return (
        <>
            <div className=' flex  flex-col items-start justify-between'>
                {!location?.pathname?.includes('invoice') ?
                    <div className='w-full py-4'>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/home/dashboard">Home</Link>
                            </Breadcrumb.Item>
                            {_route?.map((_r, idx) => {
                                let _url = `/settings/${_r}`
                                if (_r === 'settings') {
                                    if (lg || xl || xxl) {
                                        _url = ''
                                    } else {
                                        _url = '/settings'
                                    }
                                }
                                return (
                                    <>
                                        {_r ?
                                            <Breadcrumb.Item key={idx}>
                                                <Link to={_url}>
                                                    {_route[_route?.length - 1] == _r ?
                                                        <h6 className={`capitalize font-semibold  ${themeColors?.headings?.primary}`}>{_r}</h6>
                                                        :
                                                        _r
                                                    }
                                                </Link>
                                            </Breadcrumb.Item>  
                                            : null}     
                                    </>     
                                )
                            })}
                            {/* <Breadcrumb.Item>
                            <Link to="/settings">Settings</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="">
                                <h6 className={`capitalize font-semibold ${themeColors?.headings?.primary}`}>{_route}</h6>
                            </Link>
                        </Breadcrumb.Item> */}
                            {/* Add more breadcrumb items as needed */}
                        </Breadcrumb>
                    </div>
                    : null}
                {_route === 'profile' ?
                    <h1 className={`text-lg lg:text-2xl font-medium ${themeColors.headings.primary}`}>{title}</h1>
                    : null}
            </div>
        </>
    )
}

export default Header
