import React, { useEffect } from 'react'
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "../Components/EditorToolbar";
import "react-quill/dist/quill.snow.css";
import SelectButton from '../Components/SelectButton';
import { DocumentIcon, DownloadIcon, OAiIcon, UploadDocIcon } from '../Components/Icons';
import { Button, Collapse, message } from 'antd';
import { useDimensions } from '../DimensionContext/DimensionContext';
import { CloseOutlined } from '@ant-design/icons';
import SignVIa from '../Components/SignVIa';
import AddSIgnForm from '../Components/AddSIgnForm';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateContent, updateDoc, updateTitle } from '../store/Reducers/contentReducer';
import { post } from '../Services/api-services';
import { useRecoilState } from 'recoil';
import { userAuthState } from '../Services/RecoilService';  
import { useTheme } from '../Themes/ThemeProvider'; 

// CREATE DOC PAYLOAD FORMAT
const createDocPayloadData = {
    document:
    {
        title: '',
        content: '',
        createdById: '',
        teamId: '',
    }
    ,
    attachments: []
}

const WriteDoc = ({ onClearData }) => {
    const { themeColors } = useTheme();
    const navigate = useNavigate();
    const locatiion = useLocation();
    const dispatch = useDispatch();
    const { lg, xl, xxl } = useDimensions();
    const inputRef = React.useRef(null);
    const [doc, setDocs] = React.useState([]);
    const { title, content, documents } = useSelector(state => state.contentOption);
    const [createDocData, setCreateDocData] = React.useState({ ...createDocPayloadData })
    const [authState, setAuthState] = useRecoilState(userAuthState)

    // UPDATE  CREATE DOCUMENT DATA 
    // useEffect(() => {
    //     updateCreateDocData()    
    // }, [title, content])

    // const updateCreateDocData = () => {
    //     setCreateDocData({
    //         ...createDocData,
    //         document: {
    //             title: title,
    //             content: content,
    //             createdById: authState?.id,
    //             teamId: authState?.teamId
    //         }
    //     });
    // };

    // UPDATE CONTENT 
    const updateContentFunction = value => {
        // setContent({ value });
        // setContent(value);
        dispatch(updateContent(value))
    };

    return (
        <>
            <div className=' w-full px-[2px] py-2  h-fit  flex flex-col items-center justify-center'>

                {/* MEDIUM RESOURCE  */}
                <div className='w-full flex items-center justify-center '>
                    <div className={` w-full relative ${themeColors.bg.main}`}>
                        {/* <CloseOutlined /> */}
                        <Button
                            style={{ position: 'absolute', right: 15, top: 15, color: '#707070', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            onClick={(e) => {
                                e?.stopPropagation();
                                onClearData();
                            }}
                            shape='circle'
                            icon={<CloseOutlined style={{ color: '#707070' }} />}
                            type="text"
                        >
                        </Button>   
                        {/* AI BUTTON  */}  
                        <Button
                            style={{
                                backgroundColor: 'rgba(5, 213, 51, 0.08)',
                                color: 'rgba(5, 213, 51, 1)',
                                fontWeight: '600',
                                position: 'absolute',
                                // top: 45,
                                top: 6,
                                right: 50,
                                zIndex: 999,
                                fontSize: '12px',
                                display: 'none'
                            }}
                            type="primary" shape="round" icon={<OAiIcon />} size='large'>
                            Write through AI
                        </Button>   

                        {/* <ReactQuill theme="snow" value={content} onChange={updateContentFunction} />; */}

                        <EditorToolbar />
                        <ReactQuill
                            theme="snow"
                            value={content}
                            onChange={updateContentFunction}
                            placeholder={"Write something awesome..."}  
                            modules={modules}
                            formats={formats}
                            style={{
                                width: '100%',
                                height: lg || xl ? 550 : xxl ? 700 : 500,
                                border: 'none',
                                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 0px 4px',
                                backgroundColor: '#FFFFFF'
                            }}  
                        />
                    </div>  
                </div>

            </div>
        </>
    )
}

export default WriteDoc
