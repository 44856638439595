import React, { useState } from 'react'
import { useDimensions } from '../DimensionContext/DimensionContext';
import { CloseOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Collapse, ConfigProvider, Skeleton, Tooltip, message } from 'antd';
import { DeleteIcon, DeleteTextIcon, DocumentIcon, DownloadIcon, EditTextIcon, OAiIcon, UploadDocIcon } from '../Components/Icons';
import SignVIa from '../Components/SignVIa';
import AddSIgnForm from '../Components/AddSIgnForm';
import DeleteDocDialogue from '../Components/DeleteDocDialogue';
import SendDrawer from '../Components/SendDrawer';
import { BASEURL, get, hasMeaningfulContent, patch, post } from '../Services/api-services';
import { useLocation, useNavigate, useHistory, json } from 'react-router-dom';
import { useEffect } from 'react';
import ReactQuill from 'react-quill';
import EditorToolbar, { modules, formats } from "../Components/EditorToolbar";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from 'react-redux';
import { removeAllSigners, removeSigners, updateContent, updateMethods, updateSigners, updateStatus, updateTitle } from '../store/Reducers/contentReducer';
import { userAuthState } from '../Services/RecoilService';
import { useRecoilState } from 'recoil';
import { useTheme } from '../Themes/ThemeProvider';
import PDFVIewer from './PDFVIewer';
import CreateHeader from '../Components/CreateHeader';
import ShowDocumentList from '../Components/ShowDocumentList';
import DownloadPdfComponent from '../Components/DownloadPdfComponent';
import "react-quill/dist/quill.snow.css";

// DOWNLOAD IMPORT 
// import React from 'react';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import PDFMerger from 'pdf-merger-js';
import { DocumentListskaleton, Historyskaleton, ShowDocumentSkaleton } from './DocumentSkaleton';
import SignerComponent from '../Components/SignerComponent';
import { Document, Page } from 'react-pdf';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import DownloadPreviewDocuments from '../Components/DownloadPreviewDocuments';
import ContentPreview from '../Components/ContentPreview';
import IframeComponent from '../Components/IframeComponent';



const DownloadPreview = () => {
  const { themeColors } = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  // const history = useHistory();    
  const dispatch = useDispatch();
  const { lg, xl, xxl } = useDimensions();
  const [textContent, setTextContent] = useState('');
  const [_title, setTitle] = useState('');
  const [documents, setDocuments] = useState([]);
  const [authState, setAuthState] = useRecoilState(userAuthState)
  const _documentid = location.pathname.split('/')[3]
  const [methods, setMethods] = React.useState(null)
  const [allsigners, setAllSigners] = React.useState([])
  const textDocRef = React.useRef(null);
  const content2Ref = React.useRef(null);
  const [dataLoading, setDataLoading] = React.useState(true)
  const [isEditable, setIsEditable] = React.useState(false)
  const [pdfLoaded, setPdfLoaded] = React.useState(false)
  const { title } = useSelector(state => state.contentOption);
  const iframeRefs = React.useRef([]);
  const [iframeHeights, setIframeHeights] = React.useState([]);
  const [allDocumentsLoaded, setAllDocumentsLoaded] = useState(false);

  const [pageNumber, setPageNumber] = React.useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  // GET CURRENT DOCUMENT DATA 
  useEffect(() => {
    getDocumentData();
  }, [])

  React.useEffect(() => {
    handlePrint()
  }, [dataLoading, pdfLoaded, allDocumentsLoaded])

  const getDocumentData = async (_currentEmail) => {
    try {
      // const response = await get(`document/${_documentid}/documentWithAttachment`)
      const response = await post(`auth/document/documentWithAttachmentBydocumentId `, { id: _documentid })
      setDataLoading(false)
      if (response.statusCode === 200 && !response?.data?.trash) {
        // setOwnerId(response?.data?.createdById)
        setTextContent(response?.data?.data?.content)
        setTitle(response?.data?.data?.title)
        // UPDATE SINGNERS DATA 
        if (response?.data?.data?.signers) {
          if (response?.data?.data?.signers?.length > 0) {
            // dispatch(removeAllSigners(response?.data?.signers))  
            setAllSigners(response?.data?.data?.signers)
          }
        } else {
          if (response?.data?.data?.signers?.length > 0) {
            dispatch(removeAllSigners([]))
          }
        }
        setDocuments(response?.data?.data?.attachments)
      } else {
      }
    } catch (e) {
      console.log(`ERROR WHILE FETCHING DOCUMENT ${e}`);
      // navigate('/home/dashboard', { replace: true })
    }
  }


  const handlePrint = () => {
    // if (!dataLoading && pdfLoaded && allDocumentsLoaded) {
    if (!dataLoading) {
      if (documents?.length > 0) {
        if (allDocumentsLoaded) {
          setTimeout(() => {
            window.print();
          }, 1000);
        }
      } else {
        setTimeout(() => {
          window.print(); 
        }, 1000); 
      }
    }
  };  

  let truncatedTitle;

  if (lg || xl || xxl) {
    truncatedTitle = _title.length > 50 ? _title.substring(0, 50) + '...' : _title;
  } else {
    truncatedTitle = _title.length > 20 ? _title.substring(0, 20) + '...' : _title;
  }

  const [numPages, setNumPages] = useState({});

  const handleDocumentLoadSuccess = (index, { numPages }) => {
    setNumPages(prevNumPages => ({
      ...prevNumPages,
      [index]: numPages,
    }));
    setPdfLoaded(true)
  };


  // React.useEffect(() => {
  //   iframeRefs.current = iframeRefs.current.slice(0, documents.length);

  //   const handleIframeLoad = (index) => {
  //     const iframe = iframeRefs.current[index];
  //     if (iframe) {
  //       const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
  //       setIframeHeights((prevHeights) => {
  //         const newHeights = [...prevHeights];
  //         newHeights[index] = iframeDocument.body.scrollHeight + 'px';
  //         return newHeights;
  //       });
  //     }
  //   };

  //   documents.forEach((_, index) => {
  //     const iframe = iframeRefs.current[index];
  //     if (iframe) {
  //       iframe.addEventListener('load', () => handleIframeLoad(index));
  //     }
  //   });

  //   return () => {
  //     documents.forEach((_, index) => {
  //       const iframe = iframeRefs.current[index];
  //       if (iframe) {
  //         iframe.removeEventListener('load', () => handleIframeLoad(index));
  //       }
  //     });
  //   };
  // }, [documents]);

  return (
    <>
      {dataLoading ?
        <ShowDocumentSkaleton />
        :
        <div className=' w-full px-3 pb-24 '>

          <div
            className='w-full py-4  max-w-[1000px] mx-auto'>
            <Tooltip title={title?.length > 50 ? title : ''}>
              <h1 className='text-xl lg:text-2xl font-semibold text-[#000] '>{truncatedTitle}</h1>
            </Tooltip>

            <ContentPreview
              textContent={textContent}
              noStyle={true}
            />

            <Button
              onClick={() => {
                window.print()
              }}
              style={{
                display: 'none'
              }}
            >
              print now
            </Button>

            {/* SHOW DOC LIST  */}
            <div className='w-full  hidden'>
              {documents &&
                documents.map((document, index) => (
                  <iframe
                    key={index}
                    // ref={(el) => (iframeRefs.current[index] = el)}
                    src={`${BASEURL}public/documents/${document?.fileName}`}
                    width="100%"
                    // height={iframeHeights[index] || '0px'}
                    title={`Document ${index}`}
                    style={{ border: '1px solid black' }}
                  // scrolling="no"
                  ></iframe>
                ))}
            </div>

            <div className='w-full hidden flex flex-col items-start  lg:items-center justify-start lg:justify-center'>
              {documents && documents.map((document, index) => (
                <div key={index} className="pdf-page">
                  <Document
                    file={`${BASEURL}public/documents/${document?.fileName}`}
                    onLoadSuccess={(pdf) => handleDocumentLoadSuccess(index, pdf)}
                  >
                    {Array.from(
                      new Array(numPages[index]),
                      (el, pageIndex) => (
                        <Page
                          key={`page_${index}_${pageIndex}`}
                          pageNumber={pageIndex + 1}
                        // width={'window.innerWidth * 0.9'} // Adjust width as needed
                        />
                      )
                    )}
                  </Document>
                </div>
              ))}
            </div>
            {/* <div>
              <h1>Embedding an Iframe in React</h1>
              <IframeComponent />
            </div> */}

            {/* {documents?.map((document, index) => {
              return (
                <iframe
                  key={index}
                  src={`${BASEURL}public/documents/${document?.fileName}`}
                  scrolling='no'
                  allowFullScreen='yes'
                />
              ) 
            })}  */}
            {/* <IframeComponent
              documents={documents}
            /> */}

            <DownloadPreviewDocuments
              documents={documents}
              allDocumentsLoaded={allDocumentsLoaded}
              setAllDocumentsLoaded={setAllDocumentsLoaded}
            />

            {/* {documents && 
              <PDFVIewer
                documents={documents}
              />
            } */}


            {allsigners ?
              <div
                ref={content2Ref}
                id='content-2'
                className={`w-full  mx-auto  rounded `}
              >
                {/* HEADER  */}
                {allsigners?.length > 0 ?
                  <div
                    className={` p-2  w-full  flex items-center gap-2 `}>
                    <h2 className={`text-base lg:text-xl font-semibold `}>SIGNERS</h2>
                  </div>
                  : null}

                {/* SINGNERS  */}
                <div className={`w-full`}>
                  {allsigners?.map((sign, index) => {
                    return (
                      <SignerComponent
                        key={index}
                        name={sign?.name}
                        title={sign?.title}
                        sign={sign}
                      // currentSigner={currentSigner}
                      />
                    )
                  })}
                </div>
              </div>
              : null}

          </div>
        </div >
      }
    </>
  )
}

export default DownloadPreview
