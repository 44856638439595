import React from 'react'
import { H1, P } from '../Themes/StylingComponent'
const NoDocument = require('../assets/Images/NoDocument.png')

const DeletedDocument = () => {
    return (
        <>
            <div className='w-full h-screen flex items-center justify-center bg-[#FFFFFF]'>
                <div className='flex flex-col items-center justify-center gap-4'>
                    <img className='h-32 my-4' src={NoDocument} alt="NoDocument" />
                    <H1
                        title='Document not found or deleted!'
                        font='bold'
                    />
                    <div className='w-full bg-[#8E8E8E] h-[1px]'></div>
                    <P
                        title={`We're sorry, but the document you're looking for has been deleted or cannot be found. Please check and try again.`}
                    />  
                </div>
            </div>
        </>
    )
}   

export default DeletedDocument
