import React, { useState, useEffect, useRef } from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { BASEURL } from '../Services/api-services';
// import './DownloadPreview.css'; // Make sure this points to your CSS file


const DownloadPreviewDocuments = ({ documents, allDocumentsLoaded, setAllDocumentsLoaded }) => {
    const [numPages, setNumPages] = useState({});
    const [loadedDocuments, setLoadedDocuments] = useState({});
    const containerRef = useRef(null);
    const [pageWidth, setPageWidth] = useState(0);

    useEffect(() => {
        const updatePageWidth = () => {
            if (containerRef.current) {
                setPageWidth(containerRef.current.offsetWidth * 0.9);
            }
        };
        updatePageWidth();
        window.addEventListener('resize', updatePageWidth);
        return () => window.removeEventListener('resize', updatePageWidth);
    }, []);

    const handleDocumentLoadSuccess = (index, { numPages }) => {
        setNumPages(prevNumPages => ({
            ...prevNumPages,
            [index]: numPages,
        }));
        setLoadedDocuments(prevLoadedDocuments => ({
            ...prevLoadedDocuments,
            [index]: true,
        }));
    };

    useEffect(() => {
        if (documents && Object.keys(loadedDocuments).length === documents.length) {
            setAllDocumentsLoaded(true);
        }
    }, [loadedDocuments, documents]);

    useEffect(() => {
        if (allDocumentsLoaded) {
            console.log('All documents have been loaded');  
            // alert('All documents have been loaded');
            // window.print(); 
            // Additional actions when all documents are loaded
        }
    }, [allDocumentsLoaded]);

    return (
        <div ref={containerRef} className="pdf-container">
            {documents && documents.map((document, index) => (
                <div key={index} className="pdf-document">
                    <Document
                        file={`${BASEURL}public/documents/${document?.fileName}`}
                        onLoadSuccess={(pdf) => handleDocumentLoadSuccess(index, pdf)}
                    >
                        {Array.from(
                            new Array(numPages[index]),
                            (el, pageIndex) => (
                                <Page
                                    key={`page_${index}_${pageIndex}`}  
                                    pageNumber={pageIndex + 1}
                                    width={pageWidth}
                                />  
                            )
                        )}
                    </Document>     
                </div>
            ))}
        </div>
    );
};

export default DownloadPreviewDocuments;    
