import * as React from 'react'
import { EmmailIcon, PasswordIcon } from '../Components/Icons';
import { Button, Input } from 'antd';
import { message } from 'antd';
import { Checkbox } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userAuthState } from '../Services/RecoilService';
import { emailRegex, passwordRegex } from '../ValidationRegex';
import { capitalizeAllWords, get, post } from '../Services/api-services';
import { useTheme } from '../Themes/ThemeProvider';
import { H1, P } from '../Themes/StylingComponent'
import { useEffect } from 'react';
import AnimationCards from '../Components/AnimationCards';

const inputStyle = { fontSize: '14px' }

const loginData = {
    email: '',
    password: '',
}

const Login = () => {
    const navigate = useNavigate()
    const { themeColors } = useTheme();
    const [authState, setAuthState] = useRecoilState(userAuthState)
    const [formData, setFormData] = React.useState({ ...loginData })
    const [isRememberMe, setIsRememberMe] = React.useState(false)
    const [isDisabled, setIsDisabled] = React.useState(true)
    const [isLoading, setIsLoading] = React.useState(false)


    // SET DISABLE 
    useEffect(() => {
        if (formData?.email?.length > 0 && formData?.password?.length > 0) {
            if (emailRegex?.test(formData?.email) && formData?.password?.length > 8) {
                setIsDisabled(false)
            } else {
                setIsDisabled(true)
            }
        } else {
            setIsDisabled(true)
        }
    }, [formData?.email, formData?.password])

    // HANDLE USER LOGIN 
    const handleLogin = async () => {
        setIsLoading(true)
        try {
            let _body = { ...formData };
            if (!_body?.email) {
                message.error('Enter Valid Email')
                setIsLoading(false)
                return
            }
            if (!_body?.password) {
                message.error('Enter Valid Password')
                setIsLoading(false)
                return
            }
            _body.email = _body.email?.toLowerCase(); // Convert email to lowercase
            if (isRememberMe) {
                _body.rememberMe = isRememberMe
            }
            const response = await post('auth/login', _body)
            setIsLoading(false)
            if (response.statusCode == 200) {
                setAuthState(response?.data)
                setTimeout(async () => {
                    await checkTeamHaveCountryAndPlan(response?.data?.teamId)
                    if (response?.data?.teamCount > 1 || response?.data?.teamCount == 0) {
                        // navigate('/home/dashboard', { replace: true })       
                        navigate('/select-team')
                    } else {
                        navigate(`${response?.data?.navigateTo}`, { replace: true });
                    }
                }, 100);
                message.success('Login Successfull!');
            } else {
                message.error(response?.message);
            }
        } catch (e) {
            console.log(`ERROR WHILE LOGIN${e}`);
            message.error(e);
        }
    }

    // function for check team have plan and country have or not
    const checkTeamHaveCountryAndPlan = async (teamId) => {
        try {
            const res = await get(`team/${teamId}`);
            if (res.statusCode === 200) {
                if (!res?.data?.country) {
                    navigate('/mobile-signup')
                } else if (!res?.data?.plan) {
                    navigate('/select-plan')
                } else {
                    navigate('/home/dashboard', { replace: true })
                }
            }
        } catch (e) {
            throw (e);
        }
    }

    // CHECK LOGIN 

    useEffect(() => {
        document.title = capitalizeAllWords('Login');
        if (authState?.id && authState?.teamId) {
            navigate('/home/dashboard', { replace: true })
        } else if (authState?.id) {
            navigate('/select-team', { replace: true })
        }
    }, [])

    return (
        <div className='w-full h-screen flex'>
            <div className='w-full lg:w-9/12 flex flex-col items-center justify-center'>
                <div className='w-11/12  lg:w-2/6 flex flex-col  items-start gap-6'>
                    <div className='w-full flex flex-col items-center gap-5 py-2'>
                        {/* <H1 title='Welcome Back!' /> */}
                        <h1 className='text-[#424D51] text-3xl lg:text-5xl whitespace-nowrap text-center'>Welcome Back!</h1>
                        <P
                            align='center'
                            title='Get started with your Signizy account and begin sending documents for signatures! Easily sign your important documents with Signizy.'
                        />
                    </div>
                    <div className='w-full flex flex-col items-start gap-5 py-4 lg:gap-6 lg:py-6'>
                        <Input
                            size='large'
                            style={inputStyle}
                            placeholder="Email"
                            onPressEnter={handleLogin}
                            prefix={<EmmailIcon color={themeColors.icons.secondary} />}
                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                            status={formData?.email?.length > 0 && !emailRegex?.test(formData.email) ? 'error' : ''}
                        />
                        {/* {formData?.email?.length > 0 && !emailRegex?.test(formData.email) && (
                            <Typography.Text type="danger">Please enter a valid email address</Typography.Text>
                        )} */}
                        <div className='w-full'>
                            <Input.Password
                                size='large'
                                style={inputStyle}
                                placeholder="Password"
                                onPressEnter={handleLogin}
                                prefix={<PasswordIcon color={themeColors.icons.secondary} />}
                                onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                                status={formData?.password?.length > -0 && formData?.password?.length < 8 ? 'error' : ''}
                            />
                            {/* <p className={`text-xs mt-3 text-red-400 ${formData?.password?.length > 0 && !passwordRegex?.test(formData.password) ? 'opacity-100' : 'opacity-0'}`}>Use a combination of uppercase and lowercase letters, numbers, and symbols and length must be 8</p> */}
                        </div>

                        <div className='flex items-center justify-between w-full'>
                            <Checkbox
                                onChange={(e) => {
                                    setIsRememberMe(e?.target?.checked)
                                }}
                            >
                                <p className='text-xs text-[#8E8E8E]'>Remember me</p>
                            </Checkbox>
                            <Button
                                onClick={() => {
                                    navigate('/forgot-password')
                                }}
                                type='link'
                                size='small'
                                style={{ color: '#8E8E8E' }}
                            >
                                Forgot Password?
                            </Button>
                        </div>
                    </div>
                    <div className='w-full py-2 flex flex-col gap-3'>
                        <Button
                            onClick={handleLogin}
                            disabled={isDisabled}
                            size='large' style={{ width: '100%' }}
                            type="primary"
                            loading={isLoading}
                        >
                            Log In
                        </Button>
                        <P
                            align='center'
                            title={<span>No account yet?<span onClick={() => navigate('/signup')} className='text-[#4a4848] cursor-pointer font-semibold'> Sign up</span></span>}
                        />
                        {/* <p className='text-[#6E6E6E] text-center text-sm lg:text-sm py-2'>No account yet?<span onClick={() => navigate('/signup')} className='text-[#4a4848] cursor-pointer font-semibold'> Sign up</span></p> */}
                    </div>
                </div>
            </div>
            <AnimationCards />
        </div>
    )
}

export default Login
