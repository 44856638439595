import { AutoComplete, Input } from 'antd';
import React, { useState } from 'react'
import { DownArrowIcon, SearchIcon, UserIcon } from './Icons';
import { get } from '../Services/api-services';
import { mobileRegex } from '../ValidationRegex';
import { useDimensions } from '../DimensionContext/DimensionContext';

const inputStyle = { fontSize: '14px', display: 'none' }

const CountrySelectComponent = ({ mobile, methods, profile, isSelected, setIsSelected, updateData, selectedCountry, setSelectedCountry }) => {
  // const [isSelected, setIsSelected] = React.useState(false)
  const [CountryData, setCountryData] = React.useState([])
  const [searchInputValue, setSearchInputValue] = useState('');
  const { xs, sm, lg, xl, xxl } = useDimensions();

  React.useEffect(() => {
    getCountriesData();
  }, [])

  // GET ALL COUNTRIES DATA   
  const getCountriesData = async () => {
    try {
      const response = await get('auth/country')
      if (response.statusCode == 200) {
        setCountryData(response.data)
      }
    } catch (e) {
      console.log(`ERROR WHILE GETTING COUNTRIES DATA ${e}`);
    }
  }

  // SELECT COUNTRY 
  const selectBefore = (
    <div
      onClick={() => {
        if (methods?.mobile || profile) {
          setIsSelected(!isSelected)
        }
      }}
      className={`flex items-center gap-2 border-r border-r-[#8E8E8E] w-12 ${methods?.mobile || profile ? 'cursor-pointer' : 'cursor-help'}`}
    >
      <img className='w-6 h-6 object-contain rounded' src={`https://flagcdn.com/w20/${selectedCountry?.code.toLowerCase()}.png`} alt="flag" />
      <DownArrowIcon />
    </div>
  );

  // set country data 
  const handleSelect = (value, option) => {
    if (option) {
      setSelectedCountry(option?.country);
      setIsSelected(false)
    }
  };     


  return (
    <>  
      <div className='w-full  '>
        <div className='w-full '>
          <AutoComplete
            popupClassName="certain-category-search-dropdown"
            popupMatchSelectWidth={lg || xl || xxl ? 480 : 350}
          
            style={{ width: '100%' }}
            options={CountryData?.map(country => ({
              value: `${country.label}  `,  
              code: country.code,
              country: country,
              label: (
                <div className='flex items-center gap-2'>
                  <div className='flex object-contain gap-2 my-1'>
                    <img className='w-6 h-6 object-contain rounded' src={`https://flagcdn.com/w20/${country.code?.toLowerCase()}.png`} alt={country.name} />
                  </div>
                  {country?.label}
                </div>
              )
            }))}
            onSelect={handleSelect}
            filterOption={(inputValue, option) => {
              return option.value.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1;
            }}
          >
            <Input.Search
              size="large"
              placeholder="search here"
              // enterButton={<SearchIcon />} // Add the search icon
              onSearch={value => console.log(value)} // Handle search functionality if needed
              suffix={null} // Remove default suffix (clear icon) 
              prefix={selectBefore} // Remove default suffix (clear icon)
              style={{ paddingRight: 40 }} // Adjust padding to accommodate the search icon
            />
            {/* <Input.Search onSearch={value => console.log(value)} placeholder="input here" /> */}
          </AutoComplete>
        </div>
      </div >
    </>
  )
}

export default CountrySelectComponent
