import React from 'react'
import { FailedIcon, RightIcon, SuccessIcon } from '../Components/Icons'
import { H1, H2, H3, H4, H5, H6, P } from '../Themes/StylingComponent'
import { Button } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import useRazorpay from 'react-razorpay'
import { formatDateFunction, get } from '../Services/api-services'
import { CheckOutlined } from '@ant-design/icons'

const PaymentStatus = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { isBilling, isSetting, success, orderData, orderId } = location.state;
    const [planData, setPlanData] = React.useState('')
    const [Razorpay] = useRazorpay();


    React.useEffect(() => {
        if (success) {
            getPlanData();
        }
    }, [success])

    const getPlanData = async () => {
        try {
            const response = await get(`transaction/orderId/${orderId}`)
            if (response.statusCode == 200) {
                setPlanData(response?.data)
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING PLAN DETAILS ${e}`);
        }
    }

    const handlePayment = async (params) => {
        let key_id = 'rzp_test_W8YjxBmW11fxSd';

        const options = {
            key: key_id, // Enter the Key ID generated from the Dashboard
            amount: params?.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: params?.currency,
            name: "ashish jaiswal",
            description: "Test Transaction",
            image: "https://example.com/your_logo",
            order_id: params.id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
            handler: function (response) {
                let body = { isSetting, success: true, isBilling, orderId: params.id }
                navigate('/screen', { state: body })
            },
            prefill: {
                name: "Piyush Garg",
                email: "youremail@example.com",
                contact: "9999999999",
            },
            notes: {
                address: "Razorpay Corporate Office",
            },
            theme: {
                color: "#3399cc",
            },
        };

        const rzp1 = new Razorpay(options);

        rzp1.on("payment.failed", function (response) {
            let body = { isSetting, success: false, isBilling, orderData: params }
            navigate('/screen', { state: body })
            // alert(response.error.code);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
        });
        // rzp1.on("payment.authorized", function (response) {
        // })

        rzp1.open();
    };
    
    return (
        <>
            <div className='w-full flex-1 px-4 lg:px-0 bg-[#FFFFFF] flex items-center justify-center'>
                <div className='w-full lg:w-1/2 flex flex-col items-center justify-center gap-6'>
                    {/* <FailedIcon /> */}
                    <div className='w-full border-b border-b-[#E8E8E8] py-6 flex flex-col items-center justify-center'>
                        {success ?
                            <SuccessIcon />
                            :
                            <FailedIcon />
                        }
                        <H2 title={success ? 'Successfully Purchased!' : 'Payment Failed!'} />
                    </div>
                    {/* <P
                        align='center'
                        title={`We're sorry, but your payment was unsuccessful. This may be due to reasons such as insufficient funds, an expired card, or a temporary network issue. Please check your payment details and try again.`}
                    /> */}
                    <P
                        title={
                            success ? `Your payment was successful! Thank you for your purchase. If you have any questions or need further assistance, please don't hesitate to contact us at signizy@eulogik.com Enjoy using our service!`
                                : `We're sorry, but your payment was unsuccessful. This may be due to reasons such as insufficient funds, an expired card, or a temporary network issue. Please check your payment details and try again.`
                        }   
                        align='center'
                    />
                    <div className='flex flex-col items-center justify-center gap-2'>
                        <h6 className='text-[#1677FF] text-base lg:text-xl font-medium'>Your {planData?.toPack} plan is now active.</h6>
                        <H6
                            title={`Active Till : ${formatDateFunction(planData?.endValidity)}`}
                            font='semibold'
                        />
                    </div>
                    <div className='flex items-center gap-4 py-4'>
                        <Button
                            icon={<CheckOutlined />}
                            type='primary'
                            style={{
                                backgroundColor: success ? '' : '#F1F6FF',
                                color: success ? '' : '#1677FF'
                            }}
                            onClick={() => {
                                if (!isBilling && isSetting) {
                                    navigate('/settings/manage-team')
                                } else if (!isSetting && isBilling) {
                                    navigate('/settings/billing')
                                } else {
                                    navigate('/home/dashboard')
                                }
                            }}
                        >
                            Done
                        </Button>
                        {!success ?
                            <Button
                                type='primary'
                                onClick={() => handlePayment(orderData)}
                            >
                                Retry
                            </Button>
                            : null}
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentStatus
