import React, { useEffect, useRef, useState } from 'react';
import SignaturePad from 'signature_pad';
import { Button, Modal, Checkbox, message } from 'antd';
import { useDimensions } from '../DimensionContext/DimensionContext';
import { patch, post } from '../Services/api-services';
import { useDispatch } from 'react-redux';
import { useTheme } from '../Themes/ThemeProvider';
import { ReloadIcon } from './Icons';

const EditSignComponent = ({
    open,
    setOpen,
    getDetails,
    currentUser,
    onclose,
    previousSignature // New prop to hold previous signature SVG
}) => {
    const { themeColors } = useTheme();
    const dispatch = useDispatch();
    const { lg, sm, xl, xxl, md } = useDimensions();
    const [isDisable, setIsDisable] = useState(false);
    const canvasRef = useRef(null);
    const containerRef = useRef(null);
    const signaturePadRef = useRef(null);

    useEffect(() => {
        if (canvasRef.current && containerRef.current) {
            if (!signaturePadRef.current) {
                signaturePadRef.current = new SignaturePad(canvasRef.current, {
                    // Additional configuration options can go here
                });
            }
            resizeCanvas();
            window.addEventListener('resize', resizeCanvas);

            // Prefill the signature pad with the previous signature (if available)
            const signaturePad = signaturePadRef.current;
            if (previousSignature && signaturePad) {
                // Convert the SVG string into an image and draw it on the canvas
                const img = new Image();
                const svgDataUrl = 'data:image/svg+xml;base64,' + btoa(previousSignature);
                img.src = svgDataUrl;

                img.onload = () => {
                    const ctx = signaturePad.canvas.getContext('2d');
                    ctx.clearRect(0, 0, signaturePad.canvas.width, signaturePad.canvas.height); // Clear canvas before drawing
                    ctx.drawImage(img, 0, 0);
                };
            }

            return () => {
                window.removeEventListener('resize', resizeCanvas);
            };
        }
    }, [open, previousSignature]); // Re-run whenever modal opens or previous signature changes


    const resizeCanvas = () => {
        const canvas = canvasRef.current;
        const container = containerRef.current;
        if (canvas && container) {
            canvas.width = container.offsetWidth;
            canvas.height = 200; // Adjust as needed
            const signaturePad = signaturePadRef.current;
            if (signaturePad) {
                signaturePad.clear(); // Clear the previous content to fit the new size
            }
        }
    };


    // UPDATE USER  SIGNATURE 
    const handleSave = async () => {
        const signaturePad = signaturePadRef.current;
        if (signaturePad) {
            const svgData = signaturePad.toSVG();

            try {

                let _body = {
                    svg: svgData,
                };

                const response = await patch(`user/${currentUser?._id}`, _body)
                if (response?.statusCode == 200) {
                    getDetails();
                    onclose();
                }
            } catch (e) {
                console.log(`ERROR WHILE UPDATING USER ${e}`);
            }
        } else {
            console.error('Signature pad is not initialized');
        }
    };

    const handleClear = () => {
        const signaturePad = signaturePadRef.current;
        if (signaturePad) {
            signaturePad.clear();
        }
    };

    const modalContentStyle = {
        padding: '0',
    };

    const onChange = (e) => {
        setIsDisable(e.target.checked);
    };

    return (
        <Modal
            title={<h1></h1>}
            centered
            open={open}
            onOk={() => setOpen(false)}
            closable={false}
            style={modalContentStyle}
            footer={
                <div className='w-full flex items-center justify-between gap-4 pt-6'>
                    <Button
                        onClick={async () => {
                            onclose();
                        }}
                        size='middle'
                        style={{ backgroundColor: themeColors.bg.backButton, color: themeColors.bg.inviteButton }}
                        type='primary'
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            handleSave();
                        }}
                        disabled={!isDisable}
                        size='middle'
                        type='primary'
                    >
                        Sign
                    </Button>
                </div>
            }
            width={700}
        >
            <div ref={containerRef}>
                <div className={`rounded relative border border-dashed ${themeColors.borders.signP}`}>
                    <div onClick={handleClear} className='absolute cursor-pointer right-3 top-3'>
                        <ReloadIcon color={themeColors.icons.fourth} />
                    </div>
                    <canvas style={{ touchAction: 'none', userSelect: 'none' }} ref={canvasRef}></canvas>
                </div>
                <div className='flex items-start gap-3 py-4'>
                    <Checkbox onChange={onChange} />
                    <p className={`text-xs ${themeColors.text.fourth}`}>
                        By clicking the button below, I acknowledge and agree that this represents my signature in a legally binding manner.
                    </p>
                </div>
            </div>
        </Modal>
    );
};

export default EditSignComponent;

