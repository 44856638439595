import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Layout, Input, } from 'antd';
import { useDimensions } from "../DimensionContext/DimensionContext";
import { DownloadDocIcon, LeftArrowIcon } from './Icons';
import * as React from 'react'
import { useSelector } from 'react-redux';
import { useTheme } from '../Themes/ThemeProvider';
import HeaderComponent from './HeaderComponent';

const { Header } = Layout;

const SignLayout = () => {
    const { themeColors } = useTheme();
    const { lg, xl, xxl } = useDimensions();
    const { title, content, signers, myMethods, status } = useSelector(state => state.contentOption);


    return (
        <div className={`w-full h-screen flex flex-col ${themeColors.bg.secondary} `}>
            <HeaderComponent
                title={title}
            />
            <div className={`w-full flex-1 flex pt-12  flex-col items-center`}>
                <Outlet />
            </div>
        </div>
    )
}

export default SignLayout 
