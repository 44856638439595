const onlySingleString = new RegExp(/^[a-zA-Z]+[a-zA-Z]+$/);
const emailRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
const phoneNumberRegex = new RegExp(/^[0-9]{10}$/);
const mobileRegex = new RegExp(/^[0-9]*$/);
const addressRegex = new RegExp(/ \d{1,5}\s\w.\s(\b\w*\b\s){1,2}\w*\./);
const StringWithSpace = new RegExp(/^[a-zA-Z]+.[a-zA-Z ]+$/);
const PercentRegex = new RegExp(/(^100([.]0{1,2})?)$|(^\d{1,2}([.]\d{1,2})?)$/);
const zipCodeRegex = new RegExp(/^[0-9]{6}$/);
const sessionRegex = new RegExp(/^(20[0-9]\d|20[0-9]\d|2050)$/);
const passwordRegex = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/);
const dateOfBirthRegex = /\d{4}-\d{2}-\d{2}/;

module.exports = {
    emailRegex, mobileRegex
}