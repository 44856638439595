import React, { useEffect, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Flex, Input, message, Tag, theme, Tooltip } from 'antd';
// import { isEmail } from 'validator'; // Import email validation function
import { emailLength, emailRegex } from '../ValidationRegex';

const tagInputStyle = {
    width: 'fit-content',
    height: 22,
    marginInlineEnd: 8,
    verticalAlign: 'top',
};

const TagComponent = ({ tags, setTags }) => {
    const { token } = theme.useToken();
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);

    const handleClose = (removedTag) => {
        const newTags = tags.filter((tag) => tag !== removedTag);
        setTags(newTags);
    };

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        if (inputValue?.length > 0) {
            if (emailRegex?.test(inputValue)) { // Validate if the input value is an email
                if (tags.includes(inputValue)) { // Check for duplicates
                    message.error('Email Already Exists!');
                } else {
                    setTags([...tags, inputValue]);
                    setInputVisible(false);
                    setInputValue('');
                }
            } else {
                message.error('Please Enter Valid Email!');
            }
        } else {
            setInputVisible(false)
        }
    };


    const tagPlusStyle = {
        height: 22,
        background: token.colorBgContainer,
        borderStyle: 'dashed',
        // width: '100%'
    };

    return (
        <Flex gap="4px 0" wrap="wrap">
            {tags.map((tag, index) => (
                <Tag
                    key={tag}
                    closable={true}
                    onClose={() => handleClose(tag)}
                    style={{
                        width: 'fit-content',
                    }}
                >
                    {tag}
                </Tag>
            ))}
            {inputVisible ? (
                <Input
                    ref={inputRef}
                    type="text"
                    size="small"
                    style={tagInputStyle}
                    value={inputValue}
                    maxLength={emailLength}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                />
            ) : (
                <Tag style={tagPlusStyle} icon={<PlusOutlined />} onClick={showInput}>
                    Add Email
                </Tag>
            )}
        </Flex>
    );
};

export default TagComponent;
