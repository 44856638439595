import { SwapOutlined, UserOutlined } from '@ant-design/icons';
import { SettingOutlined, LoadingOutlined } from '@ant-design/icons'
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Layout, message, Spin, Tooltip } from 'antd';
import { useDimensions } from "../DimensionContext/DimensionContext";
import * as React from 'react'
import { Button } from 'antd';
import { capitalizeAllWords, get } from '../Services/api-services';
import { useRecoilState } from 'recoil';
import { userAuthState } from '../Services/RecoilService';
import { useTheme } from '../Themes/ThemeProvider';
import { H1, H4, H5, P } from '../Themes/StylingComponent';
import VerifyEmail from './VerifyEmail';
import Dashboard from '../Screens/Dashboard';
import ConfirmCancelDialogue from './ConfirmCancelDialogue';
import { updateContent, updateIsWriting, updateTitle } from '../store/Reducers/contentReducer';
import { useDispatch, useSelector } from 'react-redux';
import { UserProvider } from '../UserContext';
import Loader from '../Screens/Loader';

const url = 'https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg';
const { Header } = Layout;

const signizyLogo = require('../assets/Images/Signizy-logo-200-dark.png')
const signizyLogoMobile = require('../assets/Images/MobileBlackLogo.png')

const HomeLayout = () => {
    const { themeColors } = useTheme();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const location = useLocation();
    const { lg, xl, xxl, sm, xs } = useDimensions();
    const _countt = (lg || xl || xxl) ? 20 : 10
    const [size, setSize] = React.useState('large');
    const [authState, setAuthState] = useRecoilState(userAuthState)
    const [currentUser, setCurrentUser] = React.useState('')
    const [currentPlan, setCurrentPlan] = React.useState('')
    const [DocCount, setDocCount] = React.useState(0)
    const [isAllowed, setIsAllowed] = React.useState(true)
    const [messateContent, setMessateContent] = React.useState('')
    const [open, setOpen] = React.useState(false)
    const [teamName, setTeamName] = React.useState('')
    const _documentid = location.pathname.split('/')[3]
    const _route = location.pathname.split('/')[2]
    const { title, isWriting } = useSelector(state => state.contentOption);

    const handleClick = () => {
        if (location?.pathname?.includes('options')) {
            setOpen(true)
        } else {
            if (lg || xl || xxl) {
                navigate('/settings/profile');
            } else {
                navigate('/settings');
            }
        }
    }

    // GET Documents    
    React.useEffect(() => {
        getAllDocuments(1, 100);
    }, [authState, location?.pathname])

    // GET CURRENT USER DETAILS 
    React.useEffect(() => {
        getCurrentUserDetails();
    }, [])

    const getCurrentUserDetails = async () => {
        try {
            // const response = await get(`user/${authState?.id}`)
            const response = await get(`team/${authState?.teamId}/user/${authState?.id}`)
            if (response?.statusCode == 200) {
                setCurrentUser(response?.data)
                setAuthState({
                    ...authState,
                    emailVerify: response?.data?.emailVerify,
                    role: response?.data?.role
                })
                getAllDocuments(1, 20);
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING USER DETAILS ${e}`);
        }
    }

    // GET ALL DOCUMENT AND DOCUMENT COUNT 
    const getAllDocuments = async (skip, limit) => {
        try {
            let queryParams
            queryParams = `${skip ? `?skip=${skip}` : ''}${limit ? `&limit=${limit}` : ''} `
            const response = await get(`team/${authState?.teamId}/document${queryParams}`);
            if (response?.statusCode == 200) {
                setDocCount(response?.data?.docCount)
                setCurrentPlan(response?.data?.team?.plan)
                setTeamName(response?.data?.team)
            }
        } catch (e) {
            console.log(`ERROR WHILE GETING ALL DOCUMENTS ${e}`);
        }
    }

    // CHECK FOR TEAM HAVE COUNTRY AND PLAN OR NOT
    const checkCountryAndPlanInTeam = async (id) => {
        try {
            const res = await get(`team/${id}`);
            if (res.statusCode === 200) {
                if (!res?.data?.country) {
                    navigate('/mobile-signup')
                } else if (!res?.data?.plan) {
                    navigate('/select-plan')
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    // CHECK AUTHENTICATION 
    //NO NEED TO CHECK ON HOMELAYOUT BECAUSE WE ARE CHECKING IT IN  LOGIN SCREEN 
    React.useEffect(() => {
        if (authState?.id && authState?.teamId) {
            checkCountryAndPlanInTeam(authState?.teamId)
        } else {
            navigate('/')
        }
    }, [])

    // CANCEL DOCUMENTS 
    const oncancel = () => {
        dispatch(updateTitle(''))
        dispatch(updateContent(''))
        dispatch(updateIsWriting(false))
        // dispatch(updateDocument([]))
        //    dispatch
        // navigate('/home/dashboard', { replace: true     })
        // navigate(-2)
        setOpen(false)
        navigate('/home/dashboard', { replace: true })
    }

    // CHECK PLAN VALIDATIONS 
    React.useEffect(() => {
        if (currentPlan) {
            // Convert the provided timestamp to a Date object
            const endValidityDate = new Date(currentPlan?.endValidity);

            // Get the current date and time
            const currentDate = new Date();
            if (endValidityDate < currentDate) {
                // setIsMobile(false)
                setIsAllowed(false)
                setMessateContent('Your current plan has expired. Please upgrade your plan!')
            } else {
                if (DocCount <= 0 || (endValidityDate < currentDate)) {
                    setIsAllowed(false)
                    setMessateContent('Maximum limit exceed Please upgrade the plan to add more')
                } else {
                    setIsAllowed(true)
                }
            }
        } else {
            setIsAllowed(false)
            setMessateContent(`Team doesn't have plan. Please upgrade the plan!`)
        }
    }, [currentPlan])

    React.useEffect(() => {
        const idRegex = /^[0-9a-fA-F]{24}$/;
        const currentPath = location.pathname?.split('/');
        let lastElement = currentPath[currentPath?.length - 1];
        if (idRegex.test(lastElement) || lastElement == 'options') {
            lastElement = 'Document'
        }
        document.title = capitalizeAllWords(lastElement);
    }, [location.pathname])

    return (
        <div className={location?.pathname?.includes('home') ? `w-full h-screen flex flex-col ${themeColors?.bg?.layout}` : `w-full h-screen flex flex-col items-center justify-center  ${isWriting || location?.pathname?.includes('document') || location?.pathname?.includes(_documentid) ? themeColors.bg.secondary : themeColors.bg.primary}`}>
            {!currentUser ?
                <Loader />
                :
                <>
                    <VerifyEmail
                        // emailVerify={currentUser?.emailVerify}   
                        authState={authState}
                        setAuthState={() => setAuthState({
                            ...authState,
                            emailVerify: true
                        })}
                        currentUser={currentUser}
                        getCurrentUserDetails={() => getCurrentUserDetails()}
                    />
                    <Header
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '40px 12px',
                            backgroundColor: themeColors?.bg?.primaryColor,
                            borderBottom: !location?.pathname?.includes('home') ? `1px solid rgba(0, 0, 0, 0.25)` : '',
                            width: '100%',
                        }}
                    >
                        <div onClick={() => {
                            {
                                if (!location?.state?.isTrial && !location?.state?.data && !location.pathname.includes('options')) {
                                    navigate('/home/dashboard')
                                } else {
                                    if (!location?.pathname?.includes('select-plan')) {
                                        setOpen(true)
                                    }
                                }
                            }
                        }} className='flex cursor-pointer h-10 items-center gap-2'>
                            {/* <EmmailIcon /> */}
                            {/* <H4
                        title={`Hey, ${currentUser && currentUser?.name ? currentUser?.name : ''}`}
                        font='medium'   
                    /> */}  
                            {xl || xxl || lg ?
                                <img className='h-full ' src={signizyLogo} alt="signizyLogo" />
                                :
                                <img className='h-full ' src={signizyLogoMobile} alt="signizyLogoMobile" /> 
                            }
                        </div>  
                        <div className='flex flex-row-reverse items-center gap-3 lg:gap-5'>

                            {location?.pathname?.includes('home') ?
                                <Button
                                    onClick={() => {
                                        if (!isAllowed) {
                                            message.error(messateContent)
                                        } else {
                                            navigate('/create-doc/options')
                                        }
                                    }}
                                    size='middle'
                                    type="primary"
                                >
                                    Create New
                                </Button>
                                : null}
                            {!location?.pathname?.includes('invoice') ?
                                <Button
                                    onClick={handleClick}
                                    style={{ padding: 0, margin: 0 }}
                                    type="link" shape="round"
                                    icon={<SettingOutlined
                                        style={{ color: '#8E8E8E' }}
                                    />}
                                    size={size}
                                />
                                : null}

                            {location?.pathname?.includes('home') ?
                                <div className='flex items-center gap-2'>
                                    <Tooltip title={teamName?.name ? teamName?.name?.length > _countt ? teamName?.name : '' : teamName?.ownerName ? teamName?.ownerName?.length > _countt ? teamName?.ownerName : '' : null}>
                                        <Button
                                            type='text'
                                            onClick={() => {
                                                navigate('/settings/manage-team')
                                            }}
                                            icon={
                                                <SwapOutlined
                                                    style={{
                                                        rotate: '90deg',
                                                        color: '#8E8E8E'
                                                    }}
                                                />
                                            }
                                        >
                                            {teamName?.name ? teamName?.name?.length > _countt ? teamName?.name.substring(0, _countt) + '....' : teamName?.name : teamName?.ownerName ? teamName?.ownerName?.length > _countt ? teamName?.ownerName.substring(0, _countt) + '...' : teamName?.ownerName : null}
                                        </Button>
                                    </Tooltip>
                                </div>
                                : null}
                        </div>
                    </Header>

                    {/* <UserProvider> */}
                    <div className='w-full  flex-1 flex flex-col overflow-y-auto'>
                        <Outlet />
                        {/* <Dashboard
                    allDocuments={allDocuments}
                    setAllDocuments={setAllDocuments}   
                    count={count}
                    setCount={setCount}
                    status={status}
                    DocCount={DocCount}
                    isAllowed={isAllowed}
                    setDocCount={setDocCount}
                    setStatus={setStatus}
                    currentPlan={currentPlan}
                    getAllDocuments={(status, skip, limit) => getAllDocuments(status, skip, limit)}
                /> */}
                    </div>

                    {/* </UserProvider> */}
                    <ConfirmCancelDialogue
                        open={open}
                        setOpen={setOpen}
                        onDiscard={() => oncancel()}
                    />
                </>
            }
        </div>
    )
}

export default HomeLayout
