import React, { useState } from 'react'
import { H1, H2, H3, H4, H6, P, Price, Span } from '../Themes/StylingComponent'
import { BasicPlanIcon, CancelIcon, RightIcon } from './Icons'
import { Button, Radio } from 'antd'
import { useTheme } from '../Themes/ThemeProvider'
import Payments from '../Screens/Payments'
import { useRecoilState } from 'recoil'
import { userAuthState } from '../Services/RecoilService'
import { useLocation, useNavigate } from 'react-router-dom';


const BillCard = ({ plan, isYearly, onStartTrial, setIsYearlyPlan, isYearlyPlan, isSetting, isBilling, setIsYearly, setCurrency, trial, currency, defaultCurrency, credits }) => {
    const { themeColors } = useTheme();
    const location = useLocation();
    const [authState, setAuthState] = useRecoilState(userAuthState);
    const [price, setPrice] = useState('')
    const navigate = useNavigate();
    credits = credits ? credits : 0
    React.useEffect(() => {
        if (plan?.price[currency?.code]) {
            setPrice(plan?.price[currency?.code])
        } else {
            setPrice(plan?.price[defaultCurrency?.code])
            setCurrency(defaultCurrency)
        }
    }, [plan])


    return (
        <>
            <div className='w-full flex items-center '>
                <div className='border border-[#EDEDED] p-4 rounded-t-2xl border-b-[#F8F8F8]' >
                    <Radio
                        size='large'
                        // checked={isYearlyPlan}
                        checked={true}
                        onChange={() => {
                            setIsYearlyPlan(!isYearly)
                        }}
                    >
                        {isYearly ? 'Yearly' : 'Monthly'}
                    </Radio>
                </div>
                <div className='p-4'>
                    <Radio
                        size='large'
                        // checked={!isYearlyPlan}  
                        checked={false}
                        onChange={() => {
                            setIsYearlyPlan(!isYearly)
                        }}
                    >
                        {!isYearly ? 'Yearly' : 'Monthly'}
                    </Radio>
                </div>
            </div>
            <div className={`w-full relative flex flex-col gap-4 overflow-hidden p-8 ${themeColors?.bg?.primary} border border-[#EDEDED] rounded-t-none rounded-tr-2xl rounded-2xl  min-w-min`}>
                <div className='flex w-full items-center justify-between gap-2'>
                    <div className='flex items-center  gap-2 '>
                        <BasicPlanIcon color={themeColors?.icons?.primary} />
                        <H2 title={plan?.title} />
                    </div>
                    {plan?.popular ?
                        <div className={`w-48 flex items-center justify-center absolute h-8 rotate-45 ${themeColors?.bg?.selecttButton} top-0 right-[-10%] `}>
                            <p className='text-white text-xs lg:text-sm ml-12'>Popular</p>
                        </div>
                        : null}
                </div>
                <P title={'Our Basic plan offers essential features at an affordable price, perfect for startups and small businesses looking to get started.'} />
                <div className='w-full flex items-center gap-2 justify-center'>
                    <Price title={`${currency?.symbol} ${isYearly ? `${(price?.monthlyPrice)} ` : price?.monthlyPrice}`} />
                    <div className='flex items-center gap-1'>
                        {currency &&
                            <>
                                <H1
                                    title={`${currency?.symbol}${isYearly ? `${((price?.offerYearlyPrice - credits) / 12)?.toFixed(2)} ` : parseFloat(credits) < parseFloat(price?.offerMonthlyPrice) ? price?.offerMonthlyPrice - credits : price.offerMonthlyPrice} `}
                                />
                                <Span title={'/Month'} />
                            </>
                        }
                    </div>
                </div>
                <div className=' py-4 flex  gap-2'>
                    {trial > 0 ?
                        <Button
                            type="primary"
                            style={{
                                width: '100%'
                            }}
                            onClick={() => onStartTrial(plan?._id)}
                        >
                            Start Free Trial
                        </Button>
                        : null}

                    {location && (location?.pathname?.includes('pay_now') || location?.pathname?.includes('invoice')) ? null :
                        <Button
                            type={trial > 0 ? 'default' : 'primary'}
                            style={{
                                width: '100%',
                            }}
                            onClick={() => navigate(`/invoice`, { state: { plan, isYearly, trial, isSetting, currency } })}
                        >
                            Purchase
                        </Button>
                    }

                    {/* <Payments
                        currency={currency}
                        userId={authState?.id}  
                        teamId={authState?.teamId}  
                        plan={plan} 
                        authState={authState}
                        isYearly={isYearly}
                        trial={trial}
                        isSetting={isSetting}
                    /> */}

                </div>

                <div className='w-full  flex flex-col items-start gap-4 pt-8'>
                    <div className='flex items-center gap-2'>
                        <RightIcon />
                        <h6 className={`text-xs lg:text-sm text-['#424D51'] font-normal`}>{plan?.numberOfDocument} Documents /month</h6>
                    </div>
                    <div className='flex items-center gap-2'>
                        <RightIcon />
                        <h6 className={`text-xs lg:text-sm text-['#424D51'] font-normal`}>{plan?.numberOfUser} Users</h6>
                    </div>
                    <div className='flex items-center gap-2'>
                        {plan?.mobileSign ?
                            <RightIcon />
                            :
                            <CancelIcon />
                        }
                        <h6 className={`text-xs lg:text-sm text-['#424D51'] font-normal`}>Mobile Sign</h6>
                    </div>
                    <div className='flex items-center gap-2'>
                        {plan?.fileUpload ?
                            <RightIcon />
                            :
                            <CancelIcon />
                        }
                        <h6 className={`text-xs lg:text-sm text-['#424D51'] font-normal`}>File Upload</h6>
                    </div>
                    {plan?.fileUpload ?
                        <div className='flex items-center gap-2'>
                            <RightIcon />
                            <h6 className={`text-xs lg:text-sm text-['#424D51'] font-normal`}>{plan?.storage} mb storage available</h6>
                        </div>
                        : null}
                </div>
            </div>
        </>
    )
}

export default BillCard
