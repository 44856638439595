import React, { useState } from 'react';
import { ConfigProvider, List, Skeleton } from 'antd';


export const DocumentSkaleton = () => {

    const listData = Array.from({
        length: 3,
    }).map((_, i) => ({

    }));

    return (
        <>
            <List
                itemLayout="vertical"
                size="large"
                dataSource={listData}
                renderItem={(item) => (
                    <List.Item
                        key={item.title}
                    >
                        <div className='flex w-full items-center justify-between gap-3'>
                            <div className='w-full flex gap-6'>
                                <Skeleton.Button active={true} size={40} shape={'default'} block={false} />
                                <Skeleton active title={true} paragraph={{ rows: 1 }} />
                            </div>
                            <Skeleton.Button active={true} size={'default'} shape={'default'} block={false} />
                        </div>
                    </List.Item>
                )}
            />
        </>
    );
};

export const UsersSkaleton = () => {

    const listData = Array.from({
        length: 3,
    }).map((_, i) => ({

    }));

    return (
        <>
            <List
                itemLayout="vertical"
                size="large"
                dataSource={listData}
                renderItem={(item) => (
                    <List.Item
                        key={item.title}
                    >
                        <div className='flex w-full items-center justify-between gap-3'>
                            <div className='w-full flex gap-6'>
                                {/* <Skeleton.Button active={true} size={40} shape={'default'} block={false} /> */}
                                <Skeleton active title={true} paragraph={{ rows: 1 }} />
                            </div>
                            <Skeleton.Button active={true} size={'default'} shape={'default'} block={false} />
                        </div>
                    </List.Item>
                )}
            />
        </>
    );
};

export const TeamSkaleton = () => {

    const listData = Array.from({
        length: 3,
    }).map((_, i) => ({

    }));

    return (
        <>
            <List
                itemLayout="vertical"
                size="large"
                dataSource={listData}
                renderItem={(item) => (
                    <List.Item
                        key={item.title}
                    >
                        <div className='flex w-full items-center justify-between gap-3'>
                            <div className='w-full flex gap-6'>
                                <Skeleton.Button active={true} size={40} shape={'default'} block={false} />
                                <Skeleton active title={true} paragraph={{ rows: 1 }} />
                            </div>
                            {/* <Skeleton.Button active={true} size={'default'} shape={'default'} block={false} /> */}
                        </div>
                    </List.Item>
                )}
            />
        </>
    );
};

export const PlanSkaleton = () => {

    return (
        <>
            <div className='flex w-full items-center  gap-6'>
                <Skeleton.Button active={true} size={400} shape={'default'} block={false} />
                <Skeleton.Button active={true} size={400} shape={'default'} block={false} />
                <Skeleton.Button active={true} size={400} shape={'default'} block={false} />
                {/* <Skeleton.Button active={true} size={'default'} shape={'default'} block={false} /> */}
            </div>
        </>
    );
};

export const Historyskaleton = () => {

    const listData = Array.from({
        length: 5,
    }).map((_, i) => ({

    }));

    return (
        <>
            <List
                itemLayout="vertical"
                size="large"
                dataSource={listData}
                renderItem={(item) => (
                    <List.Item
                        key={item.title}
                    >
                        <div className='flex  w-full items-center justify-between gap-3'>
                            <Skeleton.Button active={true} size={'small'} block={true} />
                            {/* <Skeleton.Button active={true} size={'default'} shape={'default'} block={false} /> */}
                        </div>
                    </List.Item>
                )}
            />
        </>
    );
};

export const DocumentListskaleton = () => {

    const listData = Array.from({
        length: 3,
    }).map((_, i) => ({

    }));

    return (
        <>
            <List
                itemLayout="vertical"
                size="large"
                dataSource={listData}
                renderItem={(item) => (
                    <List.Item
                        key={item.title}
                    >
                        <div className='flex  w-full items-center justify-between gap-3'>
                            <Skeleton.Button active={true} size={'small'} block={true} />
                            {/* <Skeleton.Button active={true} size={'default'} shape={'default'} block={false} /> */}
                        </div>
                    </List.Item>
                )}
            />
        </>
    );
};

export const SubscriptionSkaleton = () => {

    return (
        <>
            <div className='flex w-full items-center  gap-6'>
                <Skeleton.Button active={true} size={200} shape={'default'} block={false} />
                <Skeleton.Button active={true} size={200} shape={'default'} block={false} />
                <Skeleton.Button active={true} size={200} shape={'default'} block={false} />
                {/* <Skeleton.Button active={true} size={'default'} shape={'default'} block={false} /> */}
            </div>
        </>
    );
};

export const ShowDocumentSkaleton = () => {

    return (
        <>
            <div className='flex flex-col w-full max-w-[950px] mx-auto py-8 items-center  gap-6'>
                <Skeleton.Button active={true} size={'large'} block={true} />
                <ConfigProvider
                    theme={{
                        token: {
                            /* here is your global tokens */
                            controlHeight: 300,

                        },
                    }}
                >
                    <Skeleton.Button active={true} size={'large'} block={true} />
                </ConfigProvider>
                <div className='w-full'>
                    <DocumentListskaleton />
                </div>
                <ConfigProvider
                    theme={{
                        token: {
                            /* here is your global tokens */
                            controlHeight: 140,

                        },
                    }}
                >
                    <Skeleton.Button active={true} size={'large'} block={true} />
                </ConfigProvider>
                <ConfigProvider
                    theme={{
                        token: {
                            /* here is your global tokens */
                            controlHeight: 180,

                        },
                    }}
                >
                    <Skeleton.Button active={true} size={'large'} block={true} />
                </ConfigProvider>   
            </div>  
        </>
    );
};