import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from '../Screens/Login'
import HomeLayout from '../Components/HomeLayout'
import SIgnup from '../Screens/Signup'
import EmailSignup from '../Components/EmailSignup'
import MobileSIgnup from '../Components/MobileSIgnup'
import Dashboard from '../Screens/Dashboard'
import Settings from '../Screens/Settings'
import Profile from '../Screens/Profile'
import ManageUser from '../Screens/ManageUser'
import Billing from '../Screens/Billing'
import HelpScreen from '../Screens/Help'
import SettingDrawer from '../Components/SettingDrawer'
import SelectOption from '../Screens/SelectOption'
import WriteDoc from '../Screens/WriteDoc'
import ShowDoc from '../Screens/ShowDoc'
import SignDoc from '../Screens/SignDoc'
import SignLayout from '../Components/SignLayout'
import ChooseTeam from '../Screens/ChooseTeam'
import TestPdf from '../Screens/TestPdf'
import CreateTeam from '../Screens/CreateTeam'
import PlanScreen from '../Screens/PlanScreen'
import Payments from '../Screens/Payments'
import ChangePassword from '../Screens/ChangePassword'
import ConfirmOtp from '../Screens/ConfirmOtp'
import ForgotPassword from '../Screens/ForgotPassword'
import ManageTeam from '../Screens/ManageTeam'
import FourZeroFour from '../Screens/FourZeroFour'
import Bill from '../Screens/Bill'
import BillingHome from '../Screens/BillingHome'
import PaymentStatus from '../Screens/PaymentStatus'
import { useDimensions } from '../DimensionContext/DimensionContext'
import DownloadPreview from '../Screens/DownloadPreview'

const MainRoutes = () => {
    const { lg, xl, xxl } = useDimensions()

    return (
        <>
            <Routes>

                <Route path='*' element={<FourZeroFour />} />

                <Route path='/' element={<Login />} />
                <Route path='/login' element={<Login />} />
                {/* <Route path='test' element={<TestPdf />} /> */}
                <Route path='/mobile-signup' element={<MobileSIgnup />} />
                <Route path='/signup' element={<EmailSignup />} />
                <Route path='/select-team' element={<ChooseTeam />} />
                <Route path='/create-team' element={<CreateTeam />} />
                <Route path='/' element={<HomeLayout />}>
                    <Route path='select-plan' element={<PlanScreen />} />
                    <Route path='invoice' element={<Bill />} />
                    <Route path='payment_status' element={<PaymentStatus />} />
                </Route>

                <Route path='confirm-otp' element={<ConfirmOtp />} />
                <Route path='forgot-password' element={<ForgotPassword />} />
                <Route path='change-password' element={<ChangePassword />} />
                <Route path='/payment' element={<Payments />} />
                
                <Route path='/home'>
                    <Route index element={<FourZeroFour />} />
                    <Route path='dashboard' element={<HomeLayout />}>
                        <Route index element={<Dashboard />} />
                    </Route>
                </Route>

                {/* CREATION ROUTES  */}
                {/* <Route path='create-doc' element={<HomeLayout />}> */}
                <Route path='create-doc' element={<HomeLayout />}>
                    <Route path='options'>
                        <Route path='' element={<SelectOption />} />
                        <Route path=':id' element={<SelectOption />} />
                    </Route>
                    <Route path='write' element={<WriteDoc />} />
                    {/* <Route path='read' element={<ShowDoc />} /> */}
                </Route>    

                {/* SHOW DOCUMENT AFTER CREATION  */}
                <Route path='document' element={<HomeLayout />}>
                    <Route path=':id' element={<ShowDoc />} />
                    <Route path='edit'>
                        <Route path=':id' element={<ShowDoc />} />
                    </Route>
                </Route>    
        
                <Route path='documents' >   
                    <Route path='download'>
                        <Route path=':id' element={<DownloadPreview />} />
                    </Route>    
                </Route>

                {/* SHOW DOCUMENT AFTER CREATION  */}
                <Route path='sing-document' element={<HomeLayout />}>
                    <Route path=':id' element={<SignDoc />} />  
                </Route>

                {/* <Route path='sign-doc' element={<SignLayout />}>
                    <Route path=':id' element={<SignDoc />} />
                     <Route path='signn' element={<Sign />} />
                </Route> */}

                <Route path='sign-doc' >
                    <Route path='*' element={<SignDoc />} />
                    {/* <Route path='signn' element={<Sign />} /> */}
                </Route>
                
                {/* SETTIGNS ROUTES  */}
                <Route path='/settings' element={<HomeLayout />}>
                    <Route path='' element={<Settings />}>
                        {lg || xl || xxl ?
                            <Route path='' element={<Profile />} />
                            :
                            <Route path='' element={<SettingDrawer />} />
                        }
                        <Route path='profile' element={<Profile />} />
                        <Route path='manage-team' element={<ManageTeam />} />
                        <Route path='user' element={<ManageUser />} />
                        <Route path='billing' element={<BillingHome />} >
                            <Route path='' element={<Billing />} />
                            <Route path='pay_now' element={<Bill />} />
                        </Route>

                        <Route path='billing' element={<Billing />} />
                        <Route path='help' element={<HelpScreen />} />
                    </Route>
                </Route>
            </Routes>
        </>
    )
}

export default MainRoutes
