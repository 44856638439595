import * as React from 'react';
import { useDimensions } from '../DimensionContext/DimensionContext';
import { Button, Collapse, message, Spin, Tooltip } from 'antd';
import { DownOutlined, LoadingOutlined, UpOutlined } from '@ant-design/icons';
import { DocumentIcon, DrawIcon, EmailOptionIcon, EmmailIcon, MobileOptionIcon, RightIcon } from '../Components/Icons';
import OptionsDialogue from '../Components/OptionsDialogue';
import SendOtpDialogue from '../Components/SendOtpDualogue';
import VerifyOtp from '../Components/VerifyOtp';
import SignaturePadComponent from '../Components/SignaturePadComponent';
import SignerComponent from '../Components/SignerComponent';
import ResponsiveModal from '../Components/ResponsiveModel';
import { BASEURL, capitalizeAllWords, formatDateFunction, get, hasMeaningfulContent, post } from '../Services/api-services';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { removeAllSigners, updateContent, updateTitle } from '../store/Reducers/contentReducer';
import { useTheme } from '../Themes/ThemeProvider';
import PDFVIewer from './PDFVIewer';
import { SignatureOutlined } from '@ant-design/icons';
import { H5, P } from '../Themes/StylingComponent';
import HeaderComponent from '../Components/HeaderComponent';


// DOWNLOAD IMPORT 
// import React from 'react';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import PDFMerger from 'pdf-merger-js';
import { ShowDocumentSkaleton } from './DocumentSkaleton';
import Loader from './Loader';
import DeletedDocument from '../Components/DeletedDocument';
import ContentPreview from '../Components/ContentPreview';

const SignDoc = () => {
    const { themeColors } = useTheme();
    const path = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { lg, xl, xxl } = useDimensions();
    const [open, setOpen] = React.useState(false)
    const [openSendOtp, setOpenSendOtp] = React.useState(false)
    const [openVerifyOtp, setOpenVerifyOtp] = React.useState(false)
    const [openSignPad, setOpenSignPad] = React.useState(false)
    const paths = path?.pathname?.split('/')
    const tt = paths.splice(2);
    const _documentId = tt?.join('/')
    const [documents, setDocuments] = React.useState([])
    const [currentEmail, setCurrentEmail] = React.useState('')
    const [currentSigner, setCurrentSigner] = React.useState('')
    const [_type, setType] = React.useState('')
    const [otp, setOtp] = React.useState('')
    const [count, setCount] = React.useState(30);
    const footerRef = React.useRef(null)
    const parentRef = React.useRef(null)
    const [height, setHeight] = React.useState(0);
    const [isMobile, setIsMobile] = React.useState(false)
    const [isValidSigner, setIsValidSigner] = React.useState(true)
    const [isDeleted, setIsDeleted] = React.useState(false)
    const [methods, setMethods] = React.useState('')
    const [createdBy, setCreatedBy] = React.useState('')
    const [textContent, setTextContent] = React.useState('')
    const [allSigners, setAllSigners] = React.useState(null)
    const [title, setTitle] = React.useState('')
    const textDocRef = React.useRef(null);
    const content2Ref = React.useRef(null);
    const [dataLoading, setDataLoading] = React.useState(true)
    const [docId, setDocId] = React.useState('')

    React.useEffect(() => {
        // Access the height after the component has rendered
        if (parentRef.current) {
            if (currentSigner?.status) {
                setHeight('')
            } else {
                setHeight(parentRef.current.offsetHeight - footerRef.current.offsetHeight);
            }
        }
    }, [parentRef]);

    React.useEffect(() => {
        const queryString = path?.search;
        if (queryString) {
            const email = queryString.match(/email=([^&]+)/)[1]; // Match the pattern 'email=' followed by any characters except '&' and capture it
            // getDocumentDetails();
            // setCurrentEmail(email)
            setIsValidSigner(true)
        } else {
            setIsValidSigner(false)
        }
    }, [path])

    // START COUNTER 
    const startCounter = () => {
        const intervalId = setInterval(() => {
            // Decrease count by 1 every second
            setCount(prevCount => prevCount - 1);
        }, 1000); // Interval of 1000 milliseconds (1 second)

        // Stop the counter after 30 seconds
        setTimeout(() => {
            clearInterval(intervalId); // Clear the interval
            setCount(30); // Reset count to 30 seconds
        }, 30000); // 30 seconds in milliseconds
    };

    // GET DOCUMENT DETAILS 
    React.useEffect(() => {
        getDocumentDetails();
    }, [currentEmail])

    const getDocumentDetails = async () => {
        try {
            const response = await post(`auth/document/documentWithAttachment`, { id: _documentId })
            setDataLoading(false)
            if (response?.statusCode == 200) {
                setIsMobile(response?.data?.data.methods?.mobile)
                setMethods(response?.data?.data.methods);
                setTitle(response?.data?.data.title)
                setTextContent(response?.data?.data.content)
                setDocuments(response?.data?.data.attachments)
                setAllSigners(response?.data?.data.signers)
                setCreatedBy(response?.data?.data.createdBy)
                setCurrentEmail(response?.data?.email);
                setDocId(response?.data?.data?._id)
                if (currentEmail) {
                    const _currentSigner = response?.data?.data.signers?.find(el => el?.email === currentEmail);
                    if (_currentSigner) {
                        setCurrentSigner(_currentSigner)
                        setIsValidSigner(true)
                    } else {
                        setIsValidSigner(false)
                        // navigate('/')
                    }
                }

            } else {
                if (response.statusCode == 405) {
                    setIsDeleted(true)
                }
                setIsValidSigner(false)
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING DOCUMENTS DETAILS ${e}`);
            setIsValidSigner(false)
        }
    }

    // SEND OTP ON EMAIL/MOBILE 
    const sendOtp = async () => {
        try {
            let Type;
            if (_type === 'phone') {
                Type = 'mobile'
            } else {
                Type = _type
            }
            const _body = {
                type: Type,
                value: _type === 'phone' ? currentSigner?.mobile : currentSigner?.email,
                documentId: docId
            }
            const response = await post('auth/sendOtp', _body)
            if (response?.statusCode == 200) {
                setOpenSendOtp(false);
                setOpenVerifyOtp(true);
                // startCounter();  
                message?.success('OTP sent successfully!')
            }
        } catch (e) {
            console.log(`ERROR WHILE SENDING OTP ${e}`);
        }
    }

    // VERIFY OTP 
    const verifyOtp = async () => {
        try {
            let Type;
            if (_type === 'phone') {
                Type = 'mobile'
            } else {
                Type = _type
            }
            const _body = {
                type: Type,
                value: _type === 'phone' ? currentSigner?.mobile : currentSigner?.email,
                otp: otp,
                documentId: docId
            }
            const response = await post('auth/verifyOtp', _body)
            if (response?.statusCode == 200) {
                message.success('Document Signed Successfully!')
                setOpenVerifyOtp(false)
                // dispatch(removeAllSigners(response?.data)) 
                getDocumentDetails()
            } else {
                message.error(response?.message)
            }
        } catch (e) {
            console.log(`ERROR WHILE VERIFYING OTP ${e}`);
        }
    }

    const downloadPdf = async (docName) => {
        console.log(docName);
        message.warning('Preparing Document!')
        const merger = new PDFMerger();
        const textDoc = document.getElementById('content-to-download');
        textDoc.style.boxShadow = 'none';
        // const textDoc = textDocRef?.current  

        // const singDiv = document.getElementById('content-2');
        const singDiv = content2Ref?.current;
        singDiv.style.boxShadow = 'none';

        const input = textDoc;
        const input2 = singDiv;

        // Use html2canvas to capture the content of input as an image
        html2canvas(input).then(async canvas => {
            // Convert the canvas to a base64-encoded PNG image
            const imageData = canvas.toDataURL('image/png');

            // Calculate the dimensions of the PDF page
            const pdfWidth = 210; // Width of A4 paper in mm
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

            // Create a new PDF document
            const pdf = new jsPDF('p', 'mm', 'a4');

            // Add the captured image to the PDF
            pdf.addImage(imageData, 'PNG', 0, 0, pdfWidth, pdfHeight);

            // Convert the PDF to a base64-encoded string
            const pdfString = pdf.output('datauristring');

            // Add the first PDF to the merger
            await merger.add(pdfString);

            // Fetch and add existing PDF files to the merger
            if (documents?.length > 0) {
                const fetchPromises = documents.map(async document => {
                    const existingPdf = await fetch(`${BASEURL}public/documents/${document?.fileName}`);
                    const pdfBytes = await existingPdf.arrayBuffer();
                    merger.add(pdfBytes);
                });

                await Promise.all(fetchPromises);
            }

            // Use html2canvas to capture the content of input2 as an image
            html2canvas(input2).then(async canvas2 => {
                // Convert the canvas to a base64-encoded PNG image
                const imageData2 = canvas2.toDataURL('image/png');

                // Create a new PDF document for input2
                const pdf2 = new jsPDF('p', 'mm', 'a4');

                // Add the captured image of input2 to the PDF
                pdf2.addImage(imageData2, 'PNG', 0, 0, pdfWidth, pdfHeight);

                // Convert the PDF to a base64-encoded string
                const pdfString2 = pdf2.output('datauristring');

                // Add the second PDF to the merger
                await merger.add(pdfString2);

                // Set metadata for the merged PDF
                await merger.setMetadata({
                    producer: "Signizy",
                    author: "Signizy",
                    creator: "Signizy",
                    title: "Signizy"
                });

                // Merge the PDFs
                const mergedPdfBytes = await merger.saveAsBlob();

                // Save the merged PDF
                saveAs(mergedPdfBytes, `${docName}.pdf`);
            });
        });
    };

    React.useEffect(() => {
        // const idRegex = /^[0-9a-fA-F]{24}$/;
        // const currentPath = location.pathname?.split('/');
        // let lastElement = currentPath[currentPath?.length - 1];
        // if (idRegex.test(lastElement)) {
        //     lastElement = 'Document'
        // }
        document.title = capitalizeAllWords('Document');
    }, []);

    return (
        <>
            {!currentSigner && path?.search != "" ?
                <Loader />
                :
                !isDeleted ?
                    dataLoading ? <ShowDocumentSkaleton />
                        :
                        <div className={`w-full h-screen overflow-hidden pt-14 flex flex-col ${themeColors.bg.secondary} `}>
                            <HeaderComponent
                                title={title}
                                createdBy={createdBy}
                                _documentid={docId}
                                onDownload={(title) => downloadPdf(title)}
                            />
                            <div className={`w-full relative flex-1 overflow-y-auto pb-16 ${themeColors.bg.primary}`} >
                                <div
                                    // style={{ maxHeight: currentSigner?.status ? 'fit-content' : height }}
                                    className={`w-full  flex  justify-center py-4 pb-6`}>
                                    <div className='w-full relative flex flex-col items-start gap-3 py-3  px-3 max-w-[1000px] overflow-x-hidden'>
                                        {/* TEXT CONTENT  */}
                                        <div className='w-full max-w-[921px]'>
                                            <ContentPreview
                                                textContent={textContent}
                                            />
                                        </div>

                                        {/* SHOW DOC LIST  */}
                                        {documents &&
                                            <PDFVIewer
                                                documents={documents}
                                            />
                                        }

                                        {/* SIGNERS  */}
                                        {allSigners ?
                                            <div
                                                ref={content2Ref}
                                                id='content-2'
                                                style={{
                                                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 0px 4px',
                                                }}
                                                className={`w-full max-w-[912px] mx-auto  rounded ${themeColors.bg.primary}`}
                                            >
                                                {/* HEADER  */}
                                                {allSigners?.length > 0 ?
                                                    <div
                                                        className={`py-2 w-full lg:w-fit flex items-center gap-2 px-3 border-b ${themeColors.borders.third}`}>
                                                        <h2 className={`text-base lg:text-xl font-semibold ${themeColors.headings.primary}`}>SIGNERS</h2>
                                                    </div>
                                                    : null}

                                                {/* SINGNERS  */}
                                                <div className={`w-full hid px-3 border-t ${themeColors.borders.third}`}>
                                                    {allSigners?.map((sign, index) => {
                                                        return (
                                                            <SignerComponent
                                                                key={index}
                                                                name={sign?.name}
                                                                title={sign?.title}
                                                                sign={sign}
                                                                currentSigner={currentSigner}
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            : null}
                                    </div>
                                </div>

                                {/* SIGN NOW  */}

                                {!currentSigner?.status && isValidSigner ?
                                    <div
                                        style={{
                                            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 0px 4px',
                                        }}
                                        className={`w-full left-0 fixed  bottom-0   mx-auto flex items-center justify-between py-2 px-3 ${themeColors.bg.primary}`}
                                    >
                                        <div className='flex flex-col items-start gap-1 rounded'>
                                            <Tooltip title={currentSigner?.name?.length > 15 ? currentSigner?.name : ''}>
                                                <h3 className={`text-lg lg:text-lg font-medium ${themeColors.headings.primary}`}>{currentSigner?.name?.length > 15 ? currentSigner?.name.substring(0, 15) + '...' : currentSigner?.name}(You)</h3>
                                            </Tooltip>
                                            <div className='flex  gap-1'>
                                                <p className={`text-xs lg:text-sm ${themeColors.text.fourth}`}>Title: </p>
                                                <Tooltip title={currentSigner?.title?.length > 15 ? currentSigner?.title : ''}>
                                                    <p className={`text-xs lg:text-sm ${themeColors.text.fourth}`}>{currentSigner?.title?.length > 15 ? currentSigner?.title.substring(0, 15) + '...' : currentSigner?.title}</p>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <Button
                                            onClick={() => setOpen(true)}
                                            style={{
                                                borderRadius: 3,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                            icon={<SignatureOutlined />}
                                            type="primary" size='large'>
                                            Sign Now
                                        </Button>
                                    </div>
                                    : null}

                                {/* OPTION DIALOGUE  */}
                                <OptionsDialogue
                                    open={open}
                                    setOpen={setOpen}
                                    setOpenSendOtp={setOpenSendOtp}
                                    setOpenSignPad={setOpenSignPad}
                                    currentSigner={currentSigner}
                                    _type={_type}
                                    setType={setType}
                                    isMobile={isMobile}
                                    methods={methods}
                                />

                                {/* SEND OTP DIALOGUE  */}
                                <SendOtpDialogue
                                    open={openSendOtp}
                                    setOpenVerifyOtp={setOpenVerifyOtp}
                                    setOpen={setOpenSendOtp}
                                    currentEmail={currentEmail}
                                    currentSigner={currentSigner}
                                    setCurrentSigner={setCurrentSigner}
                                    onSendOtp={() => sendOtp()}
                                    _type={_type}
                                    setType={setType}
                                />

                                {/* VERIFY OTP  */}
                                <VerifyOtp
                                    open={openVerifyOtp}
                                    setOpen={setOpenVerifyOtp}
                                    otp={otp}
                                    setOtp={setOtp}
                                    currentSigner={currentSigner}
                                    count={count}
                                    _type={_type}
                                    onVerifyOtp={verifyOtp}
                                    onSendOtp={() => sendOtp()}
                                />

                                {/* SIGNTURE PAD COMPONENT  */}
                                <SignaturePadComponent
                                    open={openSignPad}
                                    _documentId={docId}
                                    setOpen={setOpenSignPad}
                                    currentEmail={currentEmail}
                                    currentSigner={currentSigner}
                                    setCurrentSigner={setCurrentSigner}
                                    getDocumentDetails={() => getDocumentDetails()}
                                    onclose={() => {
                                        setOpenSignPad(false)
                                    }}
                                />

                                <ResponsiveModal
                                    open={false}
                                />
                            </div >
                        </div>
                    :
                    <>
                        <DeletedDocument />
                    </>
            }
        </>
    )
}

export default SignDoc

