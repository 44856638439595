import React, { useEffect } from 'react'
import { RightArrowIcon, RightIcon } from '../Components/Icons';
import { PlusOutlined, TeamOutlined } from '@ant-design/icons';
import { Avatar, Button, Empty, Skeleton, Tooltip } from 'antd';
import { BASEURL, capitalizeAllWords, get } from '../Services/api-services';
import { useRecoilState } from 'recoil';
import { userAuthState } from '../Services/RecoilService';
import { useTheme } from '../Themes/ThemeProvider';
import { useNavigate } from 'react-router-dom';
import { TeamSkaleton } from './DocumentSkaleton';

const _img = require('../assets/Images/teamLogo.png')


const ChooseTeam = () => {
    const { themeColors } = useTheme();
    const navigate = useNavigate();
    const [authState, setAuthState] = useRecoilState(userAuthState)
    const [teams, setTeams] = React.useState([])
    const [dataLoading, setDataLoading] = React.useState(true)

    // GET USERS TEAM FUNCTION 
    useEffect(() => {
        getUserTeasm();
    }, [])

    const getUserTeasm = async () => {
        try {
            const response = await get(`user/${authState?.id}/team`)
            if (response?.statusCode == 200) {
                setTeams(response?.data)
                setDataLoading(false)
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHINF TEAMS ${e}`);
        }
    }
    const checkTeamHaveCountryAndPlan = async (teamId) => {
        try {
            const res = await get(`team/${teamId}`);
            if (res.statusCode === 200) {
                if (!res?.data?.country) {
                    navigate('/mobile-signup')
                } else if (!res?.data?.plan) {
                    navigate('/select-plan')

                } else {
                    navigate('/home/dashboard')
                }

            }
        } catch (e) {
            throw (e);
        }
    }
    const selectTeam = (teamId) => {
        setAuthState(prevState => ({
            ...prevState, // Keep the existing data
            teamId: teamId // Add the new team ID
        }));
        checkTeamHaveCountryAndPlan(teamId)
    }

    React.useEffect(() => {
        document.title = capitalizeAllWords('Team');
    }, [])

    return (
        <>
            <div className='w-full bg-[#FFFFFF] h-screen flex flex-col items-center justify-center '>
                <div className={`w-4/5 lg:w-1/4 flex  flex-col ${teams?.length > 0 ? ' items-start' : 'justify-center items-center'}   gap-6`}>
                    {teams?.data?.length > 0 ?
                        <div className='w-full'>
                            <h1 className={`text-xl ${themeColors.headings.primary}`}>Choose Team</h1>
                        </div>
                        : null}
                    {dataLoading ?
                        <div className='w-full'>
                            <TeamSkaleton />
                        </div>
                        : teams?.length > 0 ?
                            teams?.map((team, index) => (
                                <div onClick={() => selectTeam(team?._id)} key={index} className='flex items-center cursor-pointer justify-between py-6 w-full px-3 hover:bg-[#FFFFFF] hover:shadow rounded group'>
                                    <div className='flex items-center gap-3'>
                                        {team?.logo ?
                                            <img
                                                className='w-12 rounded h-10 object-cover'
                                                src={`${BASEURL}public/logos/${team?.logo}`}
                                                alt=""
                                                onError={(e) => { e.target.src = _img }}
                                            />
                                            :
                                            <div className='w-1/4  flex items-start justify-start '>
                                                <Avatar
                                                    src={`${BASEURL}public/logos/${team?.logo}`}
                                                    shape="square"
                                                    size={44}
                                                    icon={<TeamOutlined />}
                                                />
                                            </div>
                                        }
                                        {/* <img className='w-12 rounded h-10 object-cover' src={_img} alt="_img" /> */}
                                        <div>
                                            <Tooltip title={team?.name?.length > 20 ? team?.name : ''}>
                                                <h6 className='text-[#424D51]  whitespace-nowrap text-sm'>{team?.name?.length > 20 ? team?.name?.substring(0, 20) + '...' : team?.name}</h6>
                                            </Tooltip>
                                            <p className='text-[#8E8E8E] whitespace-nowrap text-xs'>{team?.member} Members in this group</p>
                                        </div>
                                    </div>
                                    <div className='opacity-0 group-hover:opacity-100'>
                                        <RightArrowIcon />
                                    </div>
                                </div>
                            ))
                            :
                            <Empty
                                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                imageStyle={{
                                    height: 60,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                                description={
                                    <span>
                                        No Teams Available create a team to continue
                                    </span>
                                }
                            >
                                <Button
                                    styl={{ color: '#AAAAAA' }}
                                    icon={<PlusOutlined />}
                                    onClick={() => navigate('/create-team')}
                                    type="primary">
                                    Create New Team
                                </Button>
                            </Empty>
                    }
                </div>
                <div className='w-full py-6 px-4 fixed bottom-0'>
                    {dataLoading ?
                        <Skeleton.Button active={true} size={'large'} shape={'default'} block={false} />
                        :
                        teams?.length > 0 ? 
                            <Button
                                styl={{ color: '#AAAAAA' }}
                                icon={<PlusOutlined />}
                                type="text"
                                onClick={() => navigate('/create-team')}
                            >
                                Create New Team
                            </Button>
                            : null
                    }   
                </div>
            </div>
        </>
    )
}

export default ChooseTeam
