import React, { useState } from 'react'
import { useDimensions } from '../DimensionContext/DimensionContext';
import { CloseOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Collapse, ConfigProvider, Skeleton, message } from 'antd';
import { DeleteIcon, DeleteTextIcon, DocumentIcon, DownloadIcon, EditTextIcon, OAiIcon, UploadDocIcon } from '../Components/Icons';
import SignVIa from '../Components/SignVIa';
import AddSIgnForm from '../Components/AddSIgnForm';
import DeleteDocDialogue from '../Components/DeleteDocDialogue';
import SendDrawer from '../Components/SendDrawer';
import { BASEURL, get, hasMeaningfulContent, patch } from '../Services/api-services';
import { useLocation, useNavigate, useHistory, json } from 'react-router-dom';
import { useEffect } from 'react';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from 'react-redux';
import { removeAllSigners, removeSigners, updateContent, updateMethods, updateSigners, updateStatus, updateTitle } from '../store/Reducers/contentReducer';
import { userAuthState } from '../Services/RecoilService';
import { useRecoilState } from 'recoil';
import { useTheme } from '../Themes/ThemeProvider';
import PDFVIewer from './PDFVIewer';
import CreateHeader from '../Components/CreateHeader';
import ShowDocumentList from '../Components/ShowDocumentList';
// DOWNLOAD IMPORT 
// import React from 'react';
import { ShowDocumentSkaleton } from './DocumentSkaleton';
import ContentPreview from '../Components/ContentPreview';


//SIGNER PAYLOAD DATA 
const _signersData = {
    title: '',
    name: '',
    email: '',
    mobile: '',
    country: {
        code: 'In',
        label: "India",
        phone: "91",
        phoneLength: 10
    },
    teamId: ''
}

const _methods = {
    // methods: {
    draw: true,
    email: false,
    mobile: false
    // }
}

const ShowDoc = () => {
    const { themeColors } = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    // const history = useHistory();    
    const dispatch = useDispatch();
    const { lg, xl, xxl } = useDimensions();
    const [open, setOpen] = React.useState(false)
    const [openDrawer, setOpenDrawer] = useState(false);
    const [textContent, setTextContent] = useState('');
    const [documents, setDocuments] = useState([]);
    const [authState, setAuthState] = useRecoilState(userAuthState)
    const _documentid = location?.pathname?.includes("edit") ? location.pathname.split('/')[3] : location.pathname.split('/')[2]
    const [methods, setMethods] = React.useState({ ..._methods })
    const [signersData, setSignerData] = React.useState({ ..._signersData })
    const [allsigners, setAllSigners] = React.useState([])
    const [selectedSigners, setSelectedSigners] = React.useState([])
    const [isError, setIsError] = React.useState(false)
    const [isSigned, setIsSigned] = React.useState(false)
    const [isMySigned, setIsMySigned] = React.useState(false)
    const [selectedCountry, setSelectedCountry] = React.useState({ ..._signersData?.country });
    const { title, content, signers, myMethods, status } = useSelector(state => state.contentOption);
    const footerRef = React.useRef(null)
    const parentRef = React.useRef(null)
    const [height, setHeight] = React.useState(0);
    const [currentUser, setCurrentUser] = React.useState('')
    const [currentTeamData, setCurrentTeamData] = React.useState('')
    const [currentSignerm, setCurrentSigner] = React.useState('')
    const content2Ref = React.useRef(null);
    const [ownerId, setOwnerId] = React.useState('')
    const [isEditable, setIsEditable] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const [dataLoading, setDataLoading] = React.useState(true)
    const buttonStyle = { backgroundColor: themeColors.bg.addButton, display: 'flex', alignItems: 'center', justifyContent: 'center' }

    React.useEffect(() => {
        // Access the height after the component has rendered
        if (parentRef.current) {
            setHeight(parentRef.current.offsetHeight - footerRef.current.offsetHeight);
        }
    }, [parentRef]);

    useEffect(() => {
        if (status == 'error') {
            setIsError(true)
        }
    }, [status])

    // GET ALL SELECTED SIGNERS 
    useEffect(() => {
        if (allsigners) {
            setSelectedSigners([...allsigners])
        }
    }, [allsigners])

    // GET ALL SELECTED METHODS 
    useEffect(() => {
        if (myMethods) {
            setMethods(myMethods)
        }
    }, [myMethods])

    useEffect(() => {
        setSignerData({
            ...signersData,
            teamId: authState?.teamId
        })
    }, [authState])

    // GET CURRENT DOCUMENT DATA 
    useEffect(() => {
        // getDocumentData();  
        getTeamData();
        getCurrentUserDetails()
    }, [])

    const getDocumentData = async (_currentEmail) => {
        try {
            const response = await get(`document/${_documentid}/documentWithAttachment`)
            setDataLoading(false)
            if (response.statusCode === 200 && !response?.data?.trash) {
                setOwnerId(response?.data?.createdById)
                setTextContent(response?.data?.content)
                dispatch(updateTitle(response?.data?.title))
                // UPDATE SINGNERS DATA 
                if (response?.data?.signers || response?.data?.methods) {
                    const _methods = response?.data?.methods
                    if (response?.data?.signers?.length > 0) {
                        // dispatch(removeAllSigners(response?.data?.signers))
                        setAllSigners(response?.data?.signers)
                    }
                    if (response?.data?.methods) {
                        dispatch(updateMethods(_methods))
                    }
                    const currentSigner = response?.data?.signers?.find(signer => signer?.email === _currentEmail);
                    const isAvailable = currentSigner?.status;
                    setIsMySigned(isAvailable)

                    if (response?.data?.signers && Array.isArray(response?.data?.signers)) {
                        const anySigned = response?.data?.signers.some(signer => signer.status === true);
                        setIsSigned(anySigned);
                    }
                } else {
                    if (response?.data?.signers?.length > 0) {
                        dispatch(removeAllSigners([]))
                    }
                }
                setDocuments(response?.data?.attachments)
            } else {
                navigate('/home/dashboard', { replace: true })
                dispatch(updateTitle(''))
                dispatch(updateContent(''))
                dispatch(removeAllSigners([]))
                dispatch(updateMethods(_methods))
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING DOCUMENT ${e}`);
            navigate('/home/dashboard', { replace: true })
        }
    }

    const getCurrentUserDetails = async () => {
        try {
            const response = await get(`user/${authState?.id}`)
            if (response?.statusCode == 200) {
                setCurrentUser(response?.data)
                getDocumentData(response?.data?.email);
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING USER DETAILS ${e}`);
        }
    }

    // UPDATE MEHTODS 
    const updateChange = async (checked, type) => {
        // Update the state
        const updateMethodsData = {
            // ...methods.methods, // Keep existing methods
            ...methods,
            [type]: checked // Update the specific method
        };
        setMethods(updateMethodsData);
        dispatch(updateMethods(updateMethodsData))
    };

    // ADD SIGNERS 
    const addSigner = async () => {
        try {
            const _body = {
                ...signersData,
                teamId: authState?.teamId
            }
            _body.email = _body.email?.toLowerCase();
            if (_body.email && allsigners.find(signer => signer.email === _body.email)) {
                message.error(`User already exist with this Email ${_body?.email}`);
                return
            }

            if (_body.mobile && allsigners.find(signer => signer.mobile === _body.mobile)) {
                message.error(`User already exist with this Mobile ${_body?.mobile}`);
                return
            }

            // dispatch(updateSigners(_body))
            setAllSigners(prevState => [...prevState, _body]);
            // const response = await patch(`document/${_documentid}/addSigner`, _body) 
            // if (response.statusCode == 200) {
            message.success('Signer added successfully!')
            setSignerData(_signersData)
            setSelectedCountry(_signersData?.country)
            dispatch(updateStatus(''))
        } catch (e) {
            console.log(`ERROR WHILE ADDING SIGNER ${e}`);
            message.success('Error while adding signer!')
        }
    }

    // REMOVE SIGNER 
    const removeSigner = async (idx) => {
        const selectedSigner = allsigners[idx]
        const remainingSigners = allsigners.filter((signer, index) => index !== idx);
        setAllSigners(remainingSigners)
        // allsigners.splice(idx, 1);       
        // dispatch(removeSigners(idx));
    }

    // SAVE AS DRAFT 
    const saveDocumemt = async (status) => {
        try {
            const _body = {
                // signers: signers,
                signers: allsigners,
                methods: methods,
                status: 'draft'
            }
            const _method = {
                // methods: {
                draw: true,
                email: false,
                mobile: false
                // }
            }

            if (!methods?.draw && !methods?.email && !methods?.mobile) {
                message.error('Select atleast one method!')
                return
            }

            if (status == 'send') {
                if (!authState?.emailVerify) {
                    message.error('Verify your email to send this document!')
                    return
                }

                if (currentTeamData?.plan) {
                    // Convert the provided timestamp to a Date object
                    const endValidityDate = new Date(currentTeamData?.plan?.endValidity);

                    // Get the current date and time
                    const currentDate = new Date();
                    if (endValidityDate < currentDate) {
                        // setIsMobile(false)
                        message.error('Plan Expired!')
                        return
                    }
                } else {
                    message.error('Plan Expired!')
                    return
                }
            }
            const response = await patch(`document/${_documentid}`, _body)

            if (response?.statusCode == 200 && status == 'send') {
                setOpenDrawer(true)
            } else if (response?.statusCode == 200) {
                dispatch(updateContent(''))
                dispatch(removeAllSigners([]))
                dispatch(updateTitle(''));
                dispatch(updateMethods(_method))
                if (status != 'saveDoc') {
                    navigate('/home/dashboard', { replace: true });
                }   
            }

            // if (response?.statusCode == 200) {
            //     dispatch(updateContent(''))
            //     dispatch(removeAllSigners([]))
            //     dispatch(updateTitle(''));
            //     dispatch(updateMethods(_method))
            //     getDocumentData()
            // }

        } catch (e) {
            console.log(`ERROR WHILE SAVING DATA ${e}`);
        }
    }

    // GET CURRENT TEAM DATA 
    const getTeamData = async () => {
        try {
            const response = await get(`team/${authState?.teamId}`);
            if (response?.statusCode == 200) {
                setCurrentTeamData(response?.data)
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING CURRENT PLAN ${e}`);
        }
    }

    React.useEffect(() => {
        if (authState?.id == ownerId || authState?.role != 'moderator') {
            setIsEditable(true)
        } else {
            setIsEditable(false)
        }
    }, [ownerId])

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (allsigners) {
                const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave?';
                e.preventDefault();
                e.returnValue = confirmationMessage; // Display custom message
                return confirmationMessage;
            }
        };

        const handleNavigation = (e) => {
            const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave?';
            if (allsigners && !window.confirm(confirmationMessage)) {
                e.preventDefault();
            } else {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.removeEventListener('popstate', handleNavigation);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handleNavigation);
        };
    }, [allsigners]);


    return (
        <>
            {dataLoading ?
                <ShowDocumentSkaleton />
                :
                <div className=' w-full px-3 pb-24 '>
                    {/* <ShowDocumentSkaleton /> */}

                    <CreateHeader
                        isSigned={isSigned}
                        isEditable={isEditable}
                        dataLoading={dataLoading}
                    />

                    <div
                        className='w-full  max-w-[1000px] mx-auto'>
                        {/* TEXT CONTENT DIV  */}
                        <ContentPreview
                            textContent={textContent}
                        />

                        {/* SHOW DOC LIST  */}

                        {documents && documents?.length > 0 ?
                            <ShowDocumentList
                                documents={documents}
                            />
                            : null}

                        < div className='w-full  '>
                            {/* SIGN OPTIONS  */}
                            <SignVIa
                                methods={methods}
                                setMethods={setMethods}
                                isSigned={isSigned}
                                currentTeamData={currentTeamData}
                                updateMethod={(checked, type) => updateChange(checked, type)}
                            />
                        </div>

                        {/* ADD SIGNERS  */}
                        <div
                            style={{
                                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 0px 4px',
                            }}
                            className={`w-full   rounded ${themeColors.bg.primary}`}
                        // id='content-2'
                        >
                            <AddSIgnForm
                                signersData={signersData}
                                setSignerData={setSignerData}
                                addSigner={addSigner}
                                // signers={signers}
                                signers={allsigners}
                                currentUser={currentUser}
                                selectedCountry={selectedCountry}
                                setSelectedCountry={setSelectedCountry}
                                removeSigner={(idx) => removeSigner(idx)}
                                methods={methods}
                                content2Ref={content2Ref}
                                // isSigned={isSigned}
                                isSigned={isMySigned}
                                _documentId={_documentid}
                                saveDocument={() => saveDocumemt('saveDoc')}
                                getDocumentData={getDocumentData}
                                getCurrentUserDetails={getCurrentUserDetails}
                            />
                        </div>
                    </div>

                    {/* BUTTONS  */}
                    <div
                        // ref={content2Ref}
                        className={`w-full  mt-3 flex items-center fixed bottom-0 z-50 left-0 justify-center border-t px-3 ${themeColors.bg.secondary} ${themeColors.borders.billing}`}>
                        <div className='w-full flex items-center justify-between py-6'>
                            <div className='flex gap-3'>
                                {/* <DownloadPdfComponent
                                textDocRef={textDocRef}
                                content2Ref={content2Ref}
                                documents={documents}   
                            /> */}
                                <Button
                                    // onClick={() => downloadPdf(title)}
                                    onClick={() => {
                                        saveDocumemt('saveDoc')
                                        navigate(`/documents/download/${_documentid}`)
                                    }}
                                    size={lg || xl || xxl ? 'middle' : 'large'}
                                    style={buttonStyle}
                                    type="primary"
                                    icon={<DownloadIcon color={themeColors.icons.primary} />}
                                    loading={isLoading}
                                >
                                    {lg || xl || xxl ?
                                        <h6 style={{ color: themeColors.icons.primary }}>Download</h6>
                                        : null}
                                </Button>
                                <Button
                                    onClick={() => setOpen(true)}
                                    type="text"
                                    danger
                                    icon={lg || xl || xxl ? null : <DeleteIcon color={'red'} />}
                                >
                                    {lg || xl || xxl ?
                                        'Move To Trash'
                                        : null}
                                </Button>
                            </div>
                            <div className={`flex ${location?.pathname?.includes('edit') ? 'flex-row-reverse' : ''} items-center gap-2`}>
                                <Button
                                    onClick={() => saveDocumemt('draft')}
                                    size={lg || xl || xxl ? 'middle' : 'large'}
                                    style={{
                                        ...(location?.pathname?.includes('edit')
                                            ? {} // If pathname includes 'edit', no additional styles
                                            : { backgroundColor: themeColors.bg.addButton, color: themeColors.bg.inviteButton })
                                    }}
                                    type="primary"
                                >
                                    {location?.pathname?.includes('edit') ?
                                        'Save'
                                        : 'Save as draft'
                                    }
                                </Button>
                                <Button
                                    onClick={() => saveDocumemt('send')}
                                    size={lg || xl || xxl ? 'middle' : 'large'}
                                    // style={{ backgroundColor: themeColors.bg.inviteButton }}
                                    style={{
                                        ...(location?.pathname?.includes('edit')
                                            ? { backgroundColor: themeColors.bg.addButton, color: themeColors.bg.inviteButton }
                                            : {})
                                    }}
                                    type="primary"
                                >
                                    {location?.pathname?.includes('edit') ?
                                        'Send again'
                                        : 'Send'
                                    }
                                </Button>
                            </div>
                        </div>
                    </div>
                </div >
            }

            <DeleteDocDialogue
                open={open}
                setOpen={setOpen}
                onDelete={getDocumentData}
            />

            <SendDrawer
                // signers={signers}
                signers={allsigners}
                open={openDrawer}
                selectedSigners={selectedSigners}
                setSelectedSigners={setSelectedSigners}
                setOpen={setOpenDrawer}
            />

        </>
    )
}

export default ShowDoc
