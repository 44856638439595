import React from 'react';
import { useDimensions } from "../DimensionContext/DimensionContext";
import SettingDrawer from '../Components/SettingDrawer';
import Header from '../Components/Header';
import { CloseOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Select, Collapse, Input, Button, message, ConfigProvider, Cascader, Form } from 'antd';
import { useTheme } from '../Themes/ThemeProvider';
import { post } from '../Services/api-services';
import { H3, H6 } from '../Themes/StylingComponent';
import { DocumentIcon } from '../Components/Icons';
import _questions from '../FrequentlyAskedQiestions.json';
import items from '../Purpose.json';
import { userAuthState } from '../Services/RecoilService';
import { useRecoilState } from 'recoil';

const { TextArea } = Input;

const _data = {
    purpose: '',
    subPurpose: '',
    message: '',
    attachments: ''
};

const HelpScreen = () => {
    const { themeColors } = useTheme();
    const { lg, xl, xxl } = useDimensions();
    const inputRef = React.useRef(null);
    const [formData, setFormData] = React.useState({ ..._data });
    const [image, setImage] = React.useState([]);
    const [authState, setAuthState] = useRecoilState(userAuthState);
    const [deletedDocs, setDeletedDocs] = React.useState([]);
    const [form] = Form.useForm();

    const customExpandIcon = ({ isActive }) => {
        return isActive ? <UpOutlined /> : <DownOutlined />;
    };

    const removeDocAtIndex = (indexToRemove) => {
        const selectedValue = image[indexToRemove];
        if (selectedValue?.fileName) {
            setDeletedDocs([
                ...deletedDocs,
                selectedValue?._id
            ]);
        }
        setImage((prevDocUrl) =>
            prevDocUrl.filter((_, index) => index !== indexToRemove)
        );
        if (inputRef.current) {
            inputRef.current.value = null;
        }
    };

    const FormHeading = ({ title }) => (
        <h6 className={`text-xs lg:text-sm ${themeColors.headings.primary}`}>{title}</h6>
    );

    const askQuery = async () => {
        try {
            let formDataObj = new FormData();
            image.forEach((file) => {
                formDataObj.append('attachments', file);
            });

            const values = form.getFieldsValue();

            if (values?.purpose?.length == 0) {
                message.error('Please Select Purpose and Subpurpose!');
                return;
            }

            if (!values?.purpose[0]) {
                message.error('Please Select Purpose!');
                return;
            }
            if (!values?.purpose[1]) {
                message.error('Please Select Sub Purpose!');
                return;
            }

            if (!formData?.message) {
                message.error('Please add a message!');
                return;
            }

            const _body = {
                purpose: values?.purpose[0],
                subPurpose: values?.purpose[1],
                message: formData?.message,
                attachments: formDataObj
            };

            formDataObj.append('purpose', JSON.stringify(values?.purpose[0]));
            formDataObj.append('subPurpose', JSON.stringify(values?.purpose[1]));
            formDataObj.append('message', JSON.stringify(_body?.message));

            const response = await post(`user/${authState?.id}/sendSupportEmail`, formDataObj);
            if (response?.statusCode == 200) {
                message.success(response?.data?.message);
                setFormData(_data);
                form.resetFields(); 
                setImage([]);
            } else {
                message?.error(response?.message);
            }
        } catch (e) {
            console.log(`ERROR WHILE ADDING QUERY ${e}`);
        }
    };

    const [options, setOptions] = React.useState(items);
    const loadData = (selectedOptions) => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.children = targetOption.subPurpose;
        setOptions([...options]);
    };  

    return (
        <>
            <div className={`w-full h-full  overflow-y-auto  py-4 lg:px-6 ${themeColors.bg.primary}`}>
                <div className='py-4 px-3'>
                    <Header title='FREQUENTLY ASKED QUESTIONS' />
                </div>
                <div className='w-full px-3 py-4 lg:py-6'>
                    {_questions && _questions.map((item) => (
                        <Collapse
                            ghost
                            style={{
                                backgroundColor: 'white',
                                borderRadius: 0,
                                borderBottom: `1px solid #00000004`,
                                padding: lg || xl || xxl ? '12px 10px' : '3px 3px'
                            }}
                            items={[
                                {
                                    key: item?.key,
                                    label: <h1 className='font-semibold'>{item?.title}</h1>,
                                    children: <p>{item?.description}</p>,
                                },
                            ]}
                        />
                    ))}
                </div>
                <div className='py-4 px-3'>
                    <H3 title='HOW CAN WE HELP YOU?' />
                </div>
                <div className='w-full flex flex-col items-start gap-4 py-8 px-3'>
                    <div className='w-full lg:w-1/2 xl:w-[35%] flex items-center justify-between gap-2'>
                        <FormHeading title='Purpose *' />
                        <div className='w-3/4'>
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Cascader: {
                                            dropdownHeight: 120,
                                            controlItemWidth: 180,
                                            controlWidth: 200
                                        },
                                    },
                                }}
                            >
                                <Form form={form}>
                                    <Form.Item
                                        name="purpose"
                                        rules={[{ required: true, message: 'Please select purpose and subpurpose!' }]}
                                    >
                                        <Cascader
                                            size='large'
                                            placeholder='Select Purpose'
                                            style={{ width: '100%', height: 'fit-content' }}
                                            options={options}
                                            loadData={loadData}
                                            changeOnSelect  
                                        />
                                    </Form.Item>
                                </Form>
                            </ConfigProvider>   
                        </div>
                    </div>
                    <div className='w-full lg:w-1/2 xl:w-[35%] flex items-center justify-between gap-2'>
                        <FormHeading title='Message *' />
                        <div className='w-3/4'>
                            <TextArea
                                size='large'
                                maxLength={100}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        message: e?.target?.value
                                    });
                                }}
                                placeholder="Message"
                                value={formData?.message}   
                                style={{ height: 120, resize: 'none' }}
                            />
                        </div>
                    </div>
                    <div className='w-full lg:w-[83%] mx-auto flex flex-col items-start justify-between gap-2'>
                        {image && image.map((file, idx) => (
                            <div key={idx} className='flex items-center justify-between w-[33%] px-3 py-2 hover:bg-[#F1F6FF] group'>
                                <div className='flex items-center gap-3'>
                                    <DocumentIcon color={themeColors.icons.primary} />
                                    <H6 title={file?.name} />
                                </div>
                                <div className='opacity-0 group-hover:opacity-100 '>
                                    <Button
                                        onClick={() => removeDocAtIndex(idx)}
                                        shape='circle'
                                        icon={<CloseOutlined style={{ color: themeColors.icons.secondary }} />}
                                        type="text"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='w-full lg:w-1/2 xl:w-[35%] flex items-center justify-end gap-2'>
                        <div className='w-full lg:w-3/4 flex items-center justify-end'>
                            <Button
                                onClick={() => inputRef.current.input.click()}
                                type="link"
                                style={{
                                    display: 'none'
                                }}
                            >
                                Attach
                            </Button>
                            <Button
                                type="primary"
                                onClick={askQuery}
                            >
                                Send
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <Input
                ref={inputRef}
                type='file'
                multiple
                accept="application/pdf"
                style={{ display: "none" }}
                onChange={(event) => {
                    const newDocs = Array.from(event.target.files);
                    setImage((prevDocs) => {
                        const uniqueDocs = newDocs.filter((newDoc) => {
                            return !prevDocs.some((prevDoc) => prevDoc.name === newDoc.name);
                        });
                        return [...prevDocs, ...uniqueDocs];
                    });
                }}
            />
        </>
    );
};

export default HelpScreen;
