import React, { useState } from 'react';
import { Button, Input, Select, Modal } from 'antd';
import { EmmailIcon, LocationIcon, PeopleIcon, RoleIcon } from './Icons';
import { useTheme } from '../Themes/ThemeProvider';
import CountrySelectComponent from './CountrySelectComponent';
import { post } from '../Services/api-services';
import { useRecoilState } from 'recoil';
import { userAuthState } from '../Services/RecoilService';
import MobileCountryComponent from './MobileCountryComponent';

const { Option } = Select;

const inputStyle = { fontSize: '14px' }

const EditUserDialogue = ({ open, setOpen, updateUser, selectedCountry, setSelectedCountry, isSelected, setIsSelected, currentUser, setCurrentUser, addUser }) => {
    const { themeColors } = useTheme();

    // FORM LABEL HEADING 
    const FormHeading = ({ title }) => (
        <h6 className={`text-xs lg:text-[15px] ${themeColors.headings.primary}`}>{title}</h6>
    )

    const modalContentStyle = {
        // backgroundColor: 'red',
        padding: '0',
        // maxWidth: '30vw'
    };

    // UPDATE MOBILE 
    const updateData = (e) => {
        setCurrentUser({
            ...currentUser,
            mobile: e?.target?.value
        });
    }

    return (
        <>
            <Modal
                title={<h1>Edit User Details</h1>}
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                closable={false}
                style={modalContentStyle}   
                footer={
                    <div className={`w-full flex items-center justify-between pt-6 `}>
                        <Button onClick={() => setOpen(false)} size='middle' style={{ backgroundColor: themeColors.bg.backButton, color: themeColors.bg.inviteButton }} type="primary">Back</Button>
                        <Button onClick={() => updateUser()} style={{ backgroundColor: themeColors.bg.inviteButton }} size='middle' type="primary">Save</Button>
                    </div>
                }
                header={null}
                width={800}
            >
                <div className='w-full flex flex-col lg:flex-row items-start lg:items-start gap-3 lg:gap-6 py-6 flex-wrap'>

                    {/* NAME FIELD  */}
                    <div className='w-full  flex flex-col items-start gap-2'>
                        <FormHeading title='Name' />
                        <div className='w-full'>
                            <Input
                                size='large'
                                style={inputStyle}
                                placeholder="Name"
                                value={currentUser?.name}
                                onChange={(e) => {
                                    setCurrentUser({
                                        ...currentUser,
                                        name: e?.target?.value
                                    })
                                }}
                            />
                        </div>
                    </div>  


                    {/* PHONE FIELD  */}
                    <div className='w-full  flex flex-col items-start gap-2'>
                        <FormHeading title='Phone' />
                        <div className='w-full'>
                            <MobileCountryComponent
                                selectedCountry={selectedCountry}
                                setSelectedCountry={setSelectedCountry}
                                mobile={currentUser?.mobile ? currentUser?.mobile : ''}
                                updateData={(e) => updateData(e)}
                                isSelected={isSelected}
                                setIsSelected={setIsSelected}
                                profile={true}
                            // methods={methods?.methods} 
                            />
                        </div>
                    </div>

                    {/* EMAIL FIELD  */}
                    <div className='w-full  flex flex-col items-start gap-2'>
                        <FormHeading title='Email' />
                        <div className='w-full'>
                            <Input
                                size='large'
                                style={inputStyle}
                                placeholder="Email"
                                type='Email'
                                value={currentUser?.email}
                                readOnly
                                disabled
                                onChange={(e) => {
                                    setCurrentUser({
                                        ...currentUser,
                                        email: e?.target?.value
                                    })
                                }}
                            />
                        </div>
                    </div>
                    {isSelected &&
                        <div
                            onClick={() => {
                                setIsSelected(false)
                            }}
                            className='w-full  bg-transparent h-full absolute  z-0 '>
                        </div>
                    }
                </div>
            </Modal>
        </>
    );
};
export default EditUserDialogue;