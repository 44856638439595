import { createSlice } from "@reduxjs/toolkit";

// Reducer.js
const initialState = {
    title: '',
    content: '',
    documents: [],
    signers: [],
    myMethods: {
        draw: true,
        email: false,
        mobile: false,
        phone: true
    },
    status: '',
    isWriting: false
}

export const contentReducer = createSlice({
    name: 'contentOption',
    initialState,
    reducers: {
        updateTitle: (state, action) => {
            state.title = action.payload
        },
        updateContent: (state, action) => {
            state.content = action.payload
        },
        updateMethods: (state, action) => {
            state.myMethods = action.payload
        },
        updateIsWriting: (state, action) => {
            state.isWriting = action.payload
        },
        updateStatus: (state, action) => {
            state.status = action.payload
        },
        updateDoc: (state, action) => {
            const newDocuments = action.payload;
            const existingDocuments = state.documents;
            // const existingDocuments = state.documents.filter(doc => Object.keys(doc).length !== 0); // Remove blank objects
            // console.log(existingDocuments);
            // console.log(newDocuments); 
            // Check if the new documents contain any duplicates with existing documents
            const filteredNewDocuments = newDocuments.filter(newDoc => {
                return !existingDocuments.some(existingDoc => {
                    // Compare the objects based on your criteria for equality
                    // For example, if the objects have an 'id' property, you can compare based on that
                    return newDoc.name === existingDoc.name;
                });
            });

            // Merge the filtered new documents with existing documents
            const updatedDocuments = [...existingDocuments, ...filteredNewDocuments];
            // console.log(updatedDocuments);

            // Update the state with the updated documents
            state.documents = updatedDocuments;
        },
        removeAllSigners: (state, action) => {
            state.signers = action.payload
        },
        updateSigners: (state, action) => {
            const newSigners = action.payload;
            let existingSigners = state.signers;
            if (existingSigners === undefined) {
                existingSigners = [];
            }
            const isAvailable = existingSigners.some(existingSigner => {
                return newSigners?.email === existingSigner?.email
            })
            if (!isAvailable) {
                // Convert newSigners to an array if it's not already one
                const newSignersArray = Array.isArray(newSigners) ? newSigners : [newSigners];
                // Merge new signers with existing signers
                const updatedSigners = [...existingSigners, ...newSignersArray];

                // Update the state with the merged signers
                state.signers = updatedSigners;
                state.status = 'success';
            } else {
                state.status = 'error';
            }
        },
        removeSigners: (state, action) => {
            const indexToRemove = action.payload;
            const existingSigners = state.signers;

            // Make a copy of the existing signers array
            const updatedSigners = [...existingSigners];

            // Remove the signer at the specified index
            updatedSigners.splice(indexToRemove, 1);

            // Update the state with the modified signers array
            state.signers = updatedSigners;
        },
    },
})

export const { updateContent, updateTitle, updateIsWriting, updateDoc, updateStatus, updateSigners, removeAllSigners, removeSigners, updateMethods } = contentReducer.actions

export default contentReducer.reducer

