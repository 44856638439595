// ThemeContext.js
import React, { createContext, useContext, useState } from 'react';
import { lightThemeColors, darkThemeColors } from './theme'; // Assuming you have a theme.js file

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState('light'); // Default theme

    const toggleTheme = () => {
        setTheme(prevTheme => (prevTheme === 'light' ? 'dark' : 'light'));
    };

    const themeColors = theme === 'light' ? lightThemeColors : darkThemeColors;

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme, themeColors }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);
