import React, { useEffect } from 'react'
import { CreateDocIcon, DocumentIcon, UploadDocIcon } from '../Components/Icons';
import SelectButton from '../Components/SelectButton';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Progress, message } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { updateContent, updateDoc, updateIsWriting, updateTitle } from '../store/Reducers/contentReducer';
import { AnimateKeyframes } from "react-simple-animate";
import { Animate } from "react-simple-animate";
import { motion } from "framer-motion";
import { gsap } from "gsap";
import WriteDoc from './WriteDoc';
import { useDimensions } from '../DimensionContext/DimensionContext';
import { get, hasMeaningfulContent, patch, post } from '../Services/api-services';
import { userAuthState } from '../Services/RecoilService';
import { useRecoilState } from 'recoil';
import { useTheme } from '../Themes/ThemeProvider';
import ConfirmCancelDialogue from '../Components/ConfirmCancelDialogue';
import CreateHeader from '../Components/CreateHeader';
import { H5 } from '../Themes/StylingComponent';


// CREATE DOC PAYLOAD FORMAT
const createDocPayloadData = {
    document:
    {
        title: '',
        content: '',
        createdById: '',
        teamId: '',
    }
    ,
    attachments: []
}

const updateDocPayloadData = {
    document: {
        title: '',
        content: '',
    },
    documentId: '',
    attachments: [],
    deletedAttachments: []
}

const SelectOption = () => {
    const { themeColors } = useTheme();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { xs, sm, lg, xl, xxl } = useDimensions();
    const inputRef = React.useRef(null);
    const [doc, setDocs] = React.useState([]);
    const [availableDocs, setAvailableDocs] = React.useState([]);
    const [deletedDocs, setDeletedDocs] = React.useState([]);
    const { title, content, documents, isWriting } = useSelector(state => state.contentOption);
    const [createDocData, setCreateDocData] = React.useState({ ...createDocPayloadData })
    const [updateDocData, setUpdateDocData] = React.useState({ ...updateDocPayloadData })
    const [authState, setAuthState] = useRecoilState(userAuthState)
    const _documentid = location.pathname.split('/')[3]
    const [open, setOpen] = React.useState(false)
    const [currentTeamData, setCurrentTeamData] = React.useState('')
    const [isUpload, setIsUpload] = React.useState(true)
    const [dataLoading, setDataLoading] = React.useState(true)
    const [docCount, setDocCount] = React.useState(0)
    const [totalDocumentFileSize, setTotalDocumentFileSize] = React.useState(0)
    const [isLoading, setIsLoading] = React.useState(false)
    const [uploadPer, setUploadPer] = React.useState(0)
    const [progress, setProgress] = React.useState(0);
    const [messageContent, setMessageContent] = React.useState('')

    // REMOVE DOCUMENTS 
    const removeDocAtIndex = (indexToRemove) => {
        const selectedValue = doc[indexToRemove]
        if (selectedValue?.fileName) {
            setDeletedDocs([
                ...deletedDocs,
                selectedValue?._id
            ])
        }
        setDocs((prevDocUrl) =>
            prevDocUrl.filter((_, index) => index !== indexToRemove)
        );
        if (inputRef.current) {
            inputRef.current.value = null;
        }
    };

    // ANIMATION CODE 
    const [play, setPlay] = React.useState(false)

    const card1 = React.useRef(null);
    const card2 = React.useRef(null);
    const mainContainer = React.useRef(null);
    const timeline = React.useRef(null);

    React.useEffect(() => {
        if (play && !isWriting) {
            timeline.current = gsap.timeline({
                onComplete: () => {
                    // navigate('#write');
                    dispatch(updateIsWriting(true))
                }
            });

            timeline.current
                .to(
                    card1.current,
                    {
                        width: '100%',
                        height: (xs || sm) ? '75vh' : (lg) ? '95vh' : '82vh',
                        duration: 0.25,
                    },
                )
        }
    }, [play]);

    // START ANIMATION 
    const handleClick = () => {
        setPlay(!play);
    };

    // UPDATE  CREATE DOCUMENT DATA 
    React.useEffect(() => {
        if (!location?.pathname?.includes(_documentid)) {
            updateCreateDocData()
        }
    }, [title, content])

    const updateCreateDocData = () => {
        setCreateDocData({
            ...createDocData,
            document: {
                title: title,
                content: content,
                createdById: authState?.id,
                teamId: authState?.teamId
            }
        });
    };
    
    // CREATE A DOCUMENT 
    const createDocument = async () => {
        try {
            setIsLoading(true)
            if (totalDocumentFileSize > 8) {
                message?.error('Documents Size can not more than 8 MB Please Compressed your files!')
                setIsLoading(false)
                return
            }
            if (currentTeamData?.plan) {
                // Convert the provided timestamp to a Date object
                const endValidityDate = new Date(currentTeamData?.plan?.endValidity);

                // Get the current date and time
                const currentDate = new Date();
                if (endValidityDate < currentDate) {
                    // setIsMobile(false)
                    message.error('Your current plan has expired. Please upgrade your plan!')
                    setIsLoading(false)
                    return
                } else if (docCount <= 0) {
                    message.error('Maximum limit exceed Please upgrade the plan to add more');
                    setIsLoading(false)
                    return
                }
            } else {
                message.error('You do not have any active plan please purchase a plan!')
                setIsLoading(false)
                return
            }

            let docPayload = { ...createDocData }
            // UPLOAD DOCUMENTS
            const files = doc;
            if (files.length > 0) {
                try {

                    let formData = new FormData();
                    for (let i = 0; i < files.length; i++) {
                        formData.append('files', files[i]);
                    }
                    // const response = await post('document/upload', formData);
                    const response = await post(`document/upload/${authState?.teamId}`, formData, {
                        onUploadProgress: (progressEvent) => {
                            const { loaded, total } = progressEvent;
                            const percentCompleted = Math.round((loaded * 100) / total);
                            setProgress(percentCompleted);
                        }
                    });
                    if (response?.statusCode === 200) {
                        // Handle successful upload 
                        setCreateDocData({
                            ...createDocData,
                            attachments: response?.data
                        });

                        // Now, make the API call to create the new document 
                        docPayload = {
                            ...createDocData,
                            attachments: response?.data
                        };
                    } else {
                        message.error(response?.message)
                        setIsLoading(false)
                        return
                    }
                } catch (e) {
                    console.log(`ERROR WHILE UPLOADNG`);
                    // message.error(e);
                    return
                }
            }

            const docResponse = await post('document/createNewDocument', docPayload);
            setIsLoading(false)
            if (docResponse.statusCode === 200) {
                message.success('Document Created successfully!');
                const _id = docResponse.data?.document?.insertedId;
                navigate(`/document/${_id}`, { replace: true })
                dispatch(updateTitle(''))
                dispatch(updateContent(''))
            } else {
                message.error(docResponse?.message)
            }

        } catch (e) {
            // Handle error
            message.error('Error while uploading files!');
            console.log(`ERROR WHILE CREATING DOCUMENT ${e}`);
            message.error(e)
        }
    };

    // UPDATE  UPDATE DOCUMENT DATA 
    React.useEffect(() => {
        if (location?.pathname?.includes(_documentid)) {
            updateUpdateDocData()
        }
    }, [title, content])

    const updateUpdateDocData = () => {
        setUpdateDocData({
            ...updateDocData,
            document: {
                title: title,
                content: content,
            },
            documentId: _documentid
        });
    };

    const updateDocument = async () => {
        try {
            setIsLoading(true)
            if (totalDocumentFileSize > 8) {
                message?.error('Documents Size can not more than 8 MB Please Compressed your files!')
                setIsLoading(false)
                return
            }
            if (currentTeamData?.plan) {
                // Convert the provided timestamp to a Date object
                const endValidityDate = new Date(currentTeamData?.plan?.endValidity);

                // Get the current date and time
                const currentDate = new Date();
                if (endValidityDate < currentDate) {
                    // setIsMobile(false)
                    message.error('Your current plan has expired. Please upgrade your plan!')
                    setIsLoading(false)
                    return
                } else if (docCount <= 0) {
                    message.error('Maximum limit exceed Please upgrade the plan to add more');
                    setIsLoading(false)
                    return
                }
            } else {
                message.error('You do not have any active plan please purchase a plan!')
                setIsLoading(false)
                return
            }

            // UPLOAD DOCUMENTS
            let docPayload = { ...updateDocData, deletedAttachments: deletedDocs }  
            const files = doc;
            if (files.length > 0) {
                try {
                    let formData = new FormData();
                    for (let i = 0; i < files.length; i++) {
                        if (!files[i]?.fileName) {
                            formData.append('files', files[i]);
                        }
                    }
                    if (formData?.has('files')) {
                        const response = await post(`document/upload/${authState?.teamId}`, formData);
                        if (response?.statusCode === 200) {
                            // Handle successful upload 
                            setUpdateDocData({
                                ...updateDocData,
                                attachments: response?.data
                            });

                            // // Now, make the API call to create the new document 
                            // docPayload = {
                            //     ...updateDocData,
                            //     attachments: response?.data
                            // }; 
                            // Now, make the API call to create the new document 
                            docPayload = {
                                ...updateDocData,
                                attachments: response?.data,
                                deletedAttachments: deletedDocs,
                                teamId: authState?.teamId
                            };
                        } else {
                            message.error(response?.message)
                            setIsLoading(false)
                            return
                        }
                    } else {
                        docPayload = {
                            ...updateDocData,
                            attachments: [],
                            deletedAttachments: deletedDocs,
                            teamId: authState?.teamId
                        };
                    }
                } catch (e) {
                    console.log(`ERROR WHILE UPLOADING ${e}`);
                    setIsLoading(false)
                    return
                }
            }

            const documentResponse = await post('document/updateDocument', docPayload)
            setIsLoading(false)
            if (documentResponse.statusCode === 200) {
                message.success('Document Updated successfully!');
                navigate(`/document/${_documentid}`)
                dispatch(updateTitle(''))
                dispatch(updateContent(''))
            } else if (documentResponse?.statusCode == 438) {
                navigate(`/document/${_documentid}`)
            } else {
                message.error(documentResponse?.message)
            }
        } catch (e) {
            console.log(`ERROR WHILE UPDATING DOCUMENT ${e}`);
        }
    }

    // CLEAR DOCUMENT 
    const onClearData = () => {
        if (location?.pathname?.includes(_documentid)) {
            dispatch(updateContent(''))
        } else {
            dispatch(updateIsWriting(false))
            setPlay(false)
            // navigate('/create-doc/options')
            dispatch(updateContent(''))
            if (timeline.current) {
                timeline.current.reverse();
            }
        }
    }

    // GET CURRENT DOCUMENT DATA 
    useEffect(() => {
        if (_documentid) {
            getDocumentData();
        }
        getTeamData();
    }, [])

    const getDocumentData = async () => {
        try {
            const response = await get(`document/${_documentid}/documentWithAttachment`)
            if (response.statusCode == 200 && !response?.data?.trash) {
                dispatch(updateTitle(response?.data?.title))
                dispatch(updateContent(response?.data?.content))
                setDocs(response?.data?.attachments)
                setAvailableDocs(response?.data?.attachments)
            } else {
                navigate('/home/dashboard')
                dispatch(updateTitle(''))
                dispatch(updateContent(''))
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING DOCUMENT ${e}`);
        }
    }

    // CANCEL DOCUMENT S
    const oncancel = () => {
        console.log('heyy');
        dispatch(updateTitle(''))
        dispatch(updateContent(''))
        dispatch(updateIsWriting(false))
        // dispatch(updateDocument([]))
        //    dispatch
        // navigate('/home/dashboard', { replace: true     })
        // navigate(-2)
        setOpen(false)
        navigate('/home/dashboard')
    }

    // GET CURRENT TEAM DATA 
    const getTeamData = async () => {
        try {
            // API CHANGE KRNA HAI 
            // const response = await get(`team/${authState?.teamId}`);
            const response = await get(`team/${authState?.teamId}/document?skip=1&limit=20`);
            if (response?.statusCode == 200) {
                setCurrentTeamData(response?.data?.team)
                setDocCount(response?.data?.docCount)
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING CURRENT PLAN ${e}`);
        }
    }

    // CHECK PLAN VALIDATIONS 
    useEffect(() => {
        if (currentTeamData) {
            if (currentTeamData?.plan) {
                // Convert the provided timestamp to a Date object
                const endValidityDate = new Date(currentTeamData?.plan?.endValidity);

                // Get the current date and time
                const currentDate = new Date();
                if (endValidityDate < currentDate) {
                    // setIsMobile(false)
                    setIsUpload(false)
                    setDataLoading(false)
                    setMessageContent('Your current plan has expired. Please upgrade your plan!')
                } else {
                    if (currentTeamData?.plan?.fileUpload) {
                        let currentUsedStorage = currentTeamData?.plan?.drive;
                        let totalStorage = currentTeamData?.plan?.storage
                        if (currentUsedStorage >= totalStorage) {
                            setIsUpload(false)
                            setMessageContent('Drive size full!')
                        } else {
                            setIsUpload(true)
                        }
                        setDataLoading(false)
                    } else {
                        setIsUpload(false)
                        setDataLoading(false)
                        setMessageContent(`You don not have upload document feature in you'r current plan please upgrade you'r plan to upload files!`)
                    }
                }
            } else {
                setIsUpload(false)
                setDataLoading(false)
                setMessageContent('You do not have any active plan please purchase a plan to continue!')
            }
        }
        // console.log(currentTeamData?.plan?.drive < currentTeamData?.plan?.storage);
    }, [currentTeamData])

    React.useEffect(() => {
        let _size = 0
        let totalSizeInMb = 0
        doc?.map((elm, idx) => {
            _size += elm?.size
            return null; // Explicit return statement
        });
        // console.log(_size / (1024 * 1024));
        totalSizeInMb = _size / (1024 * 1024)
        setTotalDocumentFileSize(totalSizeInMb)
    }, [doc]);

    return (
        <>
            {dataLoading ?
                <div className='w-full flex-1 flex items-center justify-center'>
                    <LoadingOutlined style={{ fontSize: 24 }} spin />
                </div>
                :
                <>
                    <div className='w-full relative flex-1 flex flex-col items-center gap-3  py-6 px-3 justify-start '>
                        {/* OPTIONS  */}
                        <CreateHeader
                            isSigned={false}
                        />
                        <div className='w-full  mx-auto max-w-[1000px] '>
                            <div className='w-full relative flex flex-col flex-wrap justify-between gap-3 lg:flex-row lg:gap-6 '>
                                {/* CREATE FILE  */}
                                <div ref={card1} onClick={() => handleClick()} className={`${isWriting || location.pathname.includes(_documentid) ? 'w-full' : 'w-full lg:w-[48%]'}`} >

                                    {/* TEXT EDITOR  */}
                                    {isWriting || location.pathname.includes(_documentid) ?
                                        <div
                                            style={{
                                                height: isWriting || location.pathname.includes(_documentid) ? (xs || sm) ? '75vh' : (lg) ? '90vh' : '85vh' : 0,
                                            }}
                                            ref={mainContainer} className={`overflow-hidden   w-full `}>
                                            <WriteDoc
                                                onClearData={onClearData}
                                            />
                                        </div>
                                        :
                                        <SelectButton
                                            title='Write Yourself'
                                            isUpload={true}
                                            desc={`Create a digital contract document by typing or pasting in a rich text editor.`}
                                            icon={<CreateDocIcon color={themeColors.icons.primary} />}
                                        />
                                    }
                                </div>
                                <div ref={card2} className={`w-full lg:w-[48%] top-[0%]`}>
                                    <SelectButton
                                        doc={doc}
                                        // isUpload={currentTeamData?.plan?.fileUpload ? true : false}
                                        isUpload={isUpload}
                                        messageContent={messageContent}
                                        inputRef={inputRef}
                                        setDocs={setDocs}
                                        desc={`Select a PDF document from your device to upload.`}
                                        title='Upload Document'
                                        icon={<UploadDocIcon color={isUpload ? themeColors.icons.primary : themeColors.icons.secondary} />}
                                    />
                                </div>
                            </div>
                            {/* bg-[#F5F5F5] */}
                            {/* SELECTED DOCUMENT LIST  */}
                            <div className='w-full px-2 py-4 flex flex-col items-start gap-3   '>
                                {doc?.map((file, idx) => (
                                    <div key={idx} className='flex items-center justify-between w-full px-3 py-2 hover:bg-[#F1F6FF] group'>
                                        <div className='flex items-center gap-3'>
                                            <DocumentIcon color={themeColors.icons.primary} />
                                            <H5
                                                title={file?.name?.length > 20 ? file?.name?.substring(0, 20) + '...' : file?.name}
                                            />
                                        </div>
                                        <div className='opacity-0 group-hover:opacity-100 '>
                                            <Button
                                                onClick={() => removeDocAtIndex(idx)}
                                                shape='circle'
                                                icon={<CloseOutlined style={{ color: themeColors.icons.secondary }} />}
                                                type="text"
                                            >
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* BUTTONS  */}
                            <div className={`w-full flex  ${isWriting || location?.pathname?.includes(_documentid) ? 'justify-between items-center' : 'justify-end items-end'} py-6`}>
                                {isWriting || location?.pathname?.includes(_documentid) ?
                                    <Button
                                        onClick={() => setOpen(true)}
                                        size='large'
                                        style={{ backgroundColor: themeColors.bg.backButton, color: themeColors.bg.inviteButton }}
                                    // type="primary"
                                    >
                                        Cancel
                                    </Button>
                                    : null}

                                {location?.pathname?.includes(_documentid) ?
                                    <Button
                                        onClick={updateDocument}
                                        disabled={!((title?.length > 0 && hasMeaningfulContent(content)) || (title?.length > 0 && doc?.length > 0))}
                                        size='large'
                                        // style={{ backgroundColor: themeColors.bg.inviteButton }}
                                        type="primary"
                                        loading={isLoading}
                                    >
                                        Save
                                    </Button>
                                    :
                                    <Button
                                        onClick={createDocument}
                                        disabled={!((title?.length > 0 && content?.length > 0) || (title?.length > 0 && doc?.length > 0))}
                                        size='large'
                                        // style={{ backgroundColor: theme.palette.bg.inviteButton }}
                                        type="primary"
                                        loading={isLoading}
                                    >
                                        Create
                                    </Button>
                                }
                            </div>
                        </div>
                    </div >
                    <ConfirmCancelDialogue
                        open={open}
                        setOpen={setOpen}
                        onDiscard={() => oncancel()}
                    />
                </>

            }


        </>
    )
}

export default SelectOption

