import React from 'react'
import { Button } from 'antd';
import { DrawIcon } from './Icons';
import { useTheme } from '../Themes/ThemeProvider';

const OptionComponent = ({ title, text, icons, handleClick }) => {
    const { themeColors } = useTheme();

    return (
        <>
            <div className='w-full flex border-b border-b-[#EBEBEB] py-2 items-center justify-between'>
                <div className='max-w-[60%] flex flex-col items-start gap-2'>
                    <h6 className={`text-base font-normal capitalize ${themeColors.headings.primary}`} >
                        {title}
                    </h6>
                    <p className={`text-[10px] ${themeColors.text.fourth}`} >
                        {text}
                    </p>
                </div>

                <Button
                    style={{
                        borderRadius: 3,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: title === 'draw' ? themeColors.bg.inviteButton : themeColors.bg.backButton,
                        color: title === 'draw' ? themeColors.icons.createDoc : themeColors.bg.inviteButton,
                        fontSize: '12px'
                    }}
                    icon={icons}
                    type="primary"
                    size='large'
                    onClick={() => handleClick(title)}
                >
                    Sign Now
                </Button>
            </div>
        </>
    )
}

export default OptionComponent
