import React, { useEffect } from 'react'
import { Switch } from 'antd';
import { useTheme } from '../Themes/ThemeProvider';

const textStyle = 'text-sm lg:text-base  font-normal';

const SignVIa = ({ methods, setMethods, currentTeamData, isSigned, updateMethod }) => {
    const { themeColors } = useTheme();
    const [isMobile, setIsMobile] = React.useState(true)

    const onChange = (checked, type) => {
        setMethods({
            ...methods,
            [type]: checked
        });
    };


    // CHECK PLAN VALIDATIONS 
    useEffect(() => {
        if (currentTeamData?.plan) {
            // Convert the provided timestamp to a Date object
            const endValidityDate = new Date(currentTeamData?.plan?.endValidity);

            // Get the current date and time
            const currentDate = new Date();
            if (endValidityDate < currentDate) {
                // console.log('heyy1');
                setIsMobile(false)
            } else {
                if (!currentTeamData?.plan?.mobileSign) {
                    setIsMobile(false)
                    // console.log('heyy2');
                }
            }
        } else {
            setIsMobile(false)
            // console.log('heyeyye33');
        }
    }, [currentTeamData])


    return (
        <>
            <div
                style={{
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 0px 4px',
                }}
                className={`w-full my-4 py-4 rounded ${themeColors.bg.primary}`}>
                <div className='py-2 pb-6 px-3'>
                    <h2 style={{ color: themeColors.headings.primary }} className='text-base lg:text-xl font-semibold'>SIGN VIA</h2>
                </div>
                <div className='w-full px-3 flex flex-col lg:flex-row items-start gap-3 lg:gap-16'>
                    <div className='w-full lg:w-fit flex items-center justify-between gap-0 lg:gap-6'>
                        <h6 style={{ color: themeColors.headings.primary }} className={textStyle}>Draw</h6>
                        <Switch
                            size='large'
                            // disabled={isSigned}
                            checked={methods?.draw}
                            onChange={(checked) => updateMethod(checked, 'draw')}
                        />
                    </div>
                    <div className='w-full lg:w-fit flex items-center justify-between gap-0 lg:gap-6'>
                        <h6 style={{ color: themeColors.headings.primary }} className={textStyle}>Email</h6>
                        <Switch
                            size='large'
                            // disabled={isSigned}
                            value={methods?.email}
                            onChange={(checked) => updateMethod(checked, 'email')}
                        />
                    </div>
                    {/* {currentTeamData?.plan?.mobileSign ?
                        <div className='w-full  lg:w-fit flex items-center justify-between gap-0 lg:gap-6'>
                            <h6 style={{ color: themeColors.headings.primary }} className={textStyle}>Phone Number</h6>
                            <Switch 
                                size='large'
                                // disabled={isSigned}
                                value={methods?.mobile}
                                onChange={(checked) => updateMethod(checked, 'mobile')}
                            />
                        </div>
                        : null} */}
                </div>
            </div>
        </>
    )
}

export default SignVIa
