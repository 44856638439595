import React from 'react'
import SettingDrawer from '../Components/SettingDrawer'
import { useDimensions } from "../DimensionContext/DimensionContext";
import { Input, Button, Select, theme, message, Skeleton } from "antd";
import Header from '../Components/Header';
import { useTheme } from '../Themes/ThemeProvider';
import { allowedImageTypes, BASEURL, get, patch, post } from '../Services/api-services';
import { useRecoilState } from 'recoil';
import { userAuthState } from '../Services/RecoilService';
import { useEffect } from 'react';
import { FormHeading, H4, H5, H6, P } from '../Themes/StylingComponent';
import UploadButton from '../Components/UploadButton';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import EditUserTeamDialogue from '../Components/EditUserTeamDetails';
import { useNavigate } from 'react-router-dom';
import { RightArrowIcon } from '../Components/Icons';
import AddTeamDialogue from '../Components/AddTeamDialogue';
import { TeamSkaleton } from './DocumentSkaleton';
import Loader from './Loader';


const payloadData = {
    name: '',
    logo: '',
    country: {}
}

const _img = require('../assets/Images/teamLogo.png')


const ManageTeam = () => {
    const { themeColors } = useTheme();
    const navigate = useNavigate();
    const { lg, xl, xxl } = useDimensions()
    const [authState, setAuthState] = useRecoilState(userAuthState)
    const [currentUser, setCurrentUser] = React.useState('')
    const [teams, setTeams] = React.useState([])
    const [open, setOpen] = React.useState(false)
    const [formData, setFormData] = React.useState({ ...payloadData });
    const [isSelected, setIsSelected] = React.useState(false)
    const [logoUrl, setLogoUrl] = React.useState('');
    const [dataLoading, setDataLoading] = React.useState(true)
    const [isChanging, setIChanging] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const teamLogo = require('../assets/Images/teamLogo.png')
    const [selectedCompanyCountry, setSelectedCompanyCountry] = React.useState({
        code: 'In',
        label: "India",
        phone: "91",
        phoneLength: 10,
        currency: {
            code: 'INR',
            symbol: '₹'
        }
    });

    const FormHeading = ({ title }) => (
        <P
            title={title}
            align='left'
        />
    )

    const FormContent = ({ title }) => (
        <H5
            title={title}
            align='left'
        />
    )

    // GET USER DETAILS 
    useEffect(() => {
        getUserDetails();
    }, [])

    const getUserDetails = async () => {
        try {
            const response = await get(`user/${authState?.id}`)
            if (response?.statusCode == 200) {
                setCurrentUser(response?.data)
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING USER DETAILS ${e}`);
        }
    }
    // GET USERS TEAM FUNCTION 
    useEffect(() => {
        getUserTeams();
    }, [])

    const getUserTeams = async () => {
        try {
            const response = await get(`user/${authState?.id}/team`)
            if (response?.statusCode == 200) {
                setTeams(response?.data)
                setDataLoading(false)
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHINF TEAMS ${e}`);
        }
    }

    // SET COUNTRY DATA 
    React.useEffect(() => {
        setCountryData()
    }, [selectedCompanyCountry, logoUrl])

    const setCountryData = () => {
        setFormData({
            ...formData,
            country: selectedCompanyCountry,
            logo: logoUrl
        })
    }

    const selectTeam = async (teamId) => {
        setIChanging(true)
        setAuthState(prevState => ({
            ...prevState,
            teamId: teamId,
        }));
        const userResponse = await get(`team/${teamId}/user/${authState?.id}`)
        if (userResponse?.statusCode == 200) {
            setAuthState(prevState => ({
                ...prevState,
                role: userResponse?.data?.role
            }));
            message?.success('team changed successfully!')
        }
        const res = await get(`team/${teamId}`);
        setIChanging(false)
        if (res?.statusCode === 200) {
            if (!res?.data?.country) {
                navigate('/mobile-signup');
            } else if (!res?.data?.plan) {
                navigate('/select-plan');
            }
        }
        // navigate('/home/dashboard')
    }

    // CREATE NEW  TEAM 
    const createTeam = async () => {
        let payload = { ...formData }
        if (logoUrl) {
            // Check file size
            if (logoUrl && (logoUrl.size / (1024 * 1024)) > 2) {
                message.error('Logo size cannot exceed 2 MB. Please select a smaller logo.');
                return;
            }

            // if (logoUrl && logoUrl?.type == 'image/gif') {
            //     message.error('can only upload image!')
            //     setLogoUrl('')
            //     return
            // }
            // Check MIME type to allow only PNG, JPEG, and BMP

            if (!allowedImageTypes.includes(logoUrl.type)) {
                message.error('Invalid file type. Please upload a PNG, JPEG, or BMP image.');
                setLogoUrl('');
                return;
            }
        }

        let teamLogoFileName;
        if (logoUrl) {
            // Create a new FormData object
            try {
                let formData = new FormData();
                formData.append('file', logoUrl);

                const response = await post('document/upload/logo', formData)
                if (response?.statusCode == 200) {
                    // setLogoUrl(response?.data?.fileName) 
                    teamLogoFileName = response?.data?.fileName
                } else {
                    message.error(response?.message)
                    return
                }
            } catch (e) {
                console.log(`ERROR WHLILE UPLOADING LOGO ${e}`);
                return
            }
            // navigate('/home/dashboard', { state: { formData } });    
        }
        if (teamLogoFileName) {
            payload.logo = teamLogoFileName
        }
        try {
            setIsLoading(true)
            const response = await post(`user/${authState?.id}/createTeam`, payload)
            setIsLoading(false)
            if (response?.statusCode == 200) {
                message.success(response?.data?.message);
                setAuthState(prevState => ({
                    ...prevState, // Keep the existing data
                    teamId: response?.data?.id // Add the new team ID
                }));
                navigate('/select-plan', { state: { data: 'setting' } });
            }
        } catch (e) {
            console.log(`ERROR WHILE CREATING TEAM ${e}`);
        }
    }

    return (
        <>
            <div className={`w-full  flex-1 overflow-y-auto py-4 px-3 lg:px-6 ${themeColors.bg.primary}`}>
                {!isChanging ?
                    <div className=' flex  flex-col items-start gap-3 py-4'>
                        <Header title='Manage Team' />
                    </div>
                    : null}
                <div className='w-full flex-1 flex  items-center justify-center gap-4 '>
                    <div className='w-full lg:w-1/2'>


                        {isChanging ? <Loader /> :

                            dataLoading ? <TeamSkaleton />
                                :
                                teams?.map((team, index) => (
                                    <div onClick={() => {
                                        selectTeam(team?._id);
                                    }}
                                        key={index} className={`flex items-center my-2 cursor-pointer  ${authState?.teamId === team?._id ? `${themeColors.bg.primary} shadow` : ''} justify-between py-6 w-full px-3 hover:bg-[#FFFFFF] hover:shadow rounded group`}>
                                        {/* {currentUser?.teamId} */}
                                        <div className='flex items-center gap-3'>
                                            <img
                                                className='w-12 rounded h-10 object-contain '
                                                src={team?.logo ? `${BASEURL}public/logos/${team?.logo}` : teamLogo}
                                                alt=""
                                                onError={(e) => { e.target.src = _img; }}
                                            />

                                            <div>
                                                <H6
                                                    title={team?.name?.length > 20 ? team?.name.substring(0, 20) + '...' : team?.name}
                                                    toolTipTitle={team?.name?.length > 20 ? team?.name : ''}
                                                />
                                                <P
                                                    title={<span>{team?.member} Members in this group</span>}
                                                />
                                            </div>
                                        </div>
                                        <div className={` ${authState?.teamId === team?._id ? 'opacity-100' : 'opacity-0'} group-hover:opacity-100`}>
                                            <RightArrowIcon />
                                        </div>
                                    </div>
                                ))
                        }

                        <div className=' h-16'>
                        </div>
                    </div>
                    {!isChanging ?
                        <div className='w-full py-6 px-4 fixed bottom-0 left-[16%]'>
                            {dataLoading ?
                                <Skeleton.Button active={true} size={'default'} shape={'default'} block={false} />
                                :
                                <Button
                                    styl={{ color: '#AAAAAA' }}
                                    icon={<PlusOutlined />}
                                    type="text"
                                    onClick={() => {
                                        setOpen(true)
                                    }}
                                >
                                    Create New Team
                                </Button>
                            }
                        </div>
                        : null}
                </div>
            </div>
            {open ?
                <AddTeamDialogue
                    open={open}
                    setOpen={setOpen}
                    selectedCountry={selectedCompanyCountry}
                    setSelectedCountry={setSelectedCompanyCountry}
                    formData={formData}
                    setFormData={setFormData}
                    setIsSelected={setIsSelected}
                    createTeam={createTeam}
                    isSelected={false}
                    isLoading={isLoading}
                    logoUrl={logoUrl}
                    setLogoUrl={setLogoUrl}
                />
                : null}
        </>
    )
}

export default ManageTeam 