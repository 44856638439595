import * as React from 'react'
import OptionHeader from '../Components/OptionHeader';
import DocumentRow from '../Components/DocumentRow';
import { get, patch } from '../Services/api-services';
import { userAuthState } from '../Services/RecoilService';
import { useRecoilState } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';
import { debounce } from "lodash"
import { useDispatch } from 'react-redux';
import { updateContent, updateDoc, updateIsWriting, updateTitle } from '../store/Reducers/contentReducer';
import { useTheme } from '../Themes/ThemeProvider';
import { Button, Pagination, Skeleton, Space, message } from 'antd';
import { H2, H4 } from '../Themes/StylingComponent';
import { DocumentSkaleton } from './DocumentSkaleton';

const Dashboard = () => {
    const { themeColors } = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const path = useLocation();
    const [authState, setAuthState] = useRecoilState(userAuthState)
    const [searchData, setSearchData] = React.useState(null)
    const [currentPlan, setCurrentPlan] = React.useState('')
    const [status, setStatus] = React.useState('all')
    const [isAllowed, setIsAllowed] = React.useState(true)
    const [messateContent, setMessateContent] = React.useState('')
    const [allDocuments, setAllDocuments] = React.useState(null)
    const [DocCount, setDocCount] = React.useState(0)
    const [count, setCount] = React.useState(0)
    const [dataLoading, setDataLoading] = React.useState(true)
    const [currentPage, setCurrentPage] = React.useState(0)

    // CHECK PLAN VALIDATIONS 
    React.useEffect(() => {
        if (currentPlan) {
            // Convert the provided timestamp to a Date object
            const endValidityDate = new Date(currentPlan?.endValidity);

            // Get the current date and time
            const currentDate = new Date();
            if (endValidityDate < currentDate) {
                // setIsMobile(false)
                setIsAllowed(false)
                setMessateContent('Your current plan has expired. Please upgrade your plan!')
            } else {
                if (DocCount <= 0 || (endValidityDate < currentDate)) {
                    setIsAllowed(false)
                    setMessateContent('Maximum limit exceed Please upgrade the plan to add more!')
                } else {
                    setIsAllowed(true)
                }
            }
        } else {
            setIsAllowed(false)
            setMessateContent(`Team doesn't have plan. Please upgrade the plan!`)
        }
    }, [currentPlan])

    React.useEffect(() => {
        if (path?.pathname?.includes('dashboard')) {
            dispatch(updateContent(''))
            dispatch(updateTitle(''))
            dispatch(updateDoc([]))
            dispatch(updateIsWriting(false))
        }
    }, [path])

    // GET CURRENT USER DETAILS 
    React.useEffect(() => {
        getAllDocuments(status, 1, 20);
    }, [])

    // GET ALL DOCUMENTS 
    const getAllDocuments = async (status, skip, limit, trash) => {
        try {
            setDataLoading(true)
            let queryParams
            if (trash) {
                queryParams = `?trash=true${skip ? `&skip=${skip}` : ''}${limit ? `&limit=${limit}` : ''} `
            } else {
                if (status && status != 'all') {
                    queryParams = `?status=${status}${skip ? `&skip=${skip}` : ''}${limit ? `&limit=${limit}` : ''} `
                } else {
                    queryParams = `${skip ? `?skip=${skip}` : ''}${limit ? `&limit=${limit}` : ''} `
                }
            }
            const response = await get(`team/${authState?.teamId}/document${queryParams}`);
            setDataLoading(false)
            // console.log(response);
            if (response?.statusCode == 200) {
                setAllDocuments(response?.data?.data)
                // setCount((response?.data?.count) / 10)  
                setCount(response?.data?.count)
                setDocCount(response?.data?.docCount)
                setCurrentPlan(response?.data?.team?.plan)
            }
        } catch (e) {
            console.log(`ERROR WHILE GETING ALL DOCUMENTS ${e}`);

        }
    }


    const getSearchDocuments = async (_data, status, skip, limit) => {
        try {
            let queryParams
            if (_data) {
                if (status == 'trash') {
                    queryParams = `${_data ? `?title=${_data}` : ''}&trash=true${skip ? `&skip=${skip}` : ''}${limit ? `&limit=${limit}` : ''}`
                } else {
                    queryParams = `${_data ? `?title=${_data}` : ''}${status && status !== 'all' ? `&status=${status}` : ''}${skip ? `&skip=${skip}` : ''}${limit ? `&limit=${limit}` : ''}`
                }
            } else {
                if (status == 'trash') {
                    queryParams = `?trash=true${skip ? `&skip=${skip}` : ''}${limit ? `&limit=${limit}` : ''}`
                } else {
                    queryParams = `${status && status !== 'all' ? `?status=${status}&` : '?'}${skip ? `skip=${skip}` : ''}${limit ? `&limit=${limit}` : ''}`
                }
            }
            const response = await get(`team/${authState?.teamId}/document${queryParams}`)

            if (response?.statusCode == 200) {
                setAllDocuments(response?.data?.data)
                setDocCount(response?.data?.docCount)
                // Calculate the count and store it in the count state
                // const count = response?.data?.count / 10;
                const count = response?.data?.count
                if (count % 1 !== 0) {
                    setCount(count + 1)
                } else {
                    setCount(count);
                }
            }
        } catch (e) {
            console.log(`ERROR WHILE GETING ALL DOCUMENTS ${e}`);

        }
    }

    const restoreDoc = async (_id) => {
        try {
            const _body = {
                trash: false
            }
            const response = await patch(`document/${_id}`, _body)
            if (response?.statusCode == 200) {
                message?.success('document restored successfully')
                getAllDocuments('', 1, 20, true)
            }
        } catch (e) {
            console.log(`ERROR WHILE RESTORING  DOCUMENT ${e}`);
        }
    }

    // SEARCH DOCUMENT BY NAME  
    React.useEffect(() => {
        if (searchData != null) {
            xfetchDocuments(searchData, status, 1, 20);
        }
    }, [searchData])
    const xfetchDocuments = React.useCallback(debounce((text, status, skip, limit) => getSearchDocuments(text, status, skip, limit), 300), []);


   
    return (
        <div className={`w-full  flex flex-col flex-1 p-0 lg:p-4  items-start ${themeColors.bg.dashbord}`}>
            <div className={`w-full shadow flex-1 flex flex-col  h-full px-0 lg:px-10 ${themeColors.bg.primary}`}>
                <div className='w-full'>
                    <OptionHeader
                        onTabChange={(status) => {
                            getAllDocuments(status, 1, 20);
                            setSearchData(null)
                        }}
                        searchData={searchData}
                        setSearchData={setSearchData}
                        status={status}
                        dataLoading={dataLoading}
                        setDataLoading={setDataLoading}
                        setStatus={setStatus}
                        getTrashData={(trash) => {
                            setSearchData('')
                            getAllDocuments('', 1, 20, trash);
                        }}
                    />
                </div>
                {/* SHOW DOCUMENTS  */}

                {dataLoading ? (
                    <DocumentSkaleton />
                ) :
                    allDocuments?.length > 0 ? (
                        <div className='w-full h-fit flex-1 px-4'>
                            <div className='w-full py-4'>
                                {/* DOCUMENT ROW */}
                                {allDocuments.map((document, index) => (
                                    <DocumentRow
                                        onClick={() => {
                                            if (!document?.trash) {
                                                navigate(`/document/edit/${document?._id}`)
                                            }
                                        }}
                                        document={document}
                                        key={index}
                                        restoreDoc={(_id) => restoreDoc(_id)}
                                    />
                                ))}
                            </div>
                        </div>
                    )
                        :
                        (status && status !== 'trash') ? (
                            <div className='w-full flex-1 flex flex-col gap-5 items-center justify-center'>
                                <H2 title='No Documents here' />
                                {searchData == null ?
                                    <Button
                                        onClick={() => {
                                            if (!isAllowed) {
                                                message.error(messateContent)
                                            } else {
                                                navigate('/create-doc/options')
                                            }
                                        }}
                                        size='middle'
                                        type="primary"
                                    >
                                        Create New
                                    </Button>
                                    : null}
                            </div>
                        ) :
                            <div className='w-full flex-1 flex flex-col gap-5 items-center justify-center'>
                                <H2 title='No Trashed Documents here' />
                            </div>
                }

                {/* PAGINATION  */}
                {!dataLoading && count && count >= 20 ?
                    <div className='w-full flex items-center justify-center py-4'>
                        <Pagination
                            // defaultCurrent={1}
                            defaultCurrent={1}
                            total={count}
                            pageSize={20}
                            current={currentPage}
                            showSizeChanger={false}
                            onChange={(page, pageSize) => {
                                // Calculate the count based on the page number and page size
                                const count = page;
                                setCurrentPage(page)
                                getAllDocuments(status, count, 20)
                            }}
                        />
                    </div>
                    : null}
            </div>
        </div>
    )
}

export default Dashboard
