import React from 'react'
import { CompletedIcon, DraftIcon, InprocessIcon } from '../Components/Icons';
import { useTheme } from '../Themes/ThemeProvider';
import { ReloadOutlined } from '@ant-design/icons';
import { Button, Skeleton, Tooltip } from 'antd';
import { H5, XS } from '../Themes/StylingComponent';
import { formatDateFunction, getDate, getDateYear } from '../Services/api-services';
import { useDimensions } from '../DimensionContext/DimensionContext';


const DocumentRow = ({ document, onClick, restoreDoc }) => {
    const { themeColors } = useTheme();
    const { lg, xl, xxl } = useDimensions();
    let truncatedTitle

    if (lg || xl || xxl) {
        truncatedTitle = document?.title.length > 50 ? document?.title.substring(0, 50) + '...' : document?.title;
    } else {
        truncatedTitle = document?.title.length > 20 ? document?.title.substring(0, 20) + '...' : document?.title;
    }
    return (
        <>
            {document ?
                <div onClick={onClick} className='flex cursor-pointer items-center justify-between py-3 lg:py-4'>
                    <div className='flex items-start gap-2 lg:gap-4'>
                        <Tooltip title={formatDateFunction(document?.updateAt)}>
                            <div className={`flex flex-col items-center justify-center py-1 rounded w-fit ${themeColors.bg.dashboardDate}`}>
                                <div className='px-4 '>
                                    <H5
                                        title={getDate(document?.createdAt)}
                                        font='semibold'
                                    />
                                </div>
                                {/* <h6 className={`whitespace-nowrap text-sm  lg:text-base  font-semibold ${themeColors.headings.primary}`}>{getDate(document?.updateAt)}</h6> */}
                                <span className={`w-full h-[2px] ${themeColors.bg.dateRow}`}></span>
                                <XS
                                    title={getDateYear(document?.createdAt)}
                                    color={themeColors?.text?.primary}
                                />
                                {/* <p className={`whitespace-nowrap text-xs mx-2 ${themeColors.text.primary}`}>{getDateYear(document?.updateAt)}</p> */}
                            </div>
                        </Tooltip>
                        <div>
                            <Tooltip title={document?.title} >
                                <H5
                                    title={truncatedTitle}
                                    font='medium'

                                />
                            </Tooltip>
                            {/* <h6 className={`text-sm lg:text-base font-medium ${themeColors?.headings?.primary}`}>{document?.title}</h6> */}
                            <XS
                                title={document?.signers?.length > 0 ? `${document?.signers?.[0]?.name?.length > 20 ? document?.signers?.[0]?.name?.substring(0, 20) + '...' : document?.signers?.[0]?.name} ${document?.signers?.length - 1 != 0 ? `+${document?.signers?.length - 1}` : ''}` : null}
                                color={themeColors?.text?.primary}
                            />
                            {/* <p className={`text-rtext  lg:text-xs py-0 lg:py-1 ${themeColors?.text?.primary}`}>{document?.signers?.length > 0 ? `${document?.signers?.[0]?.name} ${document?.signers?.length - 1 != 0 ? `+${document?.signers?.length - 1}` : ''}` : null}</p> */}
                        </div>
                    </div>

                    {document?.trash ?
                        <Tooltip placement="topRight" title={'restore'}>
                            <Button
                                onClick={() => restoreDoc(document?._id)}
                                icon={<ReloadOutlined />}
                                shape='circle'
                                type='text'
                            />
                        </Tooltip> :
                        <div className='flex w-1/5 lg:w-[7%] items-center gap-1'>
                            {document?.status === 'completed' ?
                                <>
                                    <CompletedIcon />
                                    <XS
                                        title={document?.status}
                                        font='semibold'
                                        capitalize='capitalize'
                                        color={themeColors?.text?.completed}
                                    />
                                </>
                                : document?.status === 'draft' ?
                                    <>
                                        <DraftIcon />
                                        <XS
                                            title={document?.status}
                                            font='semibold'
                                            capitalize='capitalize'
                                            color={themeColors?.text?.draft}
                                        />
                                    </>
                                    : document?.status === 'send' ?
                                        <>
                                            <InprocessIcon />
                                            <XS
                                                title={'In Process'}
                                                font='semibold'
                                                capitalize='capitalize'
                                                color={themeColors?.text?.send}
                                            />
                                        </>
                                        : null}
                        </div>
                    }
                </div>
                :
                <div style={{ marginBottom: '3rem', marginLeft: '1rem' }}>
                    <Skeleton
                        active
                        paragraph={{ rows: 1 }}
                    />
                </div>
            }
        </>
    )
}

export default DocumentRow
