// fileSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const fileSlice = createSlice({
    name: 'files',
    initialState: {
        list: [],
    },
    reducers: {
        addFile: (state, action) => {
            state.list.push(action.payload);
        },
        removeFile: (state, action) => {
            state.list.splice(action.payload, 1);
        },
    },
});

export const { addFile, removeFile } = fileSlice.actions;

export default fileSlice.reducer;
